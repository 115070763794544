.video-of-placeholder {
    min-height: 225px;
    max-height: 225px;
    background: #000;
    border: 1px solid #fff;
}
/* .video-parent {
    margin-right: 85px;
} */
.single.video-of-placeholder {
    min-height: calc( 100vh);
    max-height: calc( 100vh);
    position: relative;
    width: calc( 100% - 67px );
}
.single.video-of-placeholder.live-video .video-player {
    height: calc( 100vh - 143px);
}
.live-img-parent{
    position: relative;
}

.single.video-of-placeholder.live-video.live-img-parent img {
    width: 100%;
    min-height: calc( 100vh - 143px);
    max-height: calc( 100vh - 143px);
}
.live-img-text {
    position: absolute;
    width: 100%;
    background: #0c0c0c8c;
    z-index: 1;
    height: 100%;
    top: 0;
    left: 0;
}
.live-img-text .text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-wrap: wrap;
}
.live-img-text .text div{
    width: 100%;
}
.live-img-text .text div h2, .live-img-text .text div p {
    color: #fff;
    text-shadow: 0px 1px 2px #000000;
}
.single.video-of-placeholder .my-video {
    position: absolute;
    width: 250px;
    /* min-height: 200px;
    max-height: 100px;
    bottom: 0; */
    min-height: auto;
    max-height: auto !important;
    bottom: 0px;
    right: 0;
     background-color: gray;
    z-index: 99;
}
.mac .single.video-of-placeholder .my-video.camera-on {
    background-color: transparent !important;
}
.mac .single.video-of-placeholder .my-video.camera-on video {
    border: 1px solid gray;
}
.single.video-of-placeholder.live-video .video-player-parent iframe , .single.video-of-placeholder.live-video iframe {
    /* min-height: calc( 100vh - 143px);
    max-height: calc( 100vh - 143px); */
    min-height: calc( 100vh - 170px);
    max-height: calc( 100vh - 170px);
    border: none;
    border-radius: 0 !important;
}
.single.video-of-placeholder.live-video{
    background: transparent;
    border: none;
    min-height: calc( 100vh - 160px);
    max-height: calc( 100vh - 160px);
    
}

.video-call-popup .profile-dp, .video-call-popup .profile-dp img {
    min-width: 100px;
    min-height: 100px;
    max-height: 100px;
    border-radius: 100px;
    max-width: 100px;
}
.video-call-popup h2{
    font-weight: 700 !important;
}
.video-call-popup button#joinButton:hover {
    background: #1b4763;
}
select.custom-select {
    border-radius: 24px;
}
.ant-modal.cm_popup.calling-popup .ant-modal-content{
border-radius: 20px;
}
.video-call-popup .text h3, .video-call-popup .text p {
    font-size: 24px !important;
    font-weight: 700 !important;
    margin-left: 30px;
    margin-bottom: 0;
}
.video-call-popup button.btn.btn-primary {
    width: 100px !important;
    margin: 0 10px !important;
}
.video-call-popup .text p {
    color: #adadad !important;
}
.fullname-wrapper {
    background: #d33b27;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    -webkit-transition: .3s color, .3s border, .3s transform, .3s opacity;
    transition: .3s color, .3s border, .3s transform, .3s opacity;
}
/* -------------for counter------------ */
.fullname-wrapper.counter-wrapper {
    width: 150px;
    height: 150px;
}
.fullname-wrapper.counter-wrapper p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.fullname-wrapper.counter-wrapper p .loader-07 h6 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    color: #fff;
    font-weight: 600;
    font-size: 23px;
}
.loader-07 {
    border: 0 solid transparent;
    border-radius: 50%;
    position: relative;
    display: inline-block;
    width: 12em;
    height: 12em;
    color: inherit;
    vertical-align: middle;
    pointer-events: none;
}

  .loader-07:before, .loader-07:after {
    content: '';
    border: .2em solid currentcolor;
    border-radius: 50%;
    width: inherit;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: loader-07 1s linear infinite;
            animation: loader-07 1s linear infinite;
    opacity: 0;
  }
  .loader-07:before {
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  .loader-07:after {
    -webkit-animation-delay: .5s;
            animation-delay: .5s;
  }
  
  @-webkit-keyframes loader-07 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 0;
    }
  }
  
  @keyframes loader-07 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 0;
    }
  }
.fullname-wrapper p {
    color: #fff;
    font-weight: 700;
    text-align: center;
    min-height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 23px;
    margin-bottom: 0;
}

.bottom-strip {
   display: block; 
    position: absolute !important;
    width: 100%;
    background: #00000087;
    /* background: pink; */
    height: 60px;
    bottom: 18px;
    text-align: center;
    z-index: 9;
}
.top-strip {
    display: block;
    position: absolute !important;
    width: 100%;
    /* background: #2c688d; */
    min-height: 40px;
    top: 6px;
    text-align: center;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-transform: capitalize;
    color: #fff !important;
    font-size: 26px;
}
.top-strip span {
    background: #2c688d;
    padding: 0 16px;
    font-size: 14px;
    border-radius: 4px;
    box-shadow: 0px 2px 6px #dae4ea;
}
.bottom-strip span {
    margin: 12px;
}

.circle-icon {
    margin: 12px;
    background: #d33b27;
    width: 36px;
    height: 36px;
    border-radius: 100px;
    position: relative;
    cursor: pointer;
   
}
.circle-icon.active {
    background: #2c688d;
}
.single.video-of-placeholder:hover .bottom-strip , .video-parent:hover .bottom-strip , .self-parent:hover .bottom-strip{
    display: block;
}
.circle-icon i{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50% , -50%);
    color: #fff;
}
.bottom-svgicon svg {
    width: 17px;
    height: 17px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    fill: #fff;
}
.sqare-icon .bottom-svgicon svg {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    fill: #fff;
}
.single.video-of-placeholder .video-player-parent .video-player {
    width: 100%;
    height: 68vh;
}
.single.video-of-placeholder .video-player-parent {
    margin: 0;
    width: 100%;
}
.single.video-of-placeholder video#other-video{
    min-height: calc( 100vh);
    max-height: calc( 100vh)   
}

/* #tile-area {
    display: flex;
    flex-wrap: wrap;
} */

/* #tile-area > div {
    position: relative !important;
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
    height: auto !important;
} */

#tile-area > div.video-tile {
    width: 50% !important;
}

#tile-area > div.content-share-tile {
    order: -1;
    width: 100% !important;
    padding-bottom: 66% !important;
    margin-bottom: 2rem;
}

#tile-area > div > video {
    object-fit: cover;
}

@media (min-width:320px) and (max-width:767px){
    .single.video-of-placeholder {
        min-height: calc( 90vh);
        max-height: calc( 90vh);
    }
    .single.video-of-placeholder {
        width: calc( 100%);
    }
    .bottom-strip{
        z-index: 999;
    }
    .single.video-of-placeholder .my-video {
        bottom:  85px ;
    }
    .video-of-placeholder {
       background: transparent;
    }
    .mobile {
        height: calc( 90vh /2 );
        position: relative;
        background: black;
    }
    .single.video-of-placeholder .mobile video#other-video {
        min-height: calc( 88vh /2 );
        max-height: calc( 88vh /2 );
    }
    .single.video-of-placeholder .my-video {
        width: 100%;
        /* height: 50vh; */
        height:calc( 90vh /2 );
        bottom: 0vh;
        background: #000;
        border-top: 5px solid #2c688d;
    }
    .single.video-of-placeholder .my-video video {
        /* height: 50vh; */
        height: calc( 88vh /2 );
    }
    .bottom-strip {
        bottom: 0px !important;
    }
  
    .single video-of-placeholder .timer-afterlogin p {
        top: -23px;
        left: 44%;
        display: block;
        text-align: center;
    }
    .single video-of-placeholder .timer-afterlogin p span {
        margin: 12px;
        font-size: 17px !important;
    }
    
}
