/* --------------support chatbox---------- */
.chat-wrapper.supportchat{
    display: none;
}

.support-open .chat-wrapper.supportchat, .support-open .chat-wrapper.supportchat .chatbody-parent {
    display: block;
    width: 426px;
    box-shadow: 0 25px 21px -12px rgb(66 66 66 / 60%);
}
.supportchat .wrap-chat-header {
      width: 426px;
}
.supportchat .chat-icon-wrapper {
    border-top-left-radius: 23px;
}
.supportchat .bottom {
    width: 426px;
}
.chat-wrapper.supportchat .chat-icon-wrapper span {
    position: absolute;
    top: 0px;
    left: 24px;
}
.chat-wrapper.supportchat .chat-icon-wrapper h5 {
    color: #fff;
}
/* ------admin-chatbox-- */
.support-tabbar-parent {
    /* background: #fff;
    border: 1px solid #eee; */
    border-radius: 9px;
    display: flex;
    height: calc(100vh - 242px);
    min-width: 800px;
}

.support-tabbar-parent .scroll {
    overflow-y: auto;
    height: calc(100vh - 242px);
    overflow-x: hidden;
}
.overflow-y-auto {
    overflow-y: hidden;
    overflow-x: auto;
}

.support-tabbar-parent .support-tabbar-body{

}
.support-tabbar-body .chat-body-parent{
    position: relative;
    height: 100%;
}
.support-tabbar-body .chatbody {
    padding: 20px;
    padding-bottom: 16px;
    height: calc( 100% - 144px);
    overflow-y: auto;
    margin-top: 0px;
    overflow-x: hidden;
    width: 100%;
}
.support-tabbar-parent .support-tabbar-body .chat-current-online-body{
    padding: 0 12px;
}
.support-tabbar-body .chat-body-parent .bottom{
    position: absolute;
    bottom: 61px;
    width: 100%;
}
.support-tabbar-body .chat-body-parent .bottom .search_wrapper i {
    position: absolute !important;
    top: 64%;
}
.support-tabbar-parent .support-tabbar-hader {
    width: 420px;
    background: #fff;
    border-bottom: 1px solid #eee;
    padding: 10px;
}
.support-tabbar-hader .header {
    display: flex;
}
.support-tabbar-hader .profile img {
    width: 45px;
    height: 45px;
}
.support-tabbar-hader .header {
    display: flex;
}
.support-tabbar-hader .header .discription {
    width: 225px;
    margin: 12px;
}
.support-tabbar-hader-parent {
    width: 420px;
    border: 1px solid #eee;
    margin-top: 12px;
    margin-bottom: 12px;
    background: #ffffff6e;
    overflow-y: auto;
    overflow-x: hidden;
}
.support-tabbar-hader .profile .dot.green {
    position: absolute;
    bottom: 16px;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 100px;
    border: 2px solid #4CAF50;
}
.support-tabbar-hader .chat-counter p {
    width: 20px;
    height: 20px;
    background: #FF0000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    font-size: 11px;
}
.support-tabbar-hader .header .discription h6 , .support-tabbar-hader .header .discription p ,.support-tabbar-hader .header .date p{
margin-bottom: 0;
}
.support-tabbar-hader .header .discription .chat-counter{
    float: right;
}
.support-tabbar-hader .header .date{
    text-align: right;
    width: calc( 100% - 225px - 80px);
}
.support-tabbar-hader .header .date p{
    float: right;

}
.support-tabbar-hader .header .discription p{
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis
}

.support-tabbar-body{
    box-shadow: 0px 0px 6px #888;
    width: calc( 100% - 420px);
    background: #fff;
}


@media (min-width:300px) and (max-width:767px){
.support-open .chat-wrapper.supportchat,
 .support-open .chat-wrapper.supportchat .chatbody-parent,
 .supportchat .wrap-chat-header ,.supportchat .bottom{
    width: 100%;
}
}