.cm-section .navbar .nav-link {
    color: #fff;
    font-weight: 700;
}
.navbar-nav {
    width: 100%;
}

.nav-wrapper a {
    font-weight: 700;
    color: #fff;
   margin-bottom: auto;
}
.nav-wrapper {
    background-color: var(--headercolor);

}

.header-wrapper {
    /* background-image: url(../../images/homebanner-bg.png); */
    display: flex;  
    background: #fff;
}

.cm-section h1, .cm-section h2, .cm-section h3, .cm-section h4, .cm-section h5, .cm-section h6 , .cm-section{
    font-weight: 700 ;
}

.cm-section h5 {
    line-height: 30px;
}
.cm-section h6 {
    line-height: 25px;
    color: rgb(116,128,136);
}

.cm-wrapper {

    /* padding-bottom: 6rem; */
}
.btn-transparent {
    background-color: transparent;
    color: #fff;
    border-radius: 100px;
    min-height: 48px;
    padding: 9px 24px;
    min-width: 142px;
    font-weight: 700;
    margin: 12px 0px;
    font-size: 19px;
}
.red-border{
    border-left: 3px solid var(--headercolor) !important;
    font-weight: 700;
    padding-left: 10px;
    text-align: left;
}
.red-border h5 , .red-border h3 {
  margin-bottom: 0rem;
}
.red-border h1.title {
    margin-top: 2rem;
    margin-bottom: 2rem;
}



.cm-section .wrapper {
    position: relative;
    text-align: center;
    color: #ffffff;
    margin-bottom: 26px;
}

.cm-section .wrapper h3 {
    color: #fff;
}

.top-right {
    position: absolute;
    top: 16px;
    right: 16px;
    /* z-index: 99999;
    cursor: cell; */
}
.reset-btn{
    background: transparent;
    border: 1px solid #fff;
    border-radius: 100px;
    padding: 4px 25px;
}
.top-right i{
color: #000;
}

.bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
}

.bottom-left {
    position: absolute;
    bottom: 5%;
    left: 2rem;
}

.top-left {
    position: absolute;
    top: 8px;
    left: 16px;
    z-index: 99;
}

.col-md-3 .bottom-left {
    bottom: 5%;
}

.bottom-right {
    bottom: 5%;
    right: 2rem

}

.mt-6 {
    margin-top: 4rem;
}
.ant-progress-show-info .ant-progress-outer {
    margin-right: 0 !important;
    padding-right: 0 !important;
}
.progress-bar-wrapper {
    max-width: 150px;
    margin-top: .5rem;
}
.progress-bar-wrapper span {
    font-size: .9em;
}
.ant-progress-inner {
    height: 4px;
}

.ant-carousel .slick-slide h3 {
    color: #fff;
}

.ant-carousel .slick-slide {
    background-color: transparent;
}


.carousel-wrapper {
    position: relative;
    margin-top: 55px;
    margin-bottom: 25px;

}

.carousel-wrapper .fa.fa-long-arrow-right{
    position: absolute;
    top: 50%;
    right: 0;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-right: -50px;
    z-index:999;
}

.carousel-wrapper .fa.fa-long-arrow-left:first-child{
    position: absolute;
    top: 50%;
    left: 0;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
    margin-left: -50px;

}
.carousel-wrapper  i.fa.fa-long-arrow-left, .carousel-wrapper  i.fa.fa-long-arrow-right {
    background: #91CBD3;
    padding: 10px;
    border-radius: 100%;
    color: #fff;
 }

.carousel-wrapper img {
    display: inherit !important;
    margin: 0 auto;
    border-radius: 20px;

}
.featured-brands.showcase hr {
    margin-top: 0;
}
.featured-brands.showcase .card img {
    /* min-height: 320px; */
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    /* max-height: 210px; */
    border-radius: 15px;
    width: 100%;
}
.featured-brands.showcase .card {
    /* min-height: 400px; */
    /* max-height: 286px; */
}
.product-showcase-devide .card.brand-card {
    /* min-height: 365px; */
}
.spotlight-web  .product-showcase-devide .card.brand-card  {
    min-height: auto;
}
 .spotlight-web  .viewbrand .product-showcase-devide .card.brand-card img {
    /* min-height: auto;
    max-height: 100% !important; */
    min-height: 240px;
    max-height: 240px !important;
}
.spotlight-web  .featured-brands.showcase .card {
    min-height: 345px;
    /* max-height: 286px; */
}
.spotlight-web  .viewbrand .product-showcase-devide .card.brand-card{
    min-height: auto;
    max-height:auto !important;
}
/* .spotlight-web .viewbrand .card.brand-card img {
    min-height: 268px;
    max-height: 268px;
}
.spotlight-web .brand-img-wrapper {
    top: 229px;
} */

.featured-brands.showcase .card .action_group {
    display: flex;
    width: 100%;
    min-height: 48px;
}
.featured-brands.showcase .card .action_group span .blue {
    color: #2b6a8e;
}
.featured-brands.showcase .card .action_group span {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    /* width: 100%; */
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}
.featured-brands.showcase .card .action_group span {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 700;
    color: #000;
}
.featured-brands.showcase .card .action_group {
    padding: 0 12px;
}
.product-card-wrapper .community-card {
    padding: 0px;
}
.circle-blue {
    position: relative;
}
.circle-blue:before {
   content: "";
    height: 6px;
    width: 6px;
    left: -10px;
    position: absolute;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: #2b6a8e;
    border-radius: 100%;
}
img.rounded {
     border-radius: 20px !important;
 }
.zigzek .col-lg-6  {
    padding-left: 30px ;
    padding-right: 15px ;
}

.zigzek .col-lg-6:first-child {
    padding-right: 30px;
}

.zigzek .col-lg-6:last-child{
    padding-left: 30px;
}

 .col-lg-6.order-first {
    padding-right: 30px;
     padding-left: 15px !important;
}

.home-networking .btn-primary {

     min-height: 32px;
    max-height: 32px;
    padding: 0px 19px;
     min-width: 100px;

    line-height: 32px;

}

.btn.btn-live {
    background-color: #fff;
    font-weight: 700;
    border-radius: 100px;
    padding: 0 10px;
    min-height: 30px;
}

.btn-live .fa-circle {
    color: red;
    margin-right: 5px;
}

.skew-text {
    clip-path: polygon(1% 112%, 482% 119%, 98% 102%, 75% -1%, 0 -19%, 0 12%);
    background-color: #2B688E;
    height: 40px;
    min-width: 200px;
    max-width: 220px;
    color: #fff !important;
}

.skew-text .fa.fa-long-arrow-right {
    background: none;
    padding: 10px;
    border-radius: 0%;
    color: #fff;

    position: relative;
    top: 50%;
    right: 0;

    margin-right: 0px;
}

.skew-text.bottom-left {
    bottom: -12px;
    left: 48px;
    z-index: 1;
    overflow: visible;

}

.skew-text h5 {
    color: #fff !important;
}

.carousel-industry img {
    min-height: 375px !important;
    object-fit: cover;
}
.brand .brand-img img {
    min-height: 200px;
    max-height: 200px;
    width: 100%;
}

.mb-3-5 {
    margin-bottom: 30px;
}

.ant-carousel .slick-dots {

     margin-right: 0% !important;
     margin-left: 0% !important;

}

.product-showcase-slider-wrapper .fa-long-arrow-left {
    margin-left: -30px !important;
}

.product-showcase-slider-wrapper .fa-long-arrow-right {
    margin-right: -30px !important;
}

.header-text h1.title {
    border-left: 3px solid var(--btncolor);
    padding-left: 10px;
    text-align: left;
    margin: 2rem 0px;
}

.flex-wrap.five img {
    margin: 0 auto;
    border-radius: 20px;
    width: 100%;
}

.five .card {
    width: 17% !important;
    margin-left: 1.5% !important;
    margin-right: 1.5% !important;
    padding: 0px;
    background: transparent;
    border: none !important;
    box-shadow: unset;
}
.featured-brands .five .card, .featured-brands .five .card img {
    min-height: 200px;
    background: #fff;
    max-height: 199px;
    margin-bottom: 30px;
    border-radius: 15px !important;
}
/* .featured-brands .five .card img {
    padding: 22px;
    min-height: 155px;
} */
.six {
    width: calc(100% / 6);

}

.pl-pr-15 {
    padding-left: 15px;
    padding-right: 15px;
}

.ant-carousel .row {
    /*margin-right: 0px;*/
    /*margin-left: 0px;*/
    padding-left: 10px ;
    padding-right: 15px;
}

.btn.btn-card {
    color: #7B96A8;
    border: 1px solid #7B96A8;
    border-radius: 20px;
    font-weight: 700;
    padding: 1px 13px 0 13px;
    font-size: 12px;
    min-height: 18px;
    line-height: 14px;
    background: #fff;
    width: 140px;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.blue-circle {
    position: relative;
    height: 40px;
    width: 40px;
    cursor: pointer;
    background: var(--blue_bg);
    border-radius: 100%;
    z-index: 9;

}
.blue-circle .fa.fa-heart-o{
    color: #fff;
    font-size: 19px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.blue-circle .spinner-border {
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 11px;
    left: 12px;
    color: #fff !important;
}

.brand-card .top-right {
    top: 8px;
    right: 8px;
}
.brand-card-body {
    margin-top: 20px;
    text-align: center;
    padding: 20px 20px 0px 20px;
}
.viewbrand .card.brand-card img , .spotlight-web .viewbrand .product-showcase-devide.roduct-showcase-devide-brand .card.brand-card img  {
    min-height: 150px;
    max-height: 150px;
    border-radius: 15px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
.brand-img-wrapper {
    position: absolute;
    top: 110px;
    margin: 0 auto;
    border: 2px solid #D3E1E4;
    left: 0;
    right: 0;
    /* width: 50%; */
    width: 70%;
    z-index: 9;
    border-radius: 5px;
}
.viewbrand .brand-img-wrapper img ,.product-showcase-devide.roduct-showcase-devide-brand .card.brand-card .brand-card-body img{
    min-height: 75px !important;
    max-height: 75px !important;
    border-radius:5px !important;
    width: 100%;
    /* object-fit: contain;
    background: #fff; */
}
.viewbrand .card.brand-card .brand-card-body h4 , .viewbrand .card.brand-card .brand-card-body p{
    font-size: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 26px;
}
.viewbrand .card.brand-card .brand-card-body p{
font-size: 14px;
font-weight: 400;
}
.brand-img-wrapper img {
    border-radius: 0px;
}
.brand-card {
    box-shadow: 0px 4px 8px #bdf5ff85;
    border: 1px solid #DAE9ED !important;
   padding-bottom: 20px;
    max-width: 450px;
    border-radius: 15px !important;
}
.product-card-wrapper {
    padding: 30px;
    background-color: #FFFFFF;
    border-radius: 15px;
    border: 2px solid #E4F3F5;
}

/*web table start*/

.cm-section .table.table-bordered tr th{
    background-color: var(--btncolor);
    text-align: left !important;
    padding-left: 30px;
    font-size: 18px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.cm-section .table.table-bordered tr th span svg{
    width: 30px;
    height: 30px;
    display: inline-table;
    margin-right: 1rem;
    fill: #fff;
}


.cm-section .table.table-bordered tr td {
    padding-left: 30px;
    color: rgb(126,130,133);
    border-right: none;
    border-left: none;
}

.cm-section .table.table-bordered .row img{
    width: 65px;
    height: 65px;
}
.cm-section .table.table-bordered .row .card.community-card.wrapper img {
    width: 100%;
    min-height: 205px;
    /* max-height: 120px; */
}
.cm-wrapper.featured-brands.showcase table.table-bordered .card {
    /* min-height: 258px; */
    /* max-height: 258px; */
}

.cm-section .table.table-bordered tr td:not(last-child){
    background-color: #FFFFFF;
    text-align: left !important;
    border-bottom: 1px solid rgba(144,203,211, .2);
}

.cm-section table.table-bordered tr:first-child th:last-child {
    border-top-right-radius:  15px;
    border-top-left-radius: 15px;
}
.cm-section .table-responsive {
    border-radius: 15px;
}
table  .row {
    border-bottom: 1px solid rgba(144,203,211, .2);
}
table .img-wrapper {
    padding: 10px;
    border: 1px solid rgb(222,239,240);
    border-radius: 5px;
}

.btn.btn-brand {
    color: #7B96A8;
    border: 1px solid #7B96A8;
    border-radius: 20px;
    font-weight: 700;
    padding: 0px 13px;
    font-size: 12px;
    min-height: 18px;
    line-height: 14px;
    background: #fff;
}

.cm-blue {
    color: #5383a2;
}
.blue.bold, .cm-blue.bold{
    font-weight: 700;
}
/*web table end*/



/* filter start */

.filter-header-wrapper {
    background-color: #91CBD3;
    padding: 0px 0 0 0;
    display: flex;
    align-items: center;

}
.middle-wrapper .ant-select.ant-tree-select.cm_multiselect.ant-select-single.ant-select-show-arrow{
    margin:5px
}
.middle-wrapper .ant-select:not(.ant-select-disabled):hover .ant-select-selector{
    border-color: #fff;
}
.cm-section .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 37px;
    height: 37px;
    border-radius: 20px;
    border-color: #fff;
    background: transparent;
    min-width: 153px;
    margin-right: 20px;
    padding-left: 20px;

}


.filter-header-wrapper .mac span.ant-select-selection-placeholder, .filter-header-wrapper span.ant-select-selection-item {
    line-height: 2.3!important;
    color: #fff !important;
}

.filter-header-wrapper .fa.fa-sort-desc {
    color: #fff;
}

.filter-header-wrapper .ant-select-selection-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff !important;
}

.rounded-circle {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 100px;
    border: 1px solid #fff;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    line-height: 40px !important;
}

.cm-section .ant-select-arrow {
    background: transparent;
}

.filter-header-dropdown {
    min-width: 100px;
    display: flex;
    align-items: center;
}

.filter-header-dropdown span {
    font-size: 20px;
    color: #fff;
    margin-right: 10px;
    /* margin-top: 7px; */
}
.ant-select-arrow {
    top: 50% !important;
}

.rounded-circle  .fa.fa-sort-desc {
    margin: 0;
    position: absolute;
    top: 43%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.middle-wrapper {
    display: flex;
    padding: 0 20px;
    margin-left: 20px;
    border-right: 1px solid rgb(184,225,230);
    border-left: 1px solid rgb(184,225,230);
    padding-top: 10px;
    padding-bottom: 10px;

}
.web-filter {
    /* padding: 20px 40px 40px 40px; */
    padding: 45px 70px;
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #DAE9ED;
    width: 100%;
}
.web-filter .form-check {
    display: flex;
   margin-bottom: 20px;
}
.web-filter label.form-check-label {
    font-size: 18px;
    color: #000000;
    opacity: 1;
}
.web-filter button.btn.btn-filter {
    color: #fff !important;
}
/* .web-filter .cm-section .ant-select-single:not(.ant-select-customize-input) .ant-select-selector, .web-filter .cm-section .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:hover 
.web-filter .ant-select-selector{
    position: relative;
    width: 100%;
    left: 0;
    top: 0;
    background: white;
    height: 100%;
    border-radius: 2rem;
    padding-left: 22px;
    padding-right: 35px;
    border: 1px solid #D7EAED;
    height: 44px !important;
    font-weight: 100;
    font-size: 14px !important;
} */
.btn.btn-filter {
    min-height: 33px;
    line-height: 30px;
    min-width: 100px;
    font-size: 14px;
    padding: 0 20px;
    margin-right: 10px;
}
.dimention {
    font-size: 13px;
    color: #2b688e;
    text-align: center;
    padding: 6px;
    font-weight: 700;
    
}
.popupdimention{
    font-size: 10px;
    font-weight: 700;
}

.no-drop {cursor: no-drop;}
.ant-modal.cm_popup.term-services .ant-modal-content p {
    color: #060606;
    font-weight: 800;
    font-size: 14px;
}
.ant-modal.cm_popup.term-services .ant-modal-content .header-text h1.title{
    font-size: 29px;
    font-weight: 700;
}
.ant-modal.cm_popup.term-services .ant-modal-content {
    width: 670px;
    border-radius: 20px;
}
.checkbox-item-wrap {
    padding: 20px;
    font-weight: 400;
}

.checkbox-item-wrap .form-check-inline .form-check-input{
margin-right: .6rem;
}

.checkbox-item-wrap .header-icon {
    width: 20px;
    margin-right: .6rem;
}
.checkbox-item-wrap .red{
    fill: red;
}
.search-result-parent .checkbox-item-wrap p , .search-result-parent .checkbox-item-wrap h6{
    margin-bottom: 0;
}
.cm-section .check_wrapper > span {
    margin-right: .4rem;
}
/* filter end */

/* community */
.community-card .logo-wrapper img {
    height: 40px;
}
.col-md-3 .community-card {
    padding-right: 5px;
    padding-left: 5px;
}

.card .fa.fa-ellipsis-v {
    cursor: pointer;
}

.cm-section .ant-tabs-nav-list {
    border: 1px solid black;
    margin-top: 40px;
    min-width: 412px;
    border-radius: 100px;
    padding: 3px;
    max-height: 40px;
}

.cm-section .ant-tabs-tab,.cm-section .ant-tabs-tab-active,.cm-section .ant-tabs-tab  {
    width: calc(100% / 2);
    margin: 0 !important;
    border-radius: 100px;
    color: #A2A7AD;
    justify-content: center;
}
.cm-section .ant-tabs-tab-active, .cm-section .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    background-color: var(--btncolor) !important;
    color: #fff;
    font-weight: 700;
}
.cm-section .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    background: transparent !important;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    margin-left: 0;
    left: 0;
}
.featured-brands.industries-serve .five .card, .featured-brands.industries-serve .five .card img.img-fluid {
    min-height: 240px;
    max-height: 240px;
    margin-bottom: 40px;
}
.postion-rel.d-flex.align-items-center.traingletext {
    background: #2b6a8e;
    height: 100%;
    padding: 7px 10px;
}
.industries-serve .bottom-text{
    position: absolute;
    bottom: 24px;
    left: 19px;
    background: #f0f8ff00;
    color: #fff;
    padding: 0;
    
    font-size: 15px;
    width: 80%;
    z-index: 1;
  }
.industries-serve .bottom-text .traingletext .traingle {
    position: absolute;
    right: -49px;
    top: 0;
    width: 50px;
    height: 100%;
  }
 .industries-serve .bottom-text .traingletext .traingle svg path {
    fill: #2b6a8e;
  }
 .industries-serve .bottom-text .traingletext .traingle svg {
    position: absolute;
    width: auto;
    height: 100%;
    top: 0;
    left: 0;
  }
  .filter-header-wrapper .overflow-auto {
    overflow-x: auto !important;
    overflow-y: hidden !important;
}
  .filter-header-wrapper span.ant-select-selection-search, 
  .filter-header-wrapper .cm_multiselect span.ant-select-selection-placeholder,
  .filter-header-wrapper span.ant-select-selection-placeholder, 
  .filter-header-wrapper .cm-section .ant-select-arrow {
    color: #fff !important;
    opacity: 1 !important;
}
.networking-banner, .networking-banner img{
    box-shadow: none;
    position: relative;
    text-align: center;
}
.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

.sm-btn, .mac .btn.btn-red.sm-btn
{
    max-width: 100px;
    padding: 5px !important;
    min-width: 76px !important;
    min-height: 20px !important;
    font-size: 10px !important;
}
/* .btn.btn-red.enter-btn{
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50% , -60%);
} */
.networking-banner span{
    color: #fff;
    background: var(--headercolor);
    padding: 0px 10px;
    text-align: center;
    margin-bottom: 2px;
    border-radius: 3px;
}
.search-result-parent {
    background: #fff;
    border-radius: 20px;
    margin: 40px auto !important;
    box-shadow: 0px 3px 5px #91cbd3;
}
/* community end */

.filter-header-wrapper .ant-select.ant-tree-select.cm_multiselect.ant-select-single.ant-select-show-arrow .ant-select-selector  span.ant-select-selection-placeholder{
      line-height: unset !important;
      /* display: contents;
      justify-content: center; */
}
.filter-header-wrapper .dropdown-filter-box span.ant-select-tree-switcher.ant-select-tree-switcher-noop, .dropdown-filter-box .ant-select-tree-indent ,.dropdown-filter-box .ant-select-tree-switcher.ant-select-tree-switcher-noop {
    display: none;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode {
    border-bottom: 1px solid #cecece52;
}

.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode:last-child {
    border-bottom: none;
}
.ant-select-dropdown.dropdown-filter-box.ant-tree-select-dropdown.ant-select-dropdown-placement-bottomLeft.ant-select-dropdown-hidden {
    padding: 0px 4px 0;
}
.ant-menu-item.active-url.ant-menu-item-only-child {
    background: #91cbd3;
}
.ant-menu-item.active-url.ant-menu-item-only-child a {
    color: #fff;
}
.manage-service .heading-after-border .heart {
    background: white;
}
.newBadge {
    position: absolute;
    z-index: 1;
    left: -6px;
}
.newBadge:before {
    content: " ";
    position: absolute;
    bottom: -7px;
    left: 0;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 0 solid transparent;
    border-top: 7px solid var(--btncolor);
}
.newBadge.offer:before {
    border-top: 7px solid var(--blue_bg);
}
.newBadge p{
    position: relative;
    margin-bottom: 0;
    padding: 4px 5px 4px 10px;
    background: var(--btncolor);
    font-size: 16px;
    color: #fff;
    font-weight: 700;
}
.newBadge.offer p{
    background:var(--blue_bg);
}
.newBadge p span{
    position: absolute;
    top: 0;
    width: 27px;
    height: 100%;
    right: -26px;
    font-size: 16px;
}
.newBadge.offer p span svg g path {
    fill: var(--blue_bg);
}
.mailtag{
    background: #2c688d;
    padding: 4px 14px;
    border-radius: 100px;
    color: #fff;
    margin: 5px 3px;
}
.mailtag-cross {
    background: white;
    border-radius: 100px;
    padding: 1px 3px;
    color: #000;
    font-weight: 700;
    margin-left: 12px;
}
.mail_modal button.btn, .mail_modal button.btn:hover {
    min-width: 100px;
}
.middle-wrapper .cm_multiselect {
    min-width: 180px;
    width: 180px !important;
}
.texton_placholder{
    position: absolute;
    bottom: 4px;
    text-align: center;
    left: 10px;
    width: 82%;
    word-break: break-all;
}
.texton_placholder h6 , .brochure_placholder h6 , .coverImage_placholder h6{
    font-size: 13px;
    font-weight: 300;
}
.coverImage_placholder h6{
    color: #fff;
}
.brochure_placholder {
    position: absolute;
    top: 50%;
    text-align: center;
    left: 10px;
    width: 82%;
    word-break: break-all;
    transform: translate(0px, -35%);
}
.coverImage_placholder {
    position: absolute;
    top: 75%;
    text-align: center;
    left: 10px;
    width: 99%;
    word-break: break-word;
    transform: translate(0px, -50%);
    background: transparent !important;
}
.coverImage_placholder.logo{
    width: 92%;
}
.brochure_parent level b, .size_msz b{
    /* color: #000; */
    font-weight: 100;
    font-size: 14px
}
.product-detale{

}
.product-detale p{
    margin-bottom: 0;
}
/* .css-26l3qy-menu {
    padding: 0 10px;
} */
.custom-select-option{
    border-bottom: 1px solid #9aabad24;
    border-radius: 0px !important;
    padding: 6px 10px;
}
.photo-placeholder{
background: #bfe1e6;
}
.brochure_parent label.upload-photo-wrapper.upload-container-pic {
    background: #bfe1e6;
}
.brand-logo-parent .coverImage_placholder.logo {
    top: 55%;
}
.brand-logo-parent .coverImage_placholder.logo h6{
    color: #000;
}
.total span{
    font-size: 19px;
    font-weight: 600;
}
.admin-product .card.brand-card img {
    min-height: 100%;
    max-height: 100%;
}
.spotlight-web .viewbrand .product-showcase-devide.roduct-showcase-devide-brand .brand-img-wrapper{
    position: relative;
    top: -33px;
    margin: 0 auto;
    border: none;
    left: 0;
    right: 0;
    width: 80%;
    z-index: 1;
    border-radius: 5px;
} 
@media (min-width:300px) and (max-width:580px){
    .admin-brand .card.brand-card.new-product {
        min-height: auto;
    }
    .professional-beauty-banner-wrapper .coverImage_placholder {
        top: 25%;
        width: 70%;
        margin: 0 12% 0 12%;
    }
    .professional-beauty-banner-wrapper .coverImage_placholder h6, .brand-logo-parent .coverImage_placholder h6 {
        font-size: 11px;
    } 
    .web-filter {
        padding: 20px;
    }
    .btn.btn-red.mange{
        /* position: absolute;
        right: 180px;
        background: #8cd0d8 !important; */
    }
    .countdown-page a.btn.btn-red {
        margin-top: 12px;
        line-height: 26px;
        margin-right: 0px !important;
    }
    .countdown-page .btn.btn-red, .countdown-page .btn.btn-red:hover {
        font-size: 11px;
        width: 45%;
        position: initial !important;
        margin-bottom: 0;
        padding: 9px 4px;
    
    }

   
 
    .cm-section .ant-tabs-nav-wrap{
        min-width: 100%;
    }
    .cummunity-filter {
        top: 53px!important;
    }
    .cm-section .ant-tabs-nav-list {
        min-width: 100%;
    }
    .header-text h1.title {
        margin-top: 55px;
        margin-bottom: 0px;
    }
    /* .cummunity-filter {
        top: -19px !important;
       
        left: 50% !important;
        transform: translate(-50% , 10px);
    } */
}
@media (min-width:300px) and (max-width:580px){
    
    .five .card {
        width: 46% !important;
        margin-left: 2% !important;
        margin-right: 2% !important;
    }
    .header-text h1.title {
        font-size: 28px;
        font-weight: 700;
    }
}
@media (min-width:581px) and (max-width:991px){
    .five .card {
        width: 30% !important;
    }
    .professional-beauty-banner-wrapper .coverImage_placholder {
        top: 29%;
        width: 95%;
    }
}
@media (min-width:992px) and (max-width:1200px)
{
    .five .card {
        width: 22% !important;
    }
}


@media (max-width:400px){
    .countdown-page .btn.btn-red , .countdown-page .btn.btn-red:hover {
        font-size: 12px;
        width: 100%;
        position: initial !important;
        margin-bottom: 0;
    }
    .countdown-page a.btn.btn-red{
        margin-top: 0;
        line-height: 26px;
    }
    .cm-section .wrapper h3 {
        font-size: 17px;
    }
    .respo-dimention{
        width: 42%;
        margin-left: 62%;
    }
}
@media (max-width:991px){
    /* .filter-header-wrapper.respo-screen{
        background-color: #fff;
    }
    .cm-section.d-flex.align-items-center.justify-content-left.overflow-auto.mobile-responsive-filter-header{
        display: flex;
        flex-wrap: wrap;
    }
    .filter-header-wrapper.container-fluid {
        padding: 0 20px !important;
    }
    
    .filter-header-wrapper .mobile-responsive-filter-header > div {
        width: 100%;
        justify-content: center;
    }
    
    .filter-header-wrapper .mobile-responsive-filter-header .middle-wrapper {
        display: flex;
        flex-wrap: wrap;
    }
    
    .filter-header-wrapper .mobile-responsive-filter-header .middle-wrapper div {
        width: 100% !important;
    }
    .filter-header-wrapper .mobile-responsive-filter-header a span, .filter-header-wrapper .mobile-responsive-filter-header .fa.fa-sort-desc ,
     .filter-header-wrapper .mobile-responsive-filter-header .filter-header-dropdown span ,
    .mobile-responsive-filter-header .middle-wrapper span.ant-select-arrow 
    {
        color: #000;
    }
    .mobile-responsive-filter-header .middle-wrapper .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        border-color: #000;
    }
    
    .filter-header-wrapper .mobile-responsive-filter-header a .rounded-circle, .filter-header-wrapper .mobile-responsive-filter-header .filter-header-dropdown .rounded-circle {
        border: 1px solid #000;
    }
    
    .mobile-responsive-filter-header .cm_multiselect span.ant-select-selection-placeholder, .mobile-responsive-filter-header span.ant-select-selection-placeholder {
        color: #000 !important;
    } */
}
@media (min-width:300px) and (max-width:767px)
{
    .cm-wrapper.featured-brands.showcase table.table-bordered .card {
        /* min-height: 470px; */
    }
    .cm-section .table.table-bordered .row .card.community-card.wrapper img {
        width: 100%;
        min-height: auto;
        height: auto;
    }
}