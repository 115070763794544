.cm_crop-container-parent .crop-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
}

.cm_crop-container-parent .controls {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
span.MuiButton-label, .MuiTypography-overline {
    font-weight: 600;
    color: #0d3d58;
}
.makeStyles-slider-6 {
    margin-left: 6px !important;
}
.makeStyles-slider-6 {
    padding: 6px 0px !important;
}
.cm_crop-container-parent .slider {
    padding: 22px 0px;
}
/* --------------croper design------------------- */
.cm_crop-container-parent{
    position: relative;
    width: 100%;
    min-height: 400px;
    max-height: 400px;
}
.cm_crop-container {
    bottom: 13px !important;
    margin-bottom: 60px !important;
}
/* span.MuiSlider-root.MuiSlider-colorPrimary {
    width: calc(100% - 140px) !important;
} */
.cm_croper_control {
    font-size: 15px;
    color: #3f51b5;
    min-width: 70px;
}
.croper-bottom-btn{
    display: flex;
    justify-content: space-between;
}
.croper-bottom-btn .upload-icon.btn.btn-primary.floar-right, .croper-bottom-btn button.btn.btn-primary {
    min-width: 144px !important;
    font-size: 14px !important;
}
@media (min-width:318px) and (max-width:600px){
    .cm_crop-container-parent {
        min-height: 300px;
        max-height: 300px;
    }
    .cm_crop-container-parent .controls {
        flex-wrap: wrap;
        justify-content: space-around;
        margin-bottom: 19px;
    }
    .croper-bottom-btn{
        margin-top: 60px;
    }
}
@media (min-width:318px) and (max-width:445px){
    .cm_crop-container-parent {
        min-height: 390px;
        max-height: 390px;
    }
    .ant-modal.paymentpopup.croperpopup .ant-modal-body {
        padding: 52px 18px;
    }
    .cm_crop-container-parent .controls {
        margin-bottom: 19px;
    }
    .cm_crop-container-parent .controls {
        height: 164px;
    }
    .cm_crop-container-parent .controls > div, .cm_crop-container-parent .controls button {
        width: 100%;
        justify-content: center;
    }
    .MuiIconButton-root {
        padding: 0px !important;
    }
    .croper-bottom-btn .upload-icon.btn.btn-primary.floar-right, .croper-bottom-btn button.btn.btn-primary {
        min-width: 115px !important;
        font-size: 11px !important;
    }
}