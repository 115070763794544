.conference-block {
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 10px 9px #00000012;
    /* min-height:415px; */
    min-height:325px;
    margin-bottom: 50px;
}
.conference-block .img, .conference-block .img img {
    /* min-height: 250px;
    max-height: 250px; */
    min-height: 160px;
    max-height: 160px;
    object-fit: cover;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
    position: relative;
    
}
button.btn.btn-card.live .fa {
    color: #FF0000;
}
.leftborder-parent h6 svg {
    margin-top: 0px;
    width: 20px;
    height: 20px;
    fill: var(--btncolor);
    margin-right: 10px;
}
.leftborder-parent h4, .header-text h1.title {
    font-weight: 700;
}
button.btn.btn-card.live {
    color: #242626;
    background: #fff;
    padding: 3px 10px;
    border-color: #FF0000;
}
.conference-block .text {
    padding: 20px;
    
   
}
.conference-block .text h4, .conference-block .text h6{
    color: #3c4a53;
}
.conference-block .text h6{
    display: flex;
   
}
.conference-block .text h6 span:nth-child(2), .conference-block .text h4{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.leftborder-parent {
    border-left: 3px solid var(--btncolor);
    padding-left: 12px;
    text-align: left;
}
.free-paid {
    position: absolute;
    top: 8px;
    left: 9px;
}
.free-paid span svg {
    width: 19px;
    margin-right: 4px;
    margin-top: -3px;
    fill:#fff;
}
.free-paid button.btn.btn-card.live {
    min-width: 80px;
    font-size: 9px;
    color: white;
    border-color: #fff;
    /* background: #2c688d; */
    background:var(--btncolor);
    padding: 2px 10px;
}
/* ----------------confrence detail---------------- */

.offer-badge {
    position: absolute;
    z-index: 1000;
    left: 5px;
    top: 20px;
    min-width: 80px;
}

.offer-badge .offer-badge-text {
    color: #fff;
    background: #FF0000;
    font-size: 13px;
    padding: 8px;
    display: flex;
    align-items: center;
}
.offer-badge-text svg {
    width: 16px;
    fill: #fff;
    margin-right: 5px;
    height: 16px;
}
  .offer-badge .offer-badge-text span {
    display: block;
    font-size: 16px;
  }
  .offer-badge .offer-badge-text:before {
      content: " ";
      position: absolute;
      bottom: -7px;
      left: 0;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 0 solid transparent;
      border-top: 7px solid #FF0000;
  }
.image-banner, .image-banner img {
    width: 100%;
    min-height: 546px;
    max-height: 546px;
    border-radius: 20px;
    object-fit: cover;
    box-shadow: 0px 5px 8px #bdbdbd;
}
.full-image-banner , .full-image-banner img{
    width: 100%;
    /* min-height: 500px;
    max-height: 350px; */
    min-height: auto;
    max-height: auto;
    border-radius: 20px;
    object-fit: cover;  
    box-shadow: 0px 5px 8px #bdbdbd;
}
.full-image-banner img , .full-image-banner iframe{
    min-height: 335px;
    max-height: 568px;
    box-shadow: 0px 0px 0px transparent;
    border-radius: 20px;
}
.full-image-banner .video{
position: relative;
}
.full-image-banner .video .overlay{
    position: absolute;
    background: #00000021;
    width: 100%;
    height: 98%;
    top: 0;
    border-radius: 19px; 
}
.full-image-banner {
    background: #fff;
}
.full-image-banner .detail{
    padding: 18px 22px
}
.full-image-banner .detail h4{
    font-weight: 700;
    margin-bottom: 0;
}
.full-image-banner .detail btn{
    max-width: auto !important;
}
.full-image-banner .detail h5 , .conference-block .leftborder-parent h5{
    margin: 6px 0 8px;
    color: #2c688d;
    font-weight: 700;
}
.join-card {
    background: #fff;
    border-radius: 20px;
    text-align: center;
    margin: 0 auto;
    padding: 40px;
    background-image: url(../../images/splash.png);
    background-position: top;
    background-repeat: no-repeat;
    background-size: 237px;
    box-shadow: 0px 5px 8px #bdbdbd;
}
.conference-lounges.networking-lounges .cm-card-body {
    margin-left: 20px;
    width: 260px;
    min-height: 212px;
    max-height: 212px;
}
.conference-lounges .cm-card-body {
    margin-left: 20px;
    width: calc( 100% - 100px );
    min-height: 212px;
    max-height: 212px;
}
.conference-lounges .card img {
    /* min-height: 400px; */
    /* max-height: 400px; */
    width: 100%;
    object-fit: contain;
}
.webhome .header-text {
    justify-content: space-between !important;
}
.webhome .cm-wrapper {
    padding-bottom: 0;
}
.ads img {
    /* min-height: 200px;
    max-height: 200px; */
    width: 900px;
    object-fit: cover;
}
.conference-stages .cm-card-body {
    min-height: 154px;
}
.circle{
    width: 80px;
    height: 80px;
    background: #92c9d2;
    position: relative;
    border-radius: 100px;
    margin: 0 auto;
}
.join-card p {
    color: #92c9d2a1;
}
.circle svg {
    width: 38px;
    height: 38px;
    position: absolute;
    top: 50%;
    left: 50%;
    fill: white;
    transform: translate(-50% ,-50%) rotate(-7deg);
}
.join-card h2{
color : #2c688d;
}
.join-card h5{
    color: #6b6b6b;
}
.speaker-details {
    /* display: flex;
    align-items: center; */
}
table.table.table-bordered.rounded-table.event-table tr:first-child th:last-child {
    border-top-left-radius: 0px;
}
table.table.table-bordered.rounded-table.event-table tr th{
    text-align: center !important;
}
/* table.table.table-bordered.rounded-table.event-table tr th:first-child{
    width: 15%;
}
table.table.table-bordered.rounded-table.event-table tr th:nth-child(2){
    width: 60%;
}
table.table.table-bordered.rounded-table.event-table tr th:nth-child(3){
    width: 25%;
} */
table.table.table-bordered.rounded-table.event-table tr th:nth-child(4), table.table.table-bordered.rounded-table.event-table tr th:nth-child(5), table.table.table-bordered.rounded-table.event-table tr th:nth-child(1) {
    min-width: 170px;
}
.speaker-details .img img {
    min-width: 70px;
    max-width: 70px;
    /* width: 70px; */
    height: 70px;
    margin-right: 12px;
    display: flex;
}
.speaker-details p.name{
    width: 240px;
    /* min-width: 240px; */
}
.speaker-details .text {
    width: calc(100% - 44px);
}
.table-bordered.rounded-table.event-table td, .table-bordered.rounded-table.event-table th {
    vertical-align: middle;
}
.circle-calender {
    width: 50px;
    height: 50px;
    background: #FF0000;
    border-radius: 100px;
    position: relative;
    display: inline-block;
}
.play-icon i {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
}
.circle-calender span svg{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% ,-50%);
    width: 18px;
    height: 18px;
    fill: white;
}
.conference-stages img {
    min-height: 380px;
    max-height: 380px;
    object-fit: cover;
}
.speaker{
    position: relative;
    margin-bottom: 30px;
}

.speaker .text-uperimg {
    position: absolute;
    bottom: 0px;
    /* left: 15px;
    width: calc(100% - 30px); */
    left: 0px;
    width: calc(100%);
    padding-left: 20px;
    padding-top: 10px;
    background-image: linear-gradient(72deg, #0d3d58, #afafaf75);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.speaker img {
    border-radius: 20px;
    width: 100%;
    /* min-height: 295px;
    max-height: 295px;
    object-fit: cover; */
    box-shadow: 2px 4px 4px #96969678;
}
.speaker .text-uperimg h3 {
    margin-bottom: 0;
    font-size: 26px;
    color: #fff;
}
.speaker .text-uperimg p{
    color: #fff;
}

.speaker .text-uperimg p ,.textparent .descript,.textparent h4,  .community-card .action_group .flex-grow-1 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
.stage-banner h3, .stage-banner span {
    text-shadow: -2px 2px 3px #151515;
}
/* .stage-banner .bottom-left {
    background: #00000026;
} */
.stage-banner .bottom-left {
    background: linear-gradient(72deg, #010304c7, #5d5d5db8);
    width: calc(100% - 32px );
    padding-bottom: 4px;
    bottom: 0;
    border-bottom-right-radius: 20px;
    padding-top: 4px;
}
span.light-blue {
    text-shadow: none;
}
.hert-tab-parent {
    display: flex;
    /* justify-content: space-between; */
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
    width: 100%;
    overflow-x: auto;
    padding-bottom: 20px;
}
.event-timetable .table-responsive {
    min-height: 102px;
    overflow: auto;
    max-height: 942px;
}
/* .event-timetable .heading-after-border .heart , .speakers-parent .heading-after-border .heart , .enquire_parents .heading-after-border .heart , .enquiredetail_parents .heading-after-border .heart{
    background: white;
} */
.hert-tab .hert svg {
    width: 150px;
    margin: 10px 18px;
    fill: #92c9d2;
}
.hert-tab.active .hert svg{
fill:#FF0000;
}
.hert {
    position: relative;
}
.centertext h4 , .centertext p {
    margin-bottom: 0;
    color: #fff;
}
.centertext p {
font-weight: 500;
}
.hert-tab.text-center h4 {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 19px;
}
.downarrow svg {
    width: 20px;
    position: relative;
    top: 60px;
}
.stage-banner img {
    min-height: 400px;
    max-height: 400px;
    object-fit: cover;
}
/* --------Community css-- */
.image-chat .chat {
    width: 35px;
    height: 35px;
    background: #FF0000;
    position: absolute;
    border-radius: 100px;
    top: 65px;
    left: 52px;
    z-index: 9;
}
.image-chat .chat.green {
    background: #4caf50;
}
.image-chat .chat.yellow {
    background: #F4E60B ;
}
.image-chat .chat svg {
    width: 23px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
}
/* .search-filter-responsive .ant-select-single:not(.ant-select-customize-input) .ant-select-selector  */
.search-filter-responsive .rounded-textbox-form .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.search-filter-responsive .rounded-textbox-form .ant-select-single:not(.ant-select-customize-input) .ant-select-selector
{
    min-width: 276px;
    height: calc(2em + .75rem + 2px);
    border-color: #d7eaed;
    margin-top: -7px;
}
.rounded-textbox-form.filter-select.pb-2 .ant-select{
    width: 100% !important;
    height: calc(1.2em + .75rem + 2px);
    font-weight: 100 !important;
}
.cummunity .community-card .img-wrapper img {
    border-radius: 100%;
    min-width: 90px;
    max-width: 90px;
    height: 90px;
}
.card.text-center.community-card .logo-wrapper img {
    min-height: 50px;
    max-height: 50px;
    object-fit: cover;
    width: 50px;
}
.cummunity-filter {
    /* position: absolute;
    top: 14px;
    left: 436px; */
    
    text-align: right;
    /* position: relative;
    margin-top: 23px; */
    width: 54px;
}
.cummunity .four-div h4 {
    font-size: 19px;
    font-weight: 500;
    -webkit-line-clamp: 1;
}
.cummunity .four-div h5 {
    color: #929292;
    font-weight: 100 !important;
    font-size: 17px;
}
.cummunity.ant-dropdown-menu-light {
    width: 210px;
}
.cummunity.ant-dropdown-menu-light .check_wrapper {
    padding: 6px 12px;
}
.ant-modal.viewpopup-product .ant-modal-body {
    padding: 0px 0 !important;
    height: 526px;
    overflow: auto;
}
.ant-modal.viewpopup-product .ant-modal-title {
    font-weight: 700;
    font-size: 22px;
}

.connection-detail-parent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.connection-detail-parent .imgparent .img-wrapper, .connection-detail-parent .imgparent .img-wrapper img {
    min-width: 100px;
    max-width: 100px;
    min-height: 100px;
    max-height: 100px;
    margin-right: 20px;
}
.connection-detail-parent .imgparent .img-wrapper img{
    border: 1px solid #bdbdbd;
}
.connection-detail-parent div:nth-child(2) {
    width: calc(100% - 100px);
}
/* .connection-detail-parent .textparent {
    width: calc(100% - 120px);
} */
.connection-detail-parent .action_group button.btn.btn.btn-blue , .connection-detail-parent button.btn.btn.btn-blue ,
.connection-detail-parent .btn.btn.btn-blue:hover
{
    margin: 12px 0 !important;
    color: #fff;
}
.gray-color{
    color: #b2cdd2;
}
.four-div {
    width: calc(100% / 4 - 20px);
    margin: 10px;
}
body.webchatopen.conference .four-div{
    width: calc(100% / 3 - 20px);
}
.six-div{
    width: calc(100% / 6 - 20px);
    margin: 10px;  
}
.two-div{
    width: calc(100% / 2 - 10px);
    margin: 5px;  
}
.connection-detail-parent .image-chat .chat {
    left: 58%;
    top: 63px;
}
.connection-detail-parent .textparent h5{
    margin-bottom: 0;
}
/* .connection-detail-parent .textparent h4 strong , .connection-detail-parent .textparent span small{
    width: 100% !important;
} */
/* .connection-detail-parent .textparent h4 , .connection-detail-parent .textparent , .connection-detail-parent .textparent h5{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
} */
.product-details-btn-parent button.btn.btn-red{
    min-width:auto;
}
.product-details-btn-parent button.btn.btn-red .spinner-border{
color: #fff !important;
margin-left: 6px;
}
.cm_ant-table .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 20px;
}
.cm_ant-table .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 20px;
}
.cm_ant-table .ant-table-container table tr:last-child td:last-child {
    border-bottom-right-radius: 20px;
}
.cm_ant-table .ant-table-container table tr:last-child td:first-child {
    border-bottom-left-radius: 20px;
}
.cm_ant-table .ant-table-container table thead th {
    background: #8BC3CB !important;
    text-align: left;
    color: #fff;
    font-weight: 700;
}
.cm_ant-table .ant-table-container table tr:nth-child(even) td {
    background: #F4F9FD;
}
.cm_ant-table .ant-table-container table th, .cm_ant-table .ant-table-container table td {
    min-width: 133px;
    font-size: 16px;
}
.cm_ant-table .ant-table-container table td p.wrap{
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 133px;
}
.ant-table-container{
    box-shadow: 0px 10px 8px #bdf5ff85;
    border-radius: 20px;
}
.cm_ant-table .action_group .circle-serial {
    width: 34px;
    height: 34px;
}
.cm_ant-table .action_group .circle-serial i {
    font-size: 19px;
}
.cm_ant-table .action_group .circle-serial svg {
    width: 20px;
}
.tonext{
    background: #bfe1e6;
    width: 40px;
    height: 40px;
   z-index: 1;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 2px solid #fff;
    position: absolute;
    right: -7px;
    top: 46%;
    transform: translate(0px, -50%);
}
.tonext .fa{
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.event-tab-parent {
    display: flex;
    justify-content: center;
    width: 100%;
    /* overflow: scroll; */
    overflow-y: auto;
    padding-bottom: 27px;
    padding-left: 73px;
}
.event-tab {
    width: 100px;
    height: 100px;
    background: var(--blue_bg);
    margin: 04px;
    border-radius: 22px;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
}
.event-tabbody{
    display: flex;
    width: 72%;
    margin-left: 10%;
    background: #fff;
    border: 1px solid #8bc3cb;
    border-radius: 20px;
    padding: 25px 14px;
    align-items: flex-start;
    margin: 16px auto;
    position: relative;
}
.ant-picker-range .ant-picker-clear {
    right: 5px !important;
}
.event-tabbody .time-details{
    text-align: center;
    border-right: 1px solid #eee;
}
.event-tabbody .event-detail{
    width: calc( 100% - 20% - 3%);
    padding-left: 3%;
    border-left: 1px solid #eee;
    
}
.event-tabbody .event-detail small {
    font-size: 15px;
    color: #999;
    margin: 8px;
}
.event-tabbody .time-details h6 {
    width: 100%;
    font-weight: 700;
    color: #5c5c5c;
}
.cmn-diary-meeting.event-tabbody {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 0 3px rgb(0 0 0 / 15%);
    border: 1px solid #8bc3cb30;
    padding: 0;
    flex-wrap: wrap;
}
.cmn-diary-meeting .diaryevnt-time, .cmn-diary-meeting .diary-evnt-dtls, .cmn-diary-meeting .time-details , .cmn-diary-meeting .event-detail{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.events-type-diary > h4 {
    margin: 0;
    text-align: left;
    background: #2A688E;
    color: #fff;
    padding: 0.5rem 2rem;
    font-size: 1.3rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    border-radius: 5px 5px 0px 0px;
}
.conference-body-event .events-type-diary > h4{
    background: #D13A27;
}
.btn.btn-con {
    background: #d13a27;
    color: #fff;
    font-weight: 600;
    font-size: 13px;
    padding: 5px 20px;
    border-radius: 20px;
}
.conference-body-event .event-detail{
    text-align: left;
    width: calc(100% - 16%);
}
.btn-con:hover{
    background: #2A688E;
    color: #fff !important;
}
.time-details h6 b {
    font-weight: 600;
    color: #000;
    font-size: 17px;
}
.event-tabbody .time-details {
    width: 16%;
}
.event-tabbody .event-detail h2 {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 0;
    color: #515151;
}
.event-tabbody .event-detail h4 {
    font-weight: 700;
    font-size: 17px;
    margin: 8px 0 9px;
    color: #2c688d;
}
.event-detail .profile .text p:last-child {
    font-size: 10px;
    color: #92c9d2;
}
.event-detail .profile .text p:first-child {
    font-size: 13px;
}
.event-detail .profile img {
    width: 45px;
    height: 45px;
}
.event-detail .speaker-details img {
    min-width: 45px;
    max-width: 45px;
    height: 45px;
    border-radius: 100px;
}
.speaker-details .profile .text {
    width: auto;
    margin: 7px 0 9px 9px;
}
.speaker-details .profile {
    margin: 12px 0;
}
.event-tabbody .time-details button svg, .event-tabbody .time-details a svg{
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
.event-tabbody .time-details button svg path:nth-child(2), .event-tabbody .time-details a svg path:nth-child(2) {
    fill: white;
}
.event-tabbody .time-details button svg path , .event-tabbody .time-details a svg path {
    stroke-width: 5px;
    fill: #c13624;
}
.event-tabbody .time-details button .cal svg, .event-tabbody .time-details a .cal svg{
    width: 15px;
    height: 15px;
}
.event-tabbody .time-details button .cal svg path ,.event-tabbody .time-details a .cal svg path {
    fill: white;
}
.event-tab div{
    height: 100px;
    display: table-cell;
    flex-wrap: wrap;
    vertical-align: middle;
    width: 100px;
    padding: 0 10px;
}
.event-tab div h3 , .event-tab div h6{
    margin-bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
}

.event-tab.active:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid var(--btncolor);
    z-index: 999;
    position: absolute;
    left: 50%;
    transform: translate(-50% , 0px);
}
.event-tab.active{
    background: var(--btncolor);
}
.product-details-btn-parent button.btn.btn-red{
    min-width:auto;
}
.product-details-btn-parent button.btn.btn-red .spinner-border{
color: #fff !important;
margin-left: 6px;
}
.manage-company .edit-tabular.profile-dtl ul.detail_row li:first-child {
    border-right: 1px solid #dee2e6;
}
.manage-company label {
    color: #2a2c2d !important;
    font-weight: 600 !important;
}
.manage-company button {
    min-width: 140px !important;
}
.edit-companysector .form-group.form-control{
    display: inline;
    margin-right: 6px;
}
.checkbox_tab-body {
    padding: 20px 29px 0px;
}
.checkbox-tab-parent{
    border-bottom: 1px solid #8cd0d861;
    padding: 20px  0;
}
.edit-companysector .form-group.form-control {
    padding: .4rem 1.5rem;
    font-size: 13px;
    background: #d9eaed;
    border: 1px solid #cdcdcd;
    color:#000;
}
.checkbox-tab{
    display: flex;
    font-weight: 600;
}
.calenderbtn {
    background: white;
    padding: 4px 7px 6px 22px;
    border: 1px solid #3b3b3b8a;
    margin: 10px 8px;
    border-radius: 100px;
    color: #000;
    display: inline-block;
    line-height: 36px;
    min-width: 200px;
}
.apple-link , .google-link{
    background: white;
    padding: 4px 32px;
    border: 1px solid #3b3b3b8a;
    margin: 10px 8px;
    border-radius: 100px;
    color: #000;
    display: inline-flex;
    line-height: 36px;
    justify-content: center;
    align-items: center;
    min-width: 200px;
}
.react-add-to-calendar__dropdown ul {
    padding-inline-start: 0;
}
.react-add-to-calendar__button span{
    font-size: 22px;
    color: #000;
}
.apple-link i.fa.fa-apple , .google-link i.fa.fa-google{
    font-size: 24px;
    padding: 4px;
    margin-right: 4px;
}
.calenderbtn span svg {
    width: 40px;
    margin-left: 10px;
}
.calenderbtn span:first-child {
    min-width: 134px;
    display: inline-flex;
    text-align: center;
}
.upload-wrapper.delete-evt {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 8px;
    top: -15px;
}
.upload-wrapper.delete-evt svg {
    width: 15px;
    fill: white;
}

@media (min-width:1141px) and (max-width:1465px){
    .four-div , .product-showcase-devide {
        width: calc(100% /3 - 20px);
        margin: 10px;
    }
}
@media (min-width:1200px) and (max-width:1500px){
    .two-div{
        width: calc(100% );
        margin: 5px;  
    }
}

@media (min-width:1285px) and (max-width:1465px){
    .six-div , .product-showcase-devide {
        width: calc(100% / 5 - 20px);
    }   
}
@media (min-width:1100px) and (max-width:1284px){
    .six-div , .product-showcase-devide{
        width: calc(100% / 4 - 20px);
    }   
}
@media (min-width:700px) and (max-width:1099px){
    .six-div , .product-showcase-devide{
        width: calc(100% / 3 - 20px);
    }   
}

@media (min-width:445px) and (max-width:699px){
    .six-div , .product-showcase-devide{
        width: calc(100% / 2 - 20px);
    }   
}
@media (min-width:300px) and (max-width:444px){
    .six-div, .product-showcase-devide  {
        width: calc(100% );
    }  
    .two-div{
        width: calc(100%  );
        margin: 5px;  
    } 
   
}

@media (min-width:768px) and (max-width:1140px){
    .four-div {
        width: calc(100% /2 - 20px);
        margin: 10px;
    }
    .stack {
        background: #eefdff;
        min-width: 200px;
        max-width: 200px;
        flex-wrap: nowrap;
    }
    .event-timetable .table-responsive table {
        min-width: 1200px;
    }
}
@media (min-width:992px) and (max-width:1500px){
    .conference-lounges .cm-card-body , .conference-lounges.networking-lounges .cm-card-body{
        width: calc( 100%  - 40px);
    }
    .conference-lounges .card img {
        /* min-height: 300px; */
        /* max-height: 300px; */

    }
}
@media (min-width:300px) and (max-width:767px){
    .speaker-details .profile {
        justify-content: center;
    }
    .event-tab-parent {
        padding-left: 0;
        justify-content: initial;
    }
    .event-tab{
        margin: 0 auto;
    }
    .ads img {
     width: 100%;
    }
    .event-tab.active:after {
        transform: translate(-50% , -1px);
    }
    .event-tabbody {
        padding: 10px 10px;
        flex-wrap: wrap;
        width: 100%;
    }
    .speaker_img {
        justify-content: center;
    }
    .event-tabbody .time-details, .event-tabbody .event-detail {
        width: 100%;
        border-right: 0;
        margin-bottom: 20px;
    }
    .event-tabbody .event-detail{
        text-align: center;
    }
  
.join-card {
  margin: 15px;
}
    .conference-lounges .cm-card-body , .conference-lounges.networking-lounges .cm-card-body{
        width: calc( 100%  - 40px);
    }
    .four-div {
        width: calc(100% );
        margin: 10px;
    }
    .stack {
        background: #eefdff;
        min-width: 200px;
        max-width: 200px;
        flex-wrap: nowrap;
    }
}
@media (min-width:300px) and (max-width:991px){
    .full-image-banner .detail {
        flex-wrap: wrap;
    }
    .full-image-banner .detail div {
        width: 100%;
    }
    .full-image-banner .detail div.d-flex{
flex-wrap: wrap;
    }
    .full-image-banner .detail div h6{
        flex-wrap: wrap;
    }
    .full-image-banner .detail div h6 span {
        width: 100%;
        text-align: left;
        margin: 3px 0 !important;
    }
}
@media (min-width:700px) and (max-width:1192px){
    .two-div{
        width: calc(100% / 3 - 20px );
        margin: 5px;  
    }
}

@media (min-width:1248px) and (max-width:1400px){
    .event-tabbody .time-details {
        width: 20%;
    }
    .event-tabbody .event-detail.diary-evnt-dtls, .conference-body-event .event-detail{
        width: calc(100% - 20%) !important;
    }
    .conference.webchatopen .container-fluid {
        padding: 0 30px !important;
    }
    .conference.webchatopen nav.navbar.navbar-expand-lg {
        overflow-x: auto;
    }
    /* .conference.webchatopen .product-showcase-devide , */
    .conference.webchatopen .four-div{
        width: calc(100% / 2 - 20px);
     margin: 10px
    }
    .conference.webchatopen .product-showcase-devide{
        width: calc(100% / 3 - 20px);
        margin: 10px
    }
    .conference.webchatopen .event-tabbody {
        display: flex;
        width: 90%;
    }
    .conference.webchatopen .footer-big .col-xl-3.col-lg-12, 
    .conference.webchatopen .footer-big .col-xl-2.col-lg-6, 
    .conference.webchatopen .footer-big .col-xl-2.col-lg-6, 
    .conference.webchatopen .footer-big .col-xl-4.col-lg-6, 
    .conference.webchatopen .footer-big .col-xl-3.col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}
@media (min-width:1400px) and (max-width:1500px){
    .conference.webchatopen .container-fluid {
        padding: 0 30px !important;
    }
    .conference.webchatopen .event-tabbody {
        display: flex;
        width: 90%;
    }
    .conference.webchatopen .event-tabbody .time-details {
        width: 22% ;
    }
    .conference.webchatopen .product-showcase-devide{
        width: calc(100% / 4 - 20px);
        margin: 10px
    }
}
@media (min-width:992px) and (max-width:1200px){
    nav.navbar.navbar-expand-lg {
        overflow-x: auto;
    }
}