.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important;
}
/*.navbar-expand-lg .navbar-nav {*/
/*    margin: 0 -45px !important;*/
/*}*/
.stack {
    background: #eefdff;
    width: calc(100% / 5 - 10px);
    padding: 20px;
    border: 1px solid #81cbd4;
    border-radius: 20px;
    min-height: 208px;
    max-height: 208px;
    position: relative;
    box-shadow: 0px 5px 8px #92c9d29c;
    margin-bottom: 20px;
}
.graph {
    margin: 0px 0px;
    position: absolute;
    bottom: 1px;
    left: 2px;
}
.stack-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 60px 0;
    flex-wrap: wrap;
}
.stack h5 {
    font-weight: 700;
    padding: 12px 0;
    margin-bottom: 0;
}
.stack.skyblue h5 {
    color: #92c9d2;
}
.stack.skyblue .icon {
    background: #92c9d2;
}
.stack.blue h5 {
    color: #2c688d;
}
.stack.blue {
    background: #eefdff;
}
.stack.blue .icon {
    background: var(--btncolor);
}
stack.purpal h5 {
    color: #34287e;
}
stack.purpal{
    background: #edf8ff
}
.stack.gray .icon {
    background: #51586a;
}
.stack.gray {
    background: #f7f7f7
}
.stack.gray h5 {
    color: #51586a;
}
.stack.purpal .icon {
    background: #34287e;
}
.stack.red{
    background: #fff9f8
}
.stack.red h5 {
    color: #FF0000;
}
.stack.red .icon {
    background: #FF0000;
}
.stack .icon {
    width: 70px;
    height: 70px;
    background: pink;
    border-radius: 100px;
    position: relative;
}
.stack .icon svg {
    width: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    fill:#fff
}
.brandhome .graph-wrapper .search_wrapper{
    max-width: 350px;
    min-width: 350px;
}
.graph-container {
    width: 100%;
    height: 420px;
    margin-bottom: 40px;
}
.brandhome .home-heading {
    font-size: 40px;
    margin-bottom: 76px;
}
.slider-container {
    padding: 30px 50px;
    margin-bottom: 40px;
}
/* ------------slider---------------- */
.card.recent-contract-card {
    padding: 25px;
    min-height: 300px;
    text-align: center;
}
.recent-contract-card .img-wrapper {
    margin: 0 auto;
}
.recent-contract-card .img-wrapper img {
    width: 100px !important;
    height: 100px;
    border-radius: 100px;
}
.recent-contract-card .texts h4 {
    font-size: 20px;
    padding: 12px 0 0 0;
    margin-bottom: 0;
    color: #414a5c;
    font-weight: 700;
}
.recent-contract-card .texts p {
    margin-bottom: 0;
    padding: 6px 0 15px;
}

.recent-contract-card .chat svg {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
}

.recent-contract-card .chat {
    background: red;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    display: block;
    position: relative;
}
.btn-combo{
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}
.btn-combo button.btn.btn-blue {
    min-width: 150px;
    padding: 9px 20px;
}
.zoom svg{
    width: 25px;
    margin-right: 6px
}
button.btn.btn-blue .zoom {
    width: 25px;
    font-size: 11px !important;
}
button.btn.btn-blue .zoom svg {
    width: 25px;
    margin-right: 8px;
    padding-right: 5px;
}
/* ----------------home page--------- */
.brand-home-banner-bg , .brandhome{
    /* background-image: url(../images/brand-home.jpg); */
    background-position: bottom;
    background-repeat: no-repeat!important; 
    background-size: cover!important;
    min-height: 100vh;
    background: #fff;
  
}
/* .brandprofile.jpg{
    background-image: url(../images/brandprofile.jpg);
} */
.manage-card{
    background: #fff;
    border: 1px solid #dbeaed;
    border-radius: 15px;
    padding: 30px;
    margin: 40px 0;
    box-shadow: 0px 6px 12px #a9e9f4;

}
.manage-service .form-group label span {
    font-weight: 500 !important;
    color: #3c3d48;
}
.heighlighter {
    font-size: 30px;
    /* color: #ff1100; */
    font-weight: 800;
    margin-bottom: 0;
}
.manage-card h5{
    font-size: 22px;
    font-weight: 700;
    display: flex;
}
.manage-card .form-check.vat-checkbox {
    padding: 15px;
    padding-left: 1rem;
    margin-bottom: 0;
}
.my-masonry-grid {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.my-masonry-grid_column:nth-child(odd) {
    width: 49% !important;
    margin-right: 1%;
}
.my-masonry-grid_column:nth-child(even) {
    width: 49% !important;
    margin-left: 1%;
}
.btn-absolute{
    position: absolute;
    top: -101px;
    right: 10px;
}
.staff .community-card .row{
    align-items: center;

}
.staff span.action_group .btn,.staff span.action_group .btn:hover {
    font-size: 12px;
    color:#fff
}

.btn.btn-red.addbtn ,.btn.btn-red.addbtn:hover ,.btn.btn-red.addbtn:focus .btn.btn-red.addbtn-gray:hover , .btn.btn-red.addbtn-gray:focus {
    font-size: 12px;
    padding: 6px 24px;
    min-height: 35px;
    margin-right: 20px !important;
}
.btn.btn-red.addbtn-gray {
background: #c3d6db;
margin-right: 20px;
}
.btn.btn-red.addbtn.addblue, .btn.btn-red.addbtn.addblue:hover {
    background: #d3d3d3 !important;
    margin-right: 0px !important; 
}
.confrence-details .card img {
    /* min-height: 390px;
    max-height: 390px; */
    object-fit: cover;
}
.btn.btn-red.addbtn-gray:hover , .btn.btn-red.addbtn-gray:focus{
    margin-right: 20px;
}
.carousel-productshowcase.carousel-industry img {
    min-height: 222px !important;
    object-fit: contain;
}

.media-list li.list-inline iframe {
    border-radius: 20px;
}
.images-video #get_file{
    min-height: 290px !important;
    margin-left: 30px;
}
.images-video .card.video-card img {
    width: 100%;
    height: 300px;
    /* object-fit: cover; */
    object-fit: inherit;
    border: 1px solid #eee;
}
.brand .text-right .btn {
    background: #c3d6db;
}
/* .staff .card.community-card {
    min-height: 190px;
    max-height: 190px;
} */
.staff .card.community-card small {
    word-break: break-word;
}
.staff .community-card img {
    border-radius: 100%;
    width: 110px;
    height: 110px;
}
.prodoct-showcase-slider {
    padding: 22px 14px;
}
.carousel-wrapper .card{
    /* text-align: center;
    justify-content: center;
    width: 95% !important; */
    /* height: 300px; */
    border-radius: 0;
    object-fit: cover;
    min-width: 100%;
    max-width: 100%;
}
  .productshowcase.carousel-wrapper .card{
   justify-content: center;
   padding: 20px;
   min-height: 450px;
   max-height: 450px;
   min-width: 312px;
   max-width: 312px;
   width: 270px !important;

}
.prodoct-showcase-slider .card.community-card img {
   /* min-width: 100%;
    height: 300px;
    border-radius: 0;
    object-fit: cover; */
    height: 300px;
    border-radius: 0;
    object-fit: cover;
    min-width: 100%;
    max-width: 100%;
  
}
.productshowcase .prodoct-showcase-slider .card.community-card img {
    height: 300px;
    border-radius: 0;
    object-fit: cover;
    min-width: 270px;
    max-width: 270px;
    width: 270px;
}
.product-showcase-slider-wrapper .fa-long-arrow-left {
    margin-left: -30px !important;
}

.product-showcase-slider-wrapper .fa-long-arrow-right {
    margin-right: -30px !important;
}

.images-video .card.video-card .video-parent {
    position: relative;
}
 .layerdiv {
    width: 100%;
    background: #1212125c;
    position: absolute;
    top: 0;
    z-index: 9;
    min-height: 300px;
    border-radius: 20px;
    max-height: 300px;
    cursor: pointer;
}
.without-sidenav .body_container.profile-body {
    margin: 0px 16%;
}
.product-showcase-devide {
    width: calc( 100% / 6 - 16px);
    margin: 8px;
}
.addproduct-popup .upload-wrapper.product, .addproduct-popup .upload-wrapper, .addproduct-popup .upload-container-pic, .addproduct-popup .upload-container-pic img, .addproduct-popup label.upload-photo-wrapper.upload-container-pic.rectangle img {
    min-height: 100% !important;
    max-height: 100% !important;
    height: 100% !important;
}
.popup.addproduct-popup {
    top: 0;
    left: 0;
    z-index: 999;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
    overflow: auto !important;
}
.addproduct-popup .popup_inner {
    min-height: auto;
    height: auto;
    margin-bottom: 40px;;
    height: auto !important;
    margin-top: 40px;
    overflow: unset;
    max-height: auto;
}
.addproduct-popup .popup_inner div {
    background: white;
    border-radius: 20px;
}
.spot-admin .btn.btn-primary, .spot-admin .btn.btn-primary:focus, .spot-admin .btn.btn-primary:active
{
    margin:12px 6px !important
}
@media (min-width:300px) and (max-width:480px){
    .product-showcase-devide {
        width: calc( 100%);
        margin: 8px;
    }
}

@media (min-width:480px) and (max-width:650px){
    .product-showcase-devide {
        width: calc( 100% /2 - 16px);
        margin: 8px;
    }
}
@media (min-width:651px) and (max-width:1109px){
    .product-showcase-devide {
        width: calc( 100% /3 - 16px);
        margin: 8px;
    }
}
@media (min-width:1110px) and (max-width:1249px){
    .product-showcase-devide {
        width: calc( 100% /4 - 16px);
        margin: 8px;
    }
}
@media (min-width:1250px) and (max-width:1400px){
    .product-showcase-devide {
        width: calc( 100% /5 - 16px);
        margin: 8px;
    }
}