

.exhibition-btn-wrapper {
  width: calc(100% - 10%);
}












/*******************************Pranaw CSS *****************************/


.cm-form-wrapper {
    max-width: calc(100% - 20%);
    margin: 0 auto;
}

.cm-form-wrapper .form-group {
    margin-bottom: 30px;
}


.ant-picker,  .diary-meeting-form .ant-input-number, .diary-meeting-form .ant-select {
  border-color: #9aabad !important;
  height: calc(1.8em + .75rem + 2px);
  border-radius: 100px !important;
  padding: .3rem 1.2rem !important;
  width: 100% !important;
  font-size: 16px !important;
}
.diary-meeting-form .ant-picker,  .diary-meeting-form .ant-input-number, .diary-meeting-form .ant-select{
  border-radius: 5px !important;
}
.diary-meeting-form .ant-input-number .ant-input-number-input{
   padding-top: 3px;
   font-weight: 600;
   font-size: 15px;
}
.diary-meeting-form .ant-select.top-Rightmee{
  padding: 0px !important;
}
.diary-meeting-form .ant-select.top-Rightmee .ant-select-selector{
  padding: .3rem 1.2rem !important;
}
.ant-select-selection-item{
  font-weight: 600;
  font-size: 15px;
}
.diary-meeting-form .ant-select .ant-select-selector{
  border: 0px none !important;
  background: transparent !important;
}
.diary-meeting-form .ant-select .ant-select-arrow{
  background: transparent;
}
.diary-meeting-form .ant-select .ant-select-selector .ant-select-selection-item{
  line-height: 1.5 !important;
  padding-right: 0px !important;
}
.mac .diary-meeting-form .ant-select .ant-select-selector .ant-select-selection-item{
  line-height: 1.8 !important;
}
.mac .diary-meeting-form .notiftime .ant-select .ant-select-selector{
  padding-left: 0.5rem !important;
}
.diary-meeting-form .dirymeeting-time .ant-select .ant-select-selector .ant-select-selection-item{
  line-height: 2 !important;
}

#inlineCheckbox1, #inlineCheckbox2 {
    width: 20px;
    height: 20px;
    opacity: .7;
}

.check-group-wrap {
    display: flex;
    padding-top: 5px;
}

.check-group-wrap:first-child {
    margin-right: 10px;
}

.btn.btn-primary, .btn.btn-primary:focus, .btn.btn-primary:active {
      background: var(--btncolor);
      color: #fff;
      border-radius: 100px !important;
      min-height: 48px !important;
      max-height: 48px !important;
      line-height: 48px !important;
      padding: 0px 24px !important;
      min-width: 142px !important;
      font-weight: 700 !important;
      margin: 12px 0px !important;
      font-size: 19px !important;
      border-color: transparent;
      outline: none;
}

.btn.btn-primary:hover {
    background-color: var(--btncolor);
    color: #fff;

}

.btn.btn-secondary {
    background: #CEE6F5;
    color: rgb(105,119,144);
    border: none;
    border-radius: 100px;
    min-height: 48px;
    padding: 9px 24px;
    min-width: 142px;
    font-weight: 700;
    margin: 12px 0px;
    font-size: 19px;
}

.btn.dtn-dropdown {
    background: #fff;
    color: rgba(105,119,144, .4);
    min-height: 48px;
    padding: 9px 24px;
    min-width: 142px;
    font-weight: 400;
    font-size: 14px;
    border-radius: 2rem;
    border: 1px solid #D7EAED;
    width: 100%;
}

.btn.btn-light {
    background: #ECFEFD;
    color: rgb(140,146,153);
    border-radius: 100px;
    border: 1px solid rgb(220,235,234) ;
    min-height: 48px !important;
    padding: 0px 24px !important;
    line-height: 48px;
    min-width: 142px;
    font-weight: 700;
    margin: 12px 0px;
    font-size: 19px;
    /* float: right;
    margin-right: 125px; */
}
.btn.btn-white {
  background: #FFF;
  color: rgb(140,146,153);
    border-radius: 100px;
    border: 1px solid #2D688D;
    min-height: 48px !important;
    padding: 0px 24px !important;
    line-height: 48px;
    min-width: 142px;
    font-weight: 700;
    margin: 12px auto;
    font-size: 19px;
    max-width: 210px;}

.btn-light i {
  margin-right: 7px;
  font-size: 15px;
}



.btn.btn-blue {
  background: var(--btncolor);
  color: #fff;
  border-radius: 100px;
  max-width: 150px;
  border: 1px solid rgb(220,235,234) ;
  line-height: 1.5;
  min-height: 30px;
  padding: 5px 20px;
  font-size: 13px;
  font-weight: 700;
}
button.btn.btn-blue .zoom svg path {
  fill: #fff !important;
}
.btn.btn-blue-large {
  background: var(--btncolor);
  color: #fff;
  border-radius: 100px;
  line-height: 1.5;
  min-height: 48px;
  padding: 11px 24px;
  min-width: 180px;
  font-weight: 700;
  margin: 12px 0px;
  font-size: 19px;

}


.form-check {
    opacity: .8;
    font-size: 12px;
}

.success-circle {
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    text-align: center;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0px);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    background-color: #57AA64;
}

.ant-select-selection {
    background-color: green !important;
}

.ant-menu-item-selected {
    background-color: indianred;
}
.confrence-view-detail .card img {
  /* min-height: 280px;
  max-height: 280px; */
  object-fit: cover;
}
.searchprice p {
  font-size: 17px;
  color: rgb(39, 39, 39);
}
.upload-inner > div , .card.video-card > div {
  padding-top: 0px !important;
}
.confrence-details .cm-card-body{
  z-index: 99;
}
.upload-wrapper .top-right i {
  color: #fff;
}
.upload-wrapper .top-right {
  top: 15px;
  left: 18px;
  color: #fff;
}
textarea.form-control.showtextarea ,  .form-control.showtextarea:focus{
  background: transparent;
  border: none !important;
  font-family: "Calibri";
}
.card-wrapper.product-detail h1.light-black {
  text-transform: capitalize;
  font-size: 50px;
  color: #737373;
}
.ant-menu-item ant-menu-item-only-child {
    margin-top: -7px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
   background-color: transparent !important;
}

.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
    margin-top: -5px !important;
}
.ant-menu-vertical > .ant-menu-item {
    margin-top: -5px !important;
}


.cm-form  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: calc(2.2em + 0.4rem + 2px);
    padding: .4rem 1.5rem;
}

/*.selectNumber {*/
/*    position: absolute;*/
/*    top: 47%;*/
/*    left: 40px;*/
/*    font-size: 16px;*/
/*    opacity: .7;*/
/*}*/

/*.inputPhoneNumber {*/
/*    position: relative;*/
/*    padding-left: 150px !important;*/
/*}*/
/*.drop-checkbox {*/
/*    position: relative;*/

/*    float: right;*/
/*    width: 20px;*/
/*    height: 20px;*/
/*}*/

/*.drop-list {*/
/*    position: absolute;*/
/*    right: 10px*/
/*}*/



.react-dropdown-tree-select .dropdown .dropdown-trigger {
  background: #fff;
  display: block !important;
  border-color: #9aabad !important;
  height: 50px;
  border-radius: 100px;
  padding: 0 9px !important;
  width: 100%;
  font-size: 16px;
  /* overflow: hidden !important; */
  position: relative;
}

.react-dropdown-tree-select .dropdown {
  display: block !important;
}

.tag {
  white-space: nowrap;
  display: block;
}

.tag-item .search {
  border: 0 !important;
  width: 100%;
  display: block;
  padding: 6px;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after,
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
  position: absolute;
  right: 9px;
  z-index: 9;
  color: #fff !important;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after{

  right: 10px;
  bottom: 0px;

}
span.inputtrigger {
  position: absolute;
  right: 32px;
  bottom: 23px;
  background: #fff;
  padding: 10px;
}

.blue-border {
  border: 1px solid #A7C1D1;
  border-radius: 7px;
  background-color: rgb(245,254,255);
}

.card-wrapper {
  position: relative;
  margin: 20px;
  padding: 50px 50px;
  margin-top: 80px;
}

.ant-tabs-top > .ant-tabs-nav::before {
  display: none;
}


.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}




.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  border-bottom: 0px !important;
  position: absolute;
  top: -23px;
  left: 50%;
  margin-left: -140px;
  color: #fff !important;

}

.nav-tabs .nav-link {
  
  border-radius: 25px !important;
  min-width: 145px;
  background-color: #ABBCC7;
  font-weight: 700;
  margin-left: 10px;
  color: #fff;
  text-align: center;
  padding: 12px 8px;
  position: relative;
}


.nav-tabs .nav-link.active  {
  background-color: #2c688d !important;
  color: #fff !important;
 
}

.nav-pills-wrapper {
  position: absolute;
  margin: 0 auto;
  top: -22px;
  align-items: center;
}









.nav-tabs  {
  border-bottom: 0px !important;
  position: absolute;
  top: -23px;
    left: 50%;
    transform: translate(-50%, -1px);
    color: #fff !important;
}

/* .nav-tabs .nav-link:first-child{
   margin-left: auto;
}

.nav-tabs .nav-link:last-child{
  margin-right: auto;
} */

.nav-tabs .nav-link.active  {
  background-color: #2c688d !important;
  color: #fff !important;
 
}
/* .popup {
  z-index: 999 !important;
} */
.view-stage-detalis .card.video-card, .view-stage-detalis .card.video-card img {
  width: 200px;
    min-width: 200px;
    min-height: 200px;
  object-fit: cover;
}
.upload-wrapper.edit-icon svg {
  width: 18px;
}
.levebtn{
  position: absolute;
  top: 16px;
  right: 62px;
  z-index: 999;
}

.upload-wrapper.edit-icon {
  width: 40px;
  height: 40px;
  top: -50px;
  right: -27px;
}
.upload-wrapper.edit-icon svg {
  width: 18px;
}
nav.nav.nav-tabs a:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 8px solid #abbcc7;
  bottom: -7px;
  left: 48%;
}
nav.nav.nav-tabs a.nav-item.nav-link.active:after{
  border-top: 8px solid #2c688d;
}
.nav-pills-wrapper {
  position: absolute;
  margin: 0 auto;
  top: -22px;
  align-items: center;
}

.card {
  border: none !important;
  border-radius: 20px !important;
}

.card img {
  z-index: 0; 
  border-radius: 20px;
  position: relative;
  object-fit: cover;
}

.cm-card-body {
  position: relative;
  border: none;
  border-radius: 20px;
  margin-top: -75px;
  background-color: #fff;
  z-index: 999;
width: calc(100% - 20px);
margin-right: auto;
margin-left: auto;
  font-weight: 700 !important;
  padding: 20px 20px 10px 20px;
  box-shadow: 0px 4px 5px #bfe1e6
}
.cm-card-body  .des::after {
  content:"";
  height: 2px;
  width: 100%;
  left: 1.25rem;
  right: 1.25rem;
  top: 20px;
  width: auto;
  position: relative;
  background-color: #EBF1F5;

}

.cm-card-body p:not(:last-child) , .des {
  color: #78B9E3;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
  overflow: hidden;
  overflow-y: hidden;

 
}

.popup_inner {
  position: absolute;
  border-radius: 20px;
  max-height: 100vh;
  margin: 0;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: white;
  overflow-y: auto; 
   -webkit-overflow-scrolling: touch;
}

.popup_inner.form {
  /* min-width: 420px;
  max-width: 420px; */
  min-width: 800px;
    max-width: 800px;
}
.popup_inner.small.form {
  min-width: 420px;
  max-width: 420px;
}
.popup_inner::-webkit-scrollbar { 
  display: none; 
} 

.close-btn {
  width: 40px;
  top: 16px;
  right: 16px;
  position: absolute;
  background: transparent;
  color: red ;
  border: none;
}

.table-wrapper {
  padding:  30px 20px;
}

.profile-icon-wrapper, .profile-icon-wrapper img {
  margin: 0 auto;
  width: 160px;
  height: 160px;
  border-radius: 100% !important;
    position: relative;
  
}

.profile-icon-wrapper img {
    border-radius: 100%;
    height: 100%;
}

.profile-icon-wrapper .edit-image {
    position: absolute;
    top: 50%;
    right: 0;
    height: 35px;
    width: 35px;
    line-height: 35px;
}

 .profile-icon-wrapper .btn{
    bottom: 0;


}


.profile-icon-wrapper .edit-image img{
    border-radius: 0px;
    background: transparent;
}
.community-card {
  position: relative;
  box-shadow: 0px 4px 8px #bdf5ff85;
  border: 1px solid #DAE9ED !important;
  padding: 20px;
  max-width: 450px;
  margin-bottom: 30px;
}

.video-card {
  position: relative;
  box-shadow: 0px 4px 8px #bdf5ff85;
  border: 1px solid #DAE9ED !important;
  border-radius: 20px !important;
  min-height: 300px;
}

.video-card iframe {
  border-radius: 20px ;
  min-height: 300px;
}


.community-card  img::after{
 position: relative;
 content: '';
 height: 2px;
 background-color: red;
 bottom: 0;
}



.community-card img {
  border-radius: 100%;
}

.community-card .row {
  padding: 20px;
}
.community-card h4 {
  color: #3974BB ;
  margin-bottom: 0px;
}

.community-card span::after{
    contain: '';
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: red;
    bottom: 0;
    left: 0;
}

.buss-connection {
  margin:  0 auto;
}

.buss-connection img {
  width: 100px;
  height: 80px;
  padding: 0;
}

.popup .header-text h4 {
  margin: 0 auto;
}



/* .upload-photo-wrapper input{
  width:100px;
  height:40px;
  position:absolute;
  left:0;
  top:0;
  cursor:pointer;
} */


.upload-photo-wrapper   {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;

}
.upload-container-pic, .upload-container-pic img {
  margin: 0;
  height: 100px !important;
  width: 100%;
  border-radius: 100px;
  padding: 0 !important;
  position: inherit;
  transform: translate(0px, 0px);
  top: 0;
  left: 0;
}
.upload-photo-wrapper label  {
  margin: 0 auto; 
  
  
}


input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}
.media-list li.list-inline img {
  width: 320px;
  /* height: 320px; */
  object-fit: cover;
}
.upload-wrapper {
  margin: 20px auto;
  text-align: center;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  background-color: #8BC3CB;
  color: #fff;
  background-image: url('../images/video.psd');
  cursor: pointer;
  position: relative;
}

.upload-wrapper.product {
    width: 100%;
    border-radius: 15px;
    min-height: 150px;
}

.upload-wrapper  img {
    height: 20px;
}
.upload-wrapper p {
    font-size: 10px;
}

.rectangle-upload-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  height: 100px;
  border-radius: 10px;
  background-color: #8BC3CB;
  color: #fff;
  background-image: url('../images/video.psd');
  cursor: pointer;
  position: relative;
}

.edit-icon-wrapper {
position: absolute;
right:  0;
bottom: 0;
width: 30px;
height: 30px;
line-height: 30px;
border-radius: 100%;
background-color: #8BC3CB;
margin: 0 auto;
}

.chat-wrapper {
  max-height: 600px;
  width: 60px;
  border: 0px solid black;
  border-radius: 25px;
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 999;
  box-shadow: 0 25px 50px -12px rgba(233,247,253, 1);
  background-color: inherit;
}

/* 
.chat-icon-wrapper {
  background-color: #D33B27;
  border-radius: 100%;
  color: #fff;
  margin: -10px;
  padding: 5px;
  margin-bottom: 5px;
 
} */


.text-wrapper p {
  margin-top: 50px;
  margin-bottom: 50px;
}

.add-promo-code .ant-checkbox-group , .add-promo-code .ant-checkbox-group-item{
  display: block;
  margin: 0;
}

.cm-table tr :last-child{
  text-align: right !important;
  justify-content: center;
}

.cm-table tr :nth-child(2){
  text-align: left !important;
}

.fragment-wrapper {
  margin-top: 30px;
}

.welcome{
  text-align: center;
  color: black;
  padding-top: 303px;
}


/* input[type=file] {
  display: none;
} */

.community-card .action_group {
  text-align: left;
  font-weight: 700;
}

/* .blue {
  color: #3974BB;
} */

.fragment-wrapper .btn-blue-large {
  opacity: .3;
}

.w-95  {
  width: 95%;
}

.product-detail h1 {
    font-weight: 700;
    color: #51595F;
}

.light-blue {
  color: #4fa5dc; 
}

.product-detail img{
    border-radius: 20px;
    padding: 0;
}
.product-detail .community-card {
    padding: 0;
}

.community-card table.table-bordered tr:not(:last-child){
  border-bottom: 2px solid #DAE9ED;
}

.community-card table.table-bordered tr td{
  text-align: left !important;
  font-weight: 700;
}

.community-card table.table-bordered tr:nth-child(even) td {
  background-color: inherit;
}

.box-shadow {
  box-shadow: 0px 4px 8px #bdf5ff85;
 
}



#my_file {
  display: none;
}

#get_file , #get_file:hover , #get_file:focus {
  background: #5383A2;
  border: 1px solid #88c;
  padding: 10px;
  border-radius: 5px;
 cursor: pointer;
  color: #fff;
    min-height: 118px !important;
  height: 100%;
  width: 34px !important;
}
#get_file.btn.btn-primary:focus , #delete_file.btn.btn-primary:focus {
  min-width: 34px !important;
  max-width: 34px !important;
  width: 34px !important;
  margin: 0;
  padding: 0;
}
#delete_file {
  background-color: #d23c3f;
  border-color: #d23c3f;
  border-radius: 5px;
 cursor: pointer;
  color: #fff;
    min-height: 118px;
  height: 100%;
  width: 34px !important;

}

#customfileupload
{
  display: inline;
  background-color: #fff;
  font-size: 14px;
  padding: 10px 30px 10px 10px;
  width: 250px;
  border: 1px solid #999;
  box-shadow: inset 1px 1px 5px #ccc;
  -webkit-box-shadow: inset 1px 1px 5px #ccc;
  -moz-box-shadow: inset 0px 0px 4px #ccc;
  -ms-box-shadow: inset 0px 0px 4px #ccc;
  -o-box-shadow: inset 0px 0px 4px #ccc;
  z-index: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.admin-brand .card.brand-card.new-product {
  /* min-height: 367px; */
}
.admin-stage .card-img-wrapper .card img {
  min-height: 400px;
  max-height: 400px;
}

/* analytics css start */

.analytics-card {
  background: #F5FEFF !important;
  max-height: 243px;

}
.analytics-card .icon {
  margin: 0 auto;
  border-radius: 100%;
  width: 64px;
  height: 65px
}
.analytics-card .icon svg {
  height: 100%;
  width: 100%;
  color: var(--white);
}
.analytics-card .icon svg path{
  fill: #fff;
}
.boucher , .enquire-analytics, .user-connection{
  fill: #96D3DD !important;
}
.analytics-card button {
  min-height: 40px !important;
  line-height: unset  !important;
  font-size: 16px;
}
/* .analytics-card .icon svg g{
  fill: #fff;
} */
.btn-down {
  margin: 0 auto;
  position: absolute;
  bottom: 53px;
  left: 40%;
}

.analytics-card .fa {
  width: 40px;
  height: 40px;
  font-size: 40px;
}
@media (max-width:1472px) and (min-width: 1301px){
  .analytics-card .btn {
    font-size: 12px;
    min-height: 37px !important;
  }
}
@media (max-width:1440px) {
  .res-h1 {
    font-size: 30px !important;
  }
}

@media (max-width:767px){
  .res-h1 {
    font-size: 5vw !important;
  }
}
@media (max-width:800px){
  .popup_inner.form {
    min-width: 100%;
      max-width: 100%;
      padding-bottom: 20px;
  }

  
}

@media (min-width:1301px) and (max-width:1440px){
  .nav-tabs  {
    top: -23px;
    left: 50%;
    transform: translate(-44%, -1px);
    color: #fff !important;
    width: 59%;
  }
  .admin-brand .card.brand-card.new-product {
    min-height: 360px;
}
.admin-stage .card-img-wrapper .card img {
  min-height: auto;
  max-height: auto;
}

}




