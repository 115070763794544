

.header-wrapper .sidebar{
    display: none;
}
.menu_open .sidebar {
    display: block;
}
 .cross ,  .sidebar .cross {
    position: absolute;
    top: 30px;
    right: 2px;
    z-index: 99;
}
/* .btn:hover {
    color: #fff !important;
} */
.or {
    background: rgb(146, 201, 210);
    border-radius: 100%;
    padding: 14px  12px;
    color: #fff;
    font-weight: 700;
}
.h5 , .h5 strong{
    font-weight: 700;
    color: #000;
}
.filterpopup{
    width:100%
}
 .sidebar .cross {
    position: absolute;
    top: 8px;
    right: 2px;
}

 .res-search-wrapper {
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     display: none;

 }
 .mobhide{
     display: block;
 }
.header-wrapper .fa.fa-search {
    color: #2F6E90;
    margin-right: 8px;
    font-size: 23px;
    display: none;
}
.mob-help {
    margin-right: 10px;
    border: none;
}
.mob-help i {
    font-size: 23px;
    color: #2b688e;
}
/* .mobshow{
    display: none !important;
} */
.mob-help{
    display: none;
}
.header-wrapper ~ .search_wrapper  {
    display: none !important;
}
.header-wrapper .mobile_trigger {
    display: none;
}
.btn.btn-red.addbtn .mobbhide {
    font-size: 12px;
    padding: 0px 24px !important;
}
a.help.mobhide:hover , a.help.mobhide:hover {
    color: #2b688e;
}
a.btn.btn-red.addbtn.mobshow {
    line-height: 34px;
    padding: 1px 24px;
}
.mobshow{
    display: none !important;
}
.mobshow .fa.fa-info-circle {
    color: #2b6a8e;
    font-size: 22px;
}
.brandcategory-list td {
    width: 100%;
   
    display: flex;
    align-items: baseline;
    padding-left: 12px !important;
    border-bottom: none;
    margin-right: 0;
    cursor: pointer;
}
.brandcategory-list {
    display: flex !important;
    margin-right: 0rem;
}
.cm-section .table-responsive.explore-by-ndustry{
    border-radius: 15px;
    height: 421px;
    overflow-x: hidden;
}
@media (max-width: 991px) {
    .cm-section .table-responsive.explore-by-ndustry{
        height: auto;
    }
    .btn.btn-primary, .btn.btn-primary:focus, .btn.btn-primary:active {
        background:var(--btncolor);
        color: #fff;
        border-radius: 100px !important;
        min-height: 35px !important;
        max-height: 35px !important;
        line-height: 35px !important;
        padding: 0px 8px !important;
        font-size: 12px !important;
    }
    .btn.btn-red, .btn.btn-red:hover {
        font-size: 13px;
        /* min-height: 35px !important;
        line-height: 35px !important;
        padding: 0px 24px !important; */
    }
    .header-wrapper  ~ .nav-wrapper {
        display: none;
    }
    .mob-help , .mobshow{
        display: block;
    }
    .mobshow{
        display: block !important;
    }
    .mobhide {
        display: none !important;
    }
    /* .help {
        display: none !important;
    } */
    .header-wrapper .logo, .header-wrapper .logo img{
        display: none;
    }

    .header-wrapper .mobile_trigger {
        align-items: center;
        display: flex;
        position: unset;
    }

    .pl_sm_0{
        padding-left: 0px !important;
    }

    .header-wrapper .text {
        display: none;
    }

    .header-wrapper .fa.fa-search {
        display: block;
    }

    .header-wrapper ~ .search_wrapper {
        margin:auto auto 10px  auto !important;
    }
    .location_wrapper {
        min-width: 300px;
    }
   
.search_open  .res-search-wrapper {
    display: flex !important;
    background: #f5fdff;
    padding-bottom: 12px;
    padding-top: 12px;
}
.res-search-wrapper .search_wrapper {
    margin-bottom: 10px;
}

.mobbhide{
    display: none;
}
}























/* community end */

@media (max-width:992px){
    /*.header-wrapper .logo {*/
    /*    display: none;*/
    /*}*/
    
.Profile-dropdown-popup .ant-modal-body p:first-letter {
    text-transform: uppercase;
}
.Profile-dropdown-popup .ant-modal-body p {
    /* text-align: center; */
    font-size: 20px;
    font-weight: 700;
}
.Profile-dropdown-popup .ant-modal-body a {
    display: block;
    color: #000;
    font-size: 15px;
    padding: 10px;
    border-top: 1px solid #88888808;
    /* text-align: center; */
}
.Profile-dropdown-popup .icon {
    margin-right: 14px;
}
.profileicon-parent {
    padding-bottom: 15px;
    border-bottom: 1px solid #C3D9E6;
}
.Profile-dropdown-popup .icon svg {
    width: 20px;
    height: 20px;
}
.Profile-dropdown-popup .profileicon-parent img {
    width: 60px;
    height: 60px;
    border-radius: 100px;
    margin-right: 15px;
}
p.mb-0.mailid {
    font-size: 15px;
    color: #5283a9;
    font-weight: 100;
}
.Profile-dropdown-popup .profileicon-parent {
    display: flex;
    align-items: center;
}
.Profile-dropdown-popup .ant-modal-body {
    padding: 20px 14px !important;
}
}


@media (max-width:400px){
    .cm-section .wrapper h3 {
        font-size: 17px;
    }
}
