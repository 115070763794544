.product-grid {
    /* background: #fff; */
    border-radius: 12px;
    margin-bottom: 30px;
    padding: 20px;
    /* min-height: 240px;
    max-height: 240px; */
}
.product-grid .img img {
    border-radius: 12px;
    height: 200px;
    width: 200px;
    object-fit: cover;
}
.product-grid .text h3 {
    color: #2c688d;
}
.wrap-2line{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.product-grid .text p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.counter {
    position: absolute;
    background: #FF0000;
    width: 20px;
    height: 20px;
    text-align: center;
    color: #fff;
    border-radius: 100px;
    top: -7px;
    left: 17px;
    font-size: 11px;
    padding-top: 2px;
}
.tab-product .community-card img {
    border-radius: 10px;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}