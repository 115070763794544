.form-group.cm_text-area textarea.form-control , textarea.form-control.hi{
    height: auto !important;
    border-radius: 20px !important;
}
.category-details .profile-section-details .table-responsive td {
    min-width: 60%;
    width: 71%;
}
.category-details table.table-bordered tr:nth-child(even) td {
    background: #ecfefd !important;
}
.category-details table.table-bordered tr td, .category-details table.table-bordered tr th {
    text-align: left !important;
    padding-left: 24px;
}
.categorymanagement .toggle-parent , .sub-categories .toggle-parent{
    justify-content: center;
}
.sub-categories table.table-bordered tr th , .sub-categories table.table-bordered tr td{
    /* text-align:center !important; */
text-align:left !important;
}
.profile-section-details .profile-dp {
    margin-right: 25px !important;
}
.category-details .profile-dp, .category-details .profile-dp img {
    width: 200px;
    height: 165px;
    object-fit: cover;
    border-radius: 20px;
}
.category-details .transaction-details-table table tr td:nth-child(even) {
    font-weight: 700;
    color: #768291;
}
.category-details .profile-section-details {
    display: flex;
    padding: 30px 0;
}
.category-details .profile-section-details .table-wrapper{
    width: 60%;

}
.category-details .profile-section-details .table-wrapper {
    width: calc(100% - 210px);
    padding: 0 !important;
    /* margin-left: 25px; */
}
/* .rounded-textbox-form.filter-select .ant-select-single:not(.ant-select-customize-input) .ant-select-selector, .rounded-textbox-form .ant-select-single:not(.ant-select-customize-input) .ant-select-selector */
.rounded-textbox-form.filter-select .ant-select-single:not(.ant-select-customize-input) .ant-select-selector
{
    border-color: #9aabad42;
    height: calc(1.9rem + .75rem + 2px);
    border-radius: 100px;
    padding: 0.1rem 1.5rem;
    min-width: 300px;
    font-size: 16px;
}

.rounded-textbox-form.filter-select .ant-select-single:not(.ant-select-customize-input) .ant-select-selector
 {
    border-color: #9aabad42;
    height: calc(1.9rem + .75rem + 2px);
    border-radius: 100px;
    padding: 0.1rem 1.5rem;
    min-width: 300px;
    font-size: 16px;
    background: #fff;
}
   
.upload-inner img , .upload-inner .video-react-controls-enabled{
    min-height: 120px;
    max-height: 120px;
    width: 190px;
    object-fit: fill;
    border-radius: 10px;
}
.rounded-textbox-form.filter-select .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    border-color: #9aabad42 !important;
}
@media(max-width:767px){
    .category-details .profile-section-details {
        flex-wrap: wrap;
    }
    .category-details .profile-dp, .profile-dp img {
        margin: 0 auto;
    }
    .category-details .profile-section-details .table-wrapper {
        width: calc(100%);
        margin-top: 30px;
        margin-left: 0 !important;
    }
}