
.filter-icon {
    height: 40px;
    width: 40px;
    background: #2C688C;
    border-radius: 50%;
    padding: 10px;
    fill: var(--white);
  }
  .filter-icon svg {
    width: 100%;
  }
  .upload-inner, .upload-inner img{
    height: 120px;
    max-width: 190px;
    border: 1px solid #9aabad;
    border-radius: 10px;
    position: relative;
    min-width: 190px;
    object-fit: cover;
    z-index: 1;
  }
  .upload-inner img{
    height: auto;
    max-width: 100%;
    max-width: 100%;
    width: auto;
  }
  .upload-inner.addMedia img {
    height: auto;
    max-width: 100%;
    min-height: inherit;
    width: auto;
}
.upload-inner.addMedia{
  min-height: 75px;
  height: auto;
}
  .upload-inner > input {
    opacity: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
  .custom-upload-file li{vertical-align: top;}
  .custom-upload-file .btn, .btn.add-btn {
    min-width: auto;
    margin: 0;
    min-height: 120px;
    border-radius: 10px;
    padding: 11px 12px;
    font-size: 18px;
    font-weight: 100;
    background: #5383A2;
    color:#fff;
  }
  .custom-upload-file .btn:hover, .btn.add-btn:hover {
    background: #729ebb;
    color: var(--white);
  }
  .upload-inner .upload-icon > svg {
    position: absolute;
    width: 25px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    fill: #5383A2;
  }
  .inverse-color tr td:first-child {background: #92c9d2 !important;color: #fff;}
  .inverse-color tr td {
    padding: 16px 30px !important;
    color: #768291;
    font-size: 15px !important;
    }
    .media-list li {
        width: 30%;
        display: inline-block;
    }
    .media-list li > div {
        width: 100% !important;
        height: 250px !important;
    }
    .media-list li:not(:last-child) {
        margin-right: 15px;
    }
    .media-list li:last-child {
        width: 4% !important;
        vertical-align: top;
        height: 250px;
    }
    .or-label {
        position: absolute;
        left: -12px;
        top: 17%;
        background: #5383A2;
        height: 25px;
        width: 25px;
        color: var(--white);
        text-align: center;
        border-radius: 50%;
        line-height: 26px;
        font-size: 11px;
        letter-spacing: 1px
    }
    .add-manag > .row {
        margin-right: -25px;
        margin-left: -25px;
    }
    .add-manag > .row > [class*=col-] {
        padding-left: 25px;
        padding-right: 25px;
    }
    .filter-dropdown .anticon-down {
        display: none !important;
    }
    .filter-dropdown-list {
        max-width: 250px;
        border-radius: 25px !important;
        margin-top: 10px !important;
    }
    .main-category .ant-picker {
        height: calc(2em + .4rem + 2px) !important;
        padding: 0px 0px 0px 27px !important;
    }
    .main-category .ant-picker input {
        font-size: 12px;
    }
    .main-category .ant-picker .ant-picker-input {
        border-color: #5383A2;
    }
    .filter-dropdown-list .row {
        margin-left: -5px;
        margin-right: -5px;
    }
    .filter-dropdown-list .row > [class*=col-] {
        padding-left: 5px;
        padding-right: 5px;
    }
    .main-category > label span {
        font-weight: 600;
        font-size: 18px;
        color: #484A54;
    }
    .main-category li {
        font-weight: 200;
        color: #484A54;
    }
    .main-category > label {
        display: block;
        margin-bottom: 5px;
    }
    .main-category ul > li span {
        font-size: 12px;
    }
    .main-category .ant-picker .ant-picker-input .ant-picker-suffix {
        position: absolute;
        left: -19px;
        margin: 0px;
        top: -2px;
    }
    .main-category .ant-picker .ant-picker-input .ant-picker-suffix svg {
        font-size: 14px;
        fill: #5383A2 !important;
    }
    .main-category ul > li .ant-checkbox .ant-checkbox-inner {
        height: 12px !important;
        width: 12px !important;
    }
    .main-category ul > li .ant-checkbox .ant-checkbox-inner:after {
        width: 4.71428571px;
        height: 7.14285714px;
    }
    .main-category .ant-checkbox-checked .ant-checkbox-inner{
        background: #3975BB;
        border-color: #3975BB;
    }
  /* kiran end */


  /*pranaw start */

  .addsm-btn , .addsm-btn:hover{
    background: #2c688d;
    color: #fff;
    min-width: 12px;
    height: 42px;
}
  @media (max-width:400px){
    .header-wrapper .cm-section.container-fluid {
      padding: 0 0px 0 0px !important;
  }
    /* .upload-inner {
      max-width: 160px ;
      height: 82px ;
  
    } */
    .custom-upload-file .btn {
      min-height: 82px;
    }
  
  }
  
  
  @media (max-width:340px){
  
    /* .upload-inner {
      max-width: 127px !important;
      padding: 11px 8px;
    } */
  
  }

  /*pranaw end */
  
