:root {
  --btncolor: #2783CA;
  --connectBtn: #afafaf;
  --mainColor:#C2A975;
  --white: #ffffff; 
  --btnshadow: 0px 0px 4px #8cd0d8;
  --headercolor: #2783CA;
  --lightcyan: #e7f8fb;
  --lightpurpl: #abcade;
  --red:#FF0000;
  --blue_bg:#2783CA;
  --home-bg:#1c2a3b;
  --homeColor:#025373;
  /* --mainColor: #F2FDFF; */

}
.bg-purpple{
  background:#938AFE;
}

.ant-tooltip-inner{
	min-width: 50px !important
}

.imgStyle{
  min-width: 40px;
    max-width: 40px;
    height: 40px;
    border-radius: 100px;
    margin: 5px;
}

.cap:first-letter {
  text-transform: uppercase;
}


.editable {
  font-family: sans-serif; 
  border: 1px dashed #aaa;
  padding: 5px;
}

.cap:first-letter {
  text-transform: uppercase;
}


.header.active {
  background-color: #d8f3f7;
}

/* html{
  scroll-behavior: smooth;
} */
.mT-3{
  margin-top: -3px;
    
}
.btn.btn-blue.blue {
  background: var(--blue_bg) !important;
}
.btn.btn-gray.blue {
  background: gray !important;
}
.event-tabbody .time-details a.btn-blue.blue svg path:nth-child(2) {
  fill: #fff;
}

.event-tabbody .time-details a.btn-blue.blue svg path {
  fill: #2c688d;
}
.custom-icons i {
  font-size: 35px;
  margin: 5px;
  color: var(--blue_bg);
}
.custom-icons ul.list-unstyled li.list-inline-item {
  margin-left: 12px;
  margin-right: 12px;
}
.list-unstyled {
  padding-bottom: 9px !important;
}
.blurry-text {
  color: transparent !important;
  text-shadow: 0 0 5px rgba(0,0,0,0.5);
}

/* .logt{
  position: absolute;
  right: 20px;
  z-index: 9;
} */
.cyan_bg{
  background-image: #fff !important;
    background: #fff !important;
}
.purpalbtn{
  background: var(--lightpurpl) !important;
}
.fontw-300{
font-weight: 300 !important;
}
@font-face{
  font-family: 'Henriette Italic';
  src: url('../src/assets/fonts/Henriette\ Italic.otf') format('truetype');
  
  font-style: italic;
}

/* @font-face {
  font-family: 'avenir';
  src: url('../src/assets/fonts/AvenirLTStd-Light.otf') format('truetype'),
  url('../src/assets/fonts/AvenirLTStd-Light.ttf') format('truetype'),
  url('../src/assets/fonts/AvenirLTStd-Light.eot?#iefix') format('embedded-opentype');
  font-weight: 400;
  font-style: normal;
}
 @font-face {
  font-family: 'avenir';
  src: url('../src/assets/fonts/AvenirLTStd-Book.otf') format('truetype'),
  url('../src/assets/fonts/AvenirLTStd-Book.ttf') format('truetype'),
  url('../src/assets/fonts/AvenirLTStd-Book.eot') format('embedded-opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'avenir';
  src: url('../src/assets/fonts/AvenirLTStd-Heavy.otf') format('truetype'),
  url('../src/assets/fonts/AvenirLTStd-Heavy.ttf') format('truetype'),
  url('../src/assets/fonts/AvenirLTStd-Light.eot?#iefix') format('embedded-opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'avenir';
  src: url('../src/assets/fonts/AvenirLTStd-Black.otf') format('truetype'),
  url('../src/assets/fonts/AvenirLTStd-Black.ttf') format('truetype'),
  url('../src/assets/fonts/AvenirLTStd-Black.eot') format('embedded-opentype');
  font-weight: 600;
  font-style: normal;
}  */

@font-face {
  font-family: 'calibri';
  src: url('../src/assets/fonts/CALIBRI.ttf') format('truetype'),
  url('../src/assets/fonts/CALIBRI.eot') format('embedded-opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'avgardd';
  src: url('../src/assets/fonts/AVGARDD_2.TTF') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'avgardd';
  src: url('../src/assets/fonts/AvantGardeBookBT.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'avgardd';
  src: url('../src/assets/fonts/AvantGardeDemiBT.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'avgardd';
  src: url('../src/assets/fonts/AVGARDD_2.TTF') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'avgardd-light';
  src: url('../src/assets/fonts/AVGARDN_2light.TTF') format('truetype');
   font-weight: 300; 
  font-style: normal;
}
@font-face {
  font-family: 'Sacramento';
  src: url('../src/assets/fonts/Sacramento-Regular.ttf') format('truetype'),
  url('../src/assets/fonts/Sacramento-Regular.eot') format('embedded-opentype');
  /* font-weight: 300; */
  font-style: normal;
}

 @import url("../src/assets/css/exhibitionManagement.css");
 @import url("../src/assets/css/brandManagement.css");
 @import url("../src/assets/css/base.css");
 @import url("../src/assets/css/web/base.css");
 @import url("../src/assets/css/calling.css");
 @import url("../src/assets/css/categoryManagement.css");
 @import url("../src/assets/css/webBrand.css");
@import url("../src/assets/css/web/conference.css");
@import url("../src/assets/css/cart.css");
@import url("../src/assets/css/supportchat.css");







.centerLoader{
  position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);

}
.upload_croperimg {
  position: absolute;
  top: -1px;
  left: 0px;
}
.upload_croperimg svg{
  width: 20px;
  height: 20px;
  fill: #fff;
}


/* name css*/

[data-letters]:before {
  content:attr(data-letters);
  display:inline-block;
  font-size:1em;
  font-weight: bold;
  width:5.0em;
  height:5.0em;
  line-height:5.0em;
  text-align:center;
  border-radius:50%;
  background:#8bc3cb;
  vertical-align:middle;
  margin-right:1em;
  color:white;
  }


  .speaker_img {
    display: flex;
}




.chatbody::-webkit-scrollbar , .chat-scroll::-webkit-scrollbar, .support-tabbar-hader-parent::-webkit-scrollbar , .support-tabbar-hader-parent::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.cursor-auto{
  cursor: auto !important;
}
/* ::-webkit-scrollbar {
  width: 9px;
  height: 9px;

} */

/* Track */
/* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 5px;
} */
 
/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #2b688e; 
  border-radius: 5px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #1d4d6b; 
} */
.chatbody:-webkit-scrollbar-track , .chat-scroll:-webkit-scrollbar-track{
  background: #D33B27; 
}

.chatbody::-webkit-scrollbar-thumb , .chat-scroll::-webkit-scrollbar-thumb {
  background: #D33B27;
}
.progress.search{
  height: 0.7rem;
}

.progress-bar.active, .progress.active .progress-bar {
  -webkit-animation: progress-bar-stripes .5s linear infinite;
  -o-animation: progress-bar-stripes  .5s linear infinite;
  animation: progress-bar-stripes  .5s linear infinite;
}
.circle-image{
  border-radius: 100px !important;
  object-fit: cover;
}
 .notification svg path {
  /* fill: #2b688e; */
}
.notification svg {
  width: 22px;
    height: 22px;
    /* margin-right: 3px; */
    fill: #2f6e90;
    cursor: pointer;
}
.notification-nodata svg {
  width: 45px;
  height: 45px;
  fill: #d2d2d299;
}
.notification-nodata h2 {
  color: #d2d2d299;
}
.pos-relative.cursor-point.notification .counter {
  top: -13px;
  left: 21px;
}
.password-show {
  position: absolute;
  top: 17px;
  right: 20px;
}
.signup .password-show {
  position: absolute;
  top: 53%;
}
.notification .counter {
  top: -10px;
  left: 15px;
}
.chatbody::-webkit-scrollbar-thumb:hover {
  background:#ba2814; 
}
body{
  /* font-family: 'avenir' !important; */
  font-family: 'avgardd' !important;;
 /* background: #edf9fb !important; */
 background: #fff !important;
}
a , a:hover{
  text-decoration: none;
}
ul{
  list-style-type: none;
}
.f-400{
  font-weight: 400 !important;
}
.f-600{
  font-weight: 600 !important;
}
.colorB{
  color: #000 !important;
}
.colorW{
  color: #fff !important;
}
.fs-16{
  font-size: 16px !important;
}
.fs-12{
  font-size: 12px !important;
}
.no-shadow{
  box-shadow: 0 0 0 #bdf5ff00 !important;
}
a.btn.btn-red.addbtn.online-exhibitionbtn .fa {
  color: #8cd0d8;
}
a.btn.btn-red.addbtn.online-exhibitionbtn {
  background: transparent;
  border: 1px solid #81cbd4;
}
.f-700{
  font-weight: 700 !important;
}
.form-control:focus ,.btn-primary.focus, .btn-primary:focus , .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0) !important;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0) !important;
  border-color: transparent !important;
  border:1px solid  #9aabad !important;
}
.btn.focus, .btn:focus{
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0) !important;
}
.pos-relative{
  position: relative !important;
}
/* AvantGarde Bk BT */
/* Avenir LT Std */
.container-fluid {
  padding: 0 100px !important;
}

.btn.btn-red, .btn.btn-red:hover {
  background: var(--btncolor);
  color: #fff;
  border-radius: 100px;
  min-height: 42px;
  padding: 9px 24px;
  min-width: 142px;
  font-weight: bold;
  margin: 12px 0px;
  font-size: 16px;
  box-shadow: var(--btnshadow);
 
} 
.hj .btn.btn-red, .btn.btn-red:hover {
  background: var(--btncolor);
  box-shadow: 0px 2px 9px #00000075;
}
span.menuicon svg path {
  fill: #000;
}
.form-group label span.red-star{
  color: red;
  font-size: 18px;
}
.red-heading{
   /* text-transform: capitalize; */
    color: red;
    font-size: 15px;
}
.btn-navyblue{
  background: var(--btncolor) !important;
  color: #fff;
  border-radius: 100px;
  min-height: 48px;
  padding: 10px 24px 9px 24px;
  min-width: 142px;
  font-weight: 700;
  margin: 12px 0px;
  font-size: 19px;
}
.mac .btn.btn-red  , .mac .btn.btn-red:hover{
  /* padding: 14px  24px 11px !important;
  font-size: 14px !important; */
}
.search-container .form-control:focus {
  border: none !important;
}
.react-tel-input .form-control {
    width: 100% !important;
    outline: none;
    border-color: #9aabad !important;;
    height: calc(2.4em + .75rem + 2px) !important;
    border-radius: 100px !important;
    padding: .7rem 1.5rem !important;
    font-size: 16px !important;
    padding-left: 66px !important;
}
.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0px;
  bottom: 0;
  padding: 0;
  background-color: #f2f1f100;
  border: none;
  border-radius: 3px 0 0 3px;
  margin-left: 20px !important;;
}
.react-tel-input .flag-dropdown {
  background-color: #f5f5f500 !important;
  border: 1px solid #cacaca00 !important;
  border-radius: 3px 0 0 3px;
}
.btn.btn-red-connect, .btn.btn-red-connect:hover {
    
    padding:11px 24px ;
    font-size: 15px;
}
.logoutbtn svg {
  width: 34px;
  fill: #fff;
  position: absolute;
  right: 20px;
  top: 20px;
  height: 53px;
    cursor: pointer;
    z-index: 9;
}

.rounded-body {
  background: #fff;
  border-radius: 20px;
}
.rounded-body-form {
    background: #fff;
    border-radius: 20px;
   padding: 50px 50px;
   
}
/* --------------------footer-------------------- */

.footer--light {
 background: #1e1e1e00;
 
}

.footer-big .footer-menu ul li a,.footer-big p,.footer-big ul li {
 color:#404549;
}
.footer_signup .form-group .form-control {
 background: transparent;
 border-color: #e1e1e1ad;
 height: calc(2em + .75rem + 4px) !important;
 margin-right: 16px;
 border-radius: 0;
}
.lets-talk {
 color: #404549 !important;
 width: 75%;
 position: relative;
 text-align: center;
}
.lets-talk  svg{
 width: 181px;
}
.lets-talk  span{
 position: absolute;
   width: 100%;
   top: 51%;
   left: 50%;
   transform: translate(-50% ,-50%);
   
}
.footer-big ul a span.svgicon svg, .footer-big ul li p span.svgicon svg {
 width: 20px;
 height: 20px;
 margin-right: 20px;
}
/* .lets-talk:after {
 border-bottom: 46px solid #fdca26;
 border-right: 33px solid transparent;
 content: '';
 position: absolute;
 top: -1px;
 right: -20%;
} */
.footer_signup .form-group .gray_btn.yellow {
 margin-top: 20px;
 width: 100%;
}
.footer-menu ul li a {
 font-size: 12px;
 line-height:32px;
 -webkit-transition:.3s;
 -o-transition:.3s;
 transition:.3s;
 color: #404549 !important;
 font-weight: 600;
}
.footer-menu.link li {
  margin-bottom: 12px;
}
.footer-menu.link li a{
  line-height: normal;
}

.footer-menu ul li a:hover {
 color:#5867dd
}
.footer-bottom{
 margin: 35px 0 ;
 font-size: 13px;
 color: #272c2f !important;

}
.footer-big ul a span.svgicon svg, .footer-big ul li p span.svgicon svg {
  fill: #000;
}
.footer-big ul li.footAdr p span.svgicon svg{
  fill: transparent  !important;
}
.footer-menu--1 {
 width:100%
}
.footer-widget-title {
 margin-bottom: 24px;
 font-size: 14px;
 color: #000000;
 text-transform: uppercase;
 font-weight: bold;
}
.mini-footer {
 background:#192027;
 text-align:center;
 padding:32px 0
}
.footer-widget p {
  font-size: 13px;
  line-height: 20px;
}
.mini-footer p {
 margin:0;
 line-height:26px;
 font-size:15px;
 color:#999
}
.mini-footer p a {
 color:#5867dd
}
.mini-footer p a:hover {
 color:#34bfa3
}
.widget-about img {
 display:block;
 margin-bottom:30px
}
.widget-about p {
 font-weight:400
}
.widget-about .contact-details {
 margin:30px 0 0 0
}
.widget-about .contact-details li {
 margin-bottom:10px
}
.widget-about .contact-details li:last-child {
 margin-bottom:0
}
.widget-about .contact-details li span {
 padding-right:12px
}
.widget-about .contact-details li a {
 color:#5867dd
}
 .social-networks {
  padding-top: 10px;
}

.social-networks .fa {
  font-size: 22px;
  margin-bottom: 5px;
  background-color: transparent;
  color: #5889a6;
  border-radius: 50%;
  padding-bottom: 25px;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 31px;
  text-decoration: none;
  transition: color 1s;
}
.text-decoration-underline{
  text-decoration: underline !important;
}
.footer-big ul {
 list-style-type: none;
 padding-inline-start: 0px;
}

.footer-big ul a {
 display: inline-block;
 margin-right: 15px
}
.footer-big ul.social-networks a svg {
 width: 26px;
 height: 26px;
}
.footer-menu.no-padding ul li i {
 margin-right: 20px;
}
/* --------sign in page---------- */
.signin-bg{
  /* background-image: url(../src/assets/images/Sign-in.jpg); */
  background-position: bottom;
  background-repeat: no-repeat!important; 
  background-size: cover!important;
  min-height: 100vh;
  padding-top: 100px !important;
  background: #fff;
}
.signin-bg .payment-sucess-screen{
  max-height: 100vh;
}
.payment-sucess-screen {
  background: #fff;
  min-height: 100vh;
  padding: 100px 0 !important;
}
.payment-sucess-screen .update-password {
  width: 700px;
    margin: 0 auto;
    position: inherit;
    top: 0;
    left: 0;
    transform: inherit;
}
.rounded-textbox-form .form-control , .cm-form-wrapper .form-control{
  border-color: #9aabad;
    height: calc(2.4em + .75rem + 2px);
    border-radius: 100px;
    padding:.7rem  1.5rem;
    font-size: 16px;
}
.rounded-textbox-form textarea.form-control{
  height: auto;
  border-radius: 10px;
}
.public-DraftEditor-content{
  min-height: 135px;
}
.cm_alert_danger{
  color: red;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/* .sign-up p {
  font-size: 18px;
} */
/* .footer-widget img {
  width: 65%;
} */
.sign-up p a{
  color: var(--btncolor);
  font-weight: 600;
  padding: 0 7px;
}
.nav-wrapper li.nav-item {
  padding: 7px 18px;
}
.nav-wrapper li.nav-item:first-child {
  padding-left: 0;
}

.nav-wrapper li.nav-item a:hover,.nav-item.active a {
  color: #000;
}
.rounded-textbox-form .form-control::placeholder , .cm-form-wrapper .form-control::placeholder, .ant-select-selection-placeholder, .cm_multiselect span.ant-select-selection-placeholder , span.ant-select-selection-item
{
 /* color: #a8b3b5 !important;   */
 color: #a8b3b5 !important;  
  opacity: 1 !important;
}
.ant-select-selection-search-input {
  line-height: 2.8!important;
  height: calc(2.4em + .75rem + 2px) !important;
  padding: .3rem .8rem !important;
}
.signup-process .rounded-textbox-form .btn {
  margin-top: 38px !important;
}
/* ----------------------------------for multi seclect---------------- */
.new_multi_check .ant-select-tree .ant-select-tree-switcher .ant-select-tree-switcher-icon {
  font-size: 10px;
    display: block !important;
    vertical-align: baseline;
    width: 100% !important;
    background:#faecec00 !important;
    z-index: 9999 !important;
    padding: 10px 3px;
    position: absolute;
    margin-top: 11px;
    /* height: 100%; */
}
span.ant-select-tree-node-content-wrapper.ant-select-tree-node-content-wrapper-close:before {
  content: '\f0da';
  position: relative;
  left: -18px;
  font-family: 'FontAwesome';
  transform: translate(10px, 10px);
}
/* .new_multi_check .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner:after{
  display: none !important;
} */
.new_multi_check span.ant-select-tree-switcher.ant-select-tree-switcher_close svg {
  display: none !important;
}
.new_multi_check .ant-select-tree .ant-select-tree-switcher {
  margin: 6px 0 !important;
}
.new_multi_check .ant-select-tree-checkbox-inner {
  top: 12px !Important;
  left: 12px !Important;
  margin-right: 8px !Important;
}
.new_multi_check .ant-select-tree-checkbox-checked::after {
  top: 12px !important;
  left: 12px !important;
  width: 16px;
  height: 16px;
}
.new_single_check .ant-select-tree .ant-select-tree-switcher , .new_multi_check .ant-select-tree .ant-select-tree-switcher{
  width: 24px;
  height: 36px !important;
  line-height: 36px !important;
}
.cmslect_multicheck .ant-select-multiple .ant-select-selection-item-content{
  color: #000 !important;
}
.cursor-point{
  cursor: pointer !important;;
}
/* [contenteditable=true]:empty:before {
  content: attr(placeholder);
  display: block;
  color: #aaa;
} */
/* .new_multi_check .ant-select-tree .ant-select-tree-switcher {
  -ms-flex: none;
  flex: none;
  width: 100% !important;
  height: 47px !important;
  margin: 0;
  line-height: 46px !important;
  text-align: center;
  cursor: pointer;
  position: absolute;
  padding: 9px;
} */
/* span.ant-select-tree-checkbox {
  width: 100% !important;
  display: block !important;
  background: #ffff002b;
  z-index: 99 !important;
  position: absolute !important;
  margin-top: 14px !important;
} */
.ant-select-tree-checkbox-inner {
  top: 0px !Important;
}
.ant-select-tree-checkbox-inner {
  left: 40px;
}
span.ant-select-selection-item {
  color: #000 !important;

}
.cmslect_multicheck span.ant-select-selection-item {
  line-height: 2 !important;
  font-size: 11px;
  color: #000 !important;
}
.new_multi_check .ant-select-tree .ant-select-tree-switcher .ant-tree-switcher-icon svg, .ant-select-tree .ant-select-tree-switcher .ant-select-tree-switcher-icon svg {
  display: block !important;
}
/* .new_multi_check span.ant-select-tree-title {
  padding-left: 23px;
} */



.search_wrapper input[type="search"]::placeholder{
  color: #a6b3bd91;
}
.rounded-textbox-form .ant-select, .cm-form-wrapper .ant-select{
  width: 100% !important;
}
 .ant-select-single:not(.ant-select-customize-input) .ant-select-selector , .rounded-textbox-form .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #9aabad;
  height: calc(2.4em + .75rem + 2px);
  border-radius: 100px;
  padding: .3rem 1.5rem;
  width: 100%;
  font-size: 16px;
}
.rounded-textbox-form .ant-select.ant-tree-select.cm_multiselect.ant-select-single.ant-select-show-arrow .ant-select-selector{
  font-size: 16px !important;
}
span.ant-select-selection-placeholder, span.ant-select-selection-item{
  line-height: 2.6 !important;
}
.mac span.ant-select-selection-placeholder, span.ant-select-selection-item{
  line-height: 2.8!important;
}
.mac .placework span.ant-select-selection-placeholder, .placework span.ant-select-selection-item {
  line-height: 1.9 !important;
    font-size: 12px;
}
.ant-select-arrow {
  background: #fff;
}
.cmslect_multicheck span.ant-select-selection-placeholder {
  line-height: 2.4 !important;
  padding: 6px 12px !important;
}
.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background-color: #f5f5f500 !important;
}
.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected{
  background-color: #bae7ff0d !Important;
}
.ant-select-tree-treenode.ant-select-tree-treenode-switcher-close{
 
  padding: 8px 0;
}
.ant-select-tree .ant-select-tree-node-content-wrapper {
  padding: 12px 4px !important;
}
.ant-select-tree .ant-select-tree-switcher {
  margin: 6px 0 !important;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode{
  padding-bottom: 0 !important;
}
.ant-select-tree-treenode.ant-select-tree-treenode-switcher-close:hover , .ant-select-tree-treenode.ant-select-tree-treenode-switcher-close.ant-select-tree-treenode-selected{
  background: #f5f5f5;
  
}
.auth-heading{
  font-family: 'avgardd';
    font-weight: 700;
    font-size: 46px;
}
.heading-after-border {
    position: absolute;
    width: 142px;
    height: 2px;
    background: var(--btncolor);
    bottom: 0;
    left: 1%;
    transform: translate(0%,15px);

}
.heading-after-border-center {
  width: 142px;
  height: 2px;
  background: var(--btncolor);
  bottom: 0;
  left: 50%;
  transform: translate(-50%,15px);
}
.heading-after-border .heart {
  width: 26px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% ,-50%);
  background: #f5fdff;
  height: 16px;
}
.heading-after-border .heart svg {
  position: absolute;
    width: 16px;
    left: 50%;
    top: 50%;
    transform: translate(-50% ,-50%);
}
.heading-after-border svg path {
  fill: var(--btncolor);
}
.signin-bg .logo img{
  width: 70%;
    margin-bottom: 35px;
}
.signin-bg .text p {
  color: #2c2e31;
  font-size: 17px;
}
/* ------------------video player---------------------- */
.video-parent .video-player{
  border: 8px solid #2c688d;
    border-radius: 12px;
 

}
.video-player-parent {
  margin: 60px 0 0;
}

video-parent {
  margin: 0 auto;
  width: 100%;
}
/* ---------------update-password screen------------- */
.update-password{
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    width: 700px;
}
.heart {
  position: relative;
  font-size: 140px;
  color: #2c688d;
}
.heart .tick svg {
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50% , -50%);
  color: #fff;
  width: 40px;
  height: 40px;
}
.heart .tick svg path{
  fill:#fff;
}
.update-password p{
  font-size: 21px;
  color: #2c333c;
}
.reset-pass .logo img {
  width: auto;
}
/* ----------------home page--------- */
.Home-banner-bg{
    /* background-image: url(../src/assets/images/homebanner-bg.png); */
    background-position: bottom;
    background-repeat: no-repeat!important; 
    background-size: cover!important;
    /* min-height: 100vh; */
    /* background-image: linear-gradient(#f5fdff , #d6f2f6); 
    background-image: linear-gradient(#fff , #fff);*/
    background:#fff;
}

.banner-bg{
    background-image: url(../src/assets/images/background-3.jpg);
    background-position: bottom center;
    background-repeat: no-repeat!important; 
    min-height: 91vh;
    width: 100%;
    background-size: cover
}
.landing-temp-wrp.Home-banner-bg{
  background:#fff;
}
.second-banner-bg{
  /* background-image: url(../src/assets/images/second-banner-bg.jpg); */
  background-image: url(../src/assets/images/backgroundcopy.jpg);
  background-position: top center;
  background-repeat: no-repeat!important; 
  min-height: 100vh;
  width: 100%;
  background-size: cover
}
.header_wrapper{
display: flex;
justify-content: space-between;
align-items: center;
padding: 24px 0;
}
header .logo img {
  /* width: 246px; */
}
.banner-section .btn , .banner-section .btn:hover {
  min-width: 230px;
}
header .header-btn-group .btn , header .header-btn-group .btn:hover {
    margin: 0px 10px;
    padding: 11px 24px;
    font-size: 15px;
    padding-top: .75rem;
    min-width: 155px;
    line-height: 1.5;
    min-height: 48px
}
header .header-btn-group .btn:first-child {
  background: #2c688d;
}
header .header-btn-group .btn:nth-child(2) {
  background: #92c9d2;
}
.header_wrapper .btn , .banner-section .btn , .banner-section a.btn{
  text-transform: uppercase;
}
.banner-section{
  margin-top: 30px;
}
.banner-section a.btn, .banner-section a.btn:hover {
  min-width: 217px !Important;
  margin-bottom: 26px;
  font-size: 16px;
}
.banner-section p{
  font-family: 'avgardd-light';
  font-weight: 300;
  color: #43423cbd;
  font-size: 57px;
  line-height: 0.9;
    margin-bottom: 0;
  }
.banner-section h1 , .home-heading{
  font-family: 'avgardd';
  font-size: 32px;
  font-weight: 700;
  }
  .home-subheading {
    font-family: 'avgardd';
    font-weight: 700;
    font-size: 17px;
    padding-top: 20px;
}
  .banner-section .event-date {
    font-size: 16px;
    font-family: 'avgardd';
    color: #46443c;
    font-weight: 700;
    line-height: 1.5;
}
  .banner-section .event-date i {
    color: #e52113;
    margin-right: 12px;
}
.glob-connection {
  padding-bottom: 80px;
}
.glob-connection ul li {
  font-size: 21px;
  display: flex;
  margin: 16px 0px;
}
.glob-connection ul {
  list-style-type: none;
  padding-inline-start: 10px;
  margin-top: 40px;
}


.home-red-circle img{
    width: 140px;
    height: 140px;
    margin: 0 auto;
}

.speaker-card {
  background-color: #D4F1F7;
  min-height: 900px;
    max-height: 900px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 20px;
}
.speaker-card img.img-fluid {
  min-width: 100%;
}
.speaker-card .card-body {
    background: transparent;
    padding: 10px 2px;
    text-align: center;
  
}
.speaker-card .card-body h6 {
  font-size: 26px;
  font-weight: 700;
}
.speaker-card .card-body h5 {
  font-size: 22px;
  font-weight: 800;
  padding: 6px 0;
}
.speaker-card .card-body p{
  color: #000;
  font-size: .99em;
  line-height: 18px;
  margin-bottom: 0px;
  font-weight: 800;
}
.speaker-card h6 {
  font-weight: 800;
}
.networking-card {
  min-height: 215px;
  max-height: 215px;
  min-width: 200px;
  width: 100%;
  margin-right: 20px;
}

.five .networking-card {
    position: relative;
    width: 226px !important;
    padding: 0px;
    background: #D4F1F7;
    border: none !important;
    margin-bottom: 20px;
    text-align: center !important;
}

.networking-card .icon img {
  width: 110px;
    height: 110px;
    margin: 0 auto;
    padding-top: 30px;
    object-fit: contain;
    padding-bottom: 30px;
}
.networking-card h6 {
  position: absolute;
    /* bottom: 45px; */
    text-align: center;
    justify-content: center;
    margin: auto;
    left: 50%;
    transform: translate(-50%, 0px);
    width: 95%;
}
article.chattextbox {
  margin: 12px 0px;
}

.cm-home h6 {
    font-size: 19px;
    color: #000;
}

.cm-home h5 {
    font-size: 25px;
    color: #000;
    font-weight: 500;
}


.home-header-text {
    font-size: 50px;
    color: #fff;
    font-weight: 700;
}
.home-header-subtext {
    font-weight: 100;
    font-size: 40px;
    color: #fff;
}

.home-logo {
    margin: 0 auto;
}





.circletick svg {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}
.circletick svg path {
  fill: #e52113;
}
.upload-container{
  padding-left: 15px !important;
    padding-right: 15px !important;
}
.second-banner-bg.global-content , .second-banner-bg.upload-container , .second-banner-bg.Join-us-section{
  background-position: left bottom !important;
  /* min-height: 700px; */
}
.landing.glob-connection , .landing.glob-connection , .landing.global-content-image{
  margin-bottom: 0px;
  padding-bottom: 85px;
}
.global-content-image {
  margin-top: 100px;
  margin-bottom: 200px;
}
.upload-container-image {
  margin-top: 100px;
  margin-bottom: 0px !important;
}
.join-us .fa{
  font-size: 300px;
}
.join-us svg{
  position: relative;
}
.join-us div{
  position: absolute;
  top: 61%;
  left: 50%;
  transform: translate(-50% , -74%);
  width: 65%;
  text-align: center;
  color: #fff;
}
.join-us svg path{
  fill:#2c688d;
}
.join-us h1 {
  font-size: 54px;
  font-family: 'avgardd';
  font-weight: 700;
  color: #fff;
}
.join-us p{
  font-size: 26px;
    font-weight: 500;
}
.join-us .btn , .Join-us-section .btn:hover , .Join-us-section button.btn.btn-red , .Join-us-section button.btn.btn-red:hover{
  min-width: 200px !important;
}
.Join-us-section h3 {
  font-family: 'avgardd';
  font-weight: 700;
  margin-bottom: 10px !important;
  font-size: 38px;
  margin: 20px 0;
}
.Join-us-section h6{
  font-family: 'avgardd';
  font-weight: 700;
}
/* ----------------toggle button start------------ */
.toggle-parent{
    display: flex;
    align-items: center;
    font-weight: 500;
}
.switch {
  position: relative;
    display: inline-block;
    width: 36px;
    height: 16px;
    margin: 0 8px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    transition: .4s;
    border: 1px solid #006a91;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 1px;
  background-color: #e52113;
  transition: .4s;
}

input:checked + .slider {
  background-color: #fff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ccc0;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
  background-color: #27D237;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* ----------------toggle button end------------ */
/* ------------------.signup-process start-------------------- */
.signup-process {
  padding-top: 50px !important;
}
.signup-process .logo img {
  width: 250px;
}
.signup-process .auth-heading {
  font-size: 35px;
}
.breadcrum-tab {
  list-style-type: none;
  font-size: 0;
}
.breadcrum-tab li {
  position: relative;
  display: inline-block;
  margin: 0 20px 0 0;
  padding: 20px 50px;
  font-size: 25px;
  color: #ffffff;
  z-index: 1;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  font-weight: 700;
}
.breadcrum-tab li::before, .breadcrum-tab li::after {
  position: absolute;
  top: 0;
  left: 0;
  background: #91c9d3;
  width: 100%;
  height: 50%;
  content: "";
  transform: skew(30deg);
  z-index: -1;
}
.breadcrum-tab li::after {
  top: auto;
  bottom: 0;
  transform: skew(-30deg);
  background: #91c9d3;
}
.breadcrum-tab li:first-child, .breadcrum-tab li:last-child {
  text-indent: -30px;
}
.breadcrum-tab li:first-child span, .breadcrum-tab li:last-child span {
  position: absolute;
  top: 0;
  left: -49px;
  width: 78px;
  height: 100%;
  background: #91c9d3;
  border-radius: 100%;
  z-index: -1;
}
.breadcrum-tab li:last-child {
  text-indent: 10px;
}
.breadcrum-tab li:last-child span {
  left: auto;
  right: -20px;
  border-radius: 0 10px 10px 0;
}
.breadcrum-tab li.active{
  cursor: pointer;
}
.breadcrum-tab li.active:first-child span, .breadcrum-tab li.active::before, .breadcrum-tab li.active::after {
  background: var(--btncolor);
}
.breadcrum-tab .icon svg {
  width: 20px;
  height: 20px;
  margin-right: 12px;
    margin-top: -6px;
}
.breadcrum-tab .icon svg path {
  fill: #fff;
}

.breadcrum-tab .counter {
  position: absolute;
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50% , -50%);
  color: #000;
  text-align: center;
  font-size: 12px;
  margin-bottom: 0;
  border: 1px solid #91c9d3;
}
.breadcrum-tab li:first-child .counter {
  left: 38%;
  transform: translate(-38% , -50%);
}
.breadcrum-tab li.active .counter{
  background: #d33927;
  border-color: #d33927;
}
.breadcrum-tab li.done .counter{
  background: #58ba5e;
  border-color: #58ba5e;
}
.breadcrum-tab li.active:first-child span, .breadcrum-tab li.active::before, .breadcrum-tab li.active::after , .breadcrum-tab li.done:first-child span, .breadcrum-tab li.done::before, .breadcrum-tab li.done::after {
  background: var(--btncolor);
}
.breadcrum-tab li.active .counter:after, .breadcrum-tab li.done .counter:after {
  color: #fff !important;
}
.breadcrum-tab li:first-child .counter:after {
  content: '1';
  position: relative;
  top: 18%;
    left: 52%;
  color:#91c9d3;
  width: 100%;
  font-weight: 700;
}
/* .breadcrum-tab li:nth-child(2) .counter:after {
  content: '2';
  position: relative;
  top: 18%;
    left: 2%;
  color: #91c9d3;
  width: 100%;
  font-weight: 700;
} */
.breadcrum-tab li:nth-child(2) .counter:after {
  content: '2';
  position: relative;
  top: 18%;
  left: -16%;
  color: #91c9d3;
  width: 100%;
  font-weight: 700;
}
.breadcrum-tab li:nth-child(3) .counter:after {
  content: '3';
  position: relative;
  top: 18%;
  left: -19%;
  color: #91c9d3;
  width: 100%;
  font-weight: 700;
}
.form-group label {
  color: #4b5455;
  font-weight: 400;
  font-size: 16px;
  margin-left: 5px;
}
.btn-label{
  margin-top: 32px !important;
}
.verify .logo img {
  width: 100px;
}
.verify .text {
  width: 500px;
  margin: 0 auto;
}
.verify .text h1{
 font-size:50px;
 font-weight: 700;
 color: #2b6a8e;
}
.verify .text h4{
  font-size: 24px;
  color: #383b3d;
  font-weight: 400;
}
/* ------------------.signup-process end-------------------- */
/* ------------------radio checkbox file upload start-------------------- */
.form-check.vat-checkbox {
  padding-left: 0rem;
}
.cm_container {
  display: block;
  position: relative;
  padding-left:30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default radio button */
.cm_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
/* Create a custom radio button */

.radio-checkmark {
  position: absolute;
  top: 6px;
  left: 0;
  height: 20px;
    width: 20px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #eee;
}
.checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 18px;
  width: 18px !important;
  background-color: #eee0 !important;
  border-radius: 4px;
  border: 1px solid #92c9d2;
}
.csmt-rad-btn .radio-checkmark{
  border: 1px solid #95d7ff;
}
.csmt-rad-btn .assign-checkboc-parent > div:not(:last-child){
  margin-right: 1rem;
}
.csmt-rad-btn .assign-checkboc-parent .radio-checkmark{
  top: 1px;
}
/* On mouse-over, add a grey background color */
.cm_container:hover input ~ .radio-checkmark , .cm_container:hover input ~ .checkmark{
  background-color: #ccc;
}
/* When the radio button is checked, add a blue background */
.cm_container input:checked + .radio-checkmark , .cm_container input:checked + .checkmark {
  background-color: #fff;
}
/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-checkmark:after , .checkmark:after{
  content: "";
  position: absolute;
  display: none;
}
/* Show the indicator (dot/circle) when checked */
.cm_container input:checked ~ .radio-checkmark:after , .container input:checked ~ .checkmark:after  {
  display: block;
}
/* Style the indicator (dot/circle) */
.cm_container .radio-checkmark:after {
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #2b6a8e;
}
.cm_container .checkmark:after {
  left: 5px;
  top: 1px;
  width: 7px;
  height: 11px;
  border: solid #92c9d2;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
/* ---------------admin pannel start----------- */

.dash_body_container {
  min-height: 100vh;
  position: relative;
  padding: 90px 3.5rem 29px calc(320px + 2.2rem);
  /* background-image: url(../src/assets/images/homebanner-bg.png); */
  background-image: linear-gradient(#fff 40%, #fff);
  background-position: bottom;
  background-repeat: no-repeat!important; 
  background-size: cover!important;
  min-height: 100vh;
}
.shadow-wrapper {
  box-shadow: 0 0 5px rgb(0 0 0 / 17%);
  padding: 1.5rem !important;
}
.dash_header {
  padding: 1.3rem 2.2rem;
  width: calc(100% - 340px);
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  background: -webkit-gradient(linear,left top,left bottom,color-stop(44%,hsla(0,0%,97.3%,.95)),color-stop(73%,hsla(0,0%,97.3%,.46)),to(hsla(0,0%,100%,0)));
}
.sidebar .cm-logo {
  padding: 30px 0;
  background: #0C2533;
}
.sidebar {
  box-shadow: 0 0 15px 0 rgba(0,0,0,.05);
  width: 100%;
  max-width: 300px;
  position: fixed;
  top: 0;
  min-height: 100vh;
  max-height: 100%;
  left: 0;
  z-index: 9999;
  background: #0D3D58;
  padding-top: 0px;
  overflow-y: scroll;
}
.cm-logo a, .cm-logo a:hover{
  text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    font-size: 15px;
}
.sidebar ul {
  padding-inline-start: 0px;
}
.sidebar > ul > li > a, .menu-wrapper-admin-sidebar > li > span > a {
  padding: 16px 15px 16px 15px;
  display: block;
  position: relative;
  color: #fff;
  font-weight: 900;
  font-size: 18px;
  border-top: 1px solid #245F83;
  /* text-shadow: 0px 0px 1px #FFF; */
}

.sidebar > ul > li > a > span svg, .menu-wrapper-admin-sidebar > li  span svg{
  width: 26px;
    height: 26px;
  margin-right: 20px;
}
.sidebar > ul > li > a.active, .sidebar > ul > li > a:hover, .menu-wrapper-admin-sidebar > li > span > a:hover, .menu-wrapper-admin-sidebar.ant-menu .ant-menu-submenu .ant-menu-item a:hover, .menu-wrapper-admin-sidebar.ant-menu .ant-menu-submenu .ant-menu-submenu-title:hover, .menu-wrapper-admin-sidebar > li > span > a.active, .menu-wrapper-admin-sidebar.ant-menu .ant-menu-submenu .ant-menu-item a.active,  .menu-wrapper-admin-sidebar.ant-menu .ant-menu-item-active.ant-menu-item-selected a{
  background: #2D688D;
  color: #fff;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  border-left: 3px solid #e52113;
  text-decoration: none;
}
/* , .menu-wrapper-admin-sidebar.ant-menu .ant-menu-sub .ant-menu-item-selected a */
.menu-primary .cross svg path {
  fill: #fff;
  stroke: #fff;
}
.sidebar > ul > li > a > span svg path, .menu-wrapper-admin-sidebar > li  span svg{
  fill:#fff;
}
.cross svg {
  width: 14px;
    cursor: pointer;
    display: none;
}
.dash_body_container .sidebar .cross svg path ,.dash_body .sidebar .cross svg path{
  fill: #fff;
  stroke: #ffff;
}
.sidebar .logo svg {
  width: 50px;
  margin-right: 10px;
}
.mobile_trigger{
  display:none;
}
.menuicon svg {
  width: 28px;
  margin: 0 14px;
  cursor: pointer;
}
.inner_header {
  padding: 1.1rem 2.2rem;
  width: calc(100% - 300px);
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  border-bottom: 1px solid #d4e0ef73;
}
.profile img {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  border: 1px solid #000;
}
.search_wrapper i svg {
  width: 24px;
  fill: #e52113;
  right: 0;
  transform: translateY(-50%);
  top: 4px;
}
.profile {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.profile .caret-icon{
  background: white;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--btncolor);
    cursor: pointer;
}
.profile .filter {
  background: var(--btncolor);
  width: 44px;
  height: 44px;
  border-radius: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--btncolor);
  cursor: pointer;
}
.profile .filter svg{
  width: 18px;
  fill:#fff
}
.profile .caret-icon .fa{
  color: var(--btncolor)
}
.profile .text {
  margin: 0 13px;
}
.profile .text p:first-child {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0;
  color: #000;
}
.profile .text p:nth-child(2) {
  font-weight: 700;
  /* text-transform: capitalize; */
  font-size: 12px;
}
.search_wrapper {
  position: relative;
  max-width: 300px;
  min-width: 300px;
  width: 100%;
  left: 0;
  top: 0;
  background: white;
  height: 100%;
  border-radius: 2rem;
  padding-left: 22px;
  padding-right: 35px;
  border: 1px solid #D7EAED;
    max-height: 50px;
}
.search_wrapper .fa-search, .search_wrapper .fa-times {
  right: 20px;
    color: #737373;
}
.search_wrapper i {
  /* position: absolute; */
  /* top: 47%; */
  position: fixed;
  right: 28px;
        bottom: 1.4%;
  transform: translateY(-50%);
}
.search_wrapper input {
  border: 0;
  width: 100%;
  height: 100%;
  outline: none;
  background: #de232300;
  padding: 12px;
  padding-left: 0;
  padding-right: 27px;
}

.location_wrapper {
    max-width: 150px;
    min-width: 150px;
    padding-right: 15px;
}

.header-text{
display: flex;
justify-content: space-between;
align-items: center;
}
.header-text .header-icon {
  width: 45px;
  height: 45px;
  background:var(--btncolor);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 18px;
  margin-bottom: 0;
}
.header-text .header-icon svg path {
  fill: white;
}
.header-text h4 {
  font-weight: 600;
  font-size: 29px;
  /* text-transform: capitalize; */
  margin-bottom: 0;
}
.header-text .header-icon svg {
  width: 24px;
  height: 24px;
}

.table-responsive {
  box-shadow: 0px 4px 8px #bdf5ff85;
  border-radius: 20px;
  width: 99.5%;
}

/* table.table.table-bordered {
  border-radius: 14px; 
  box-shadow: 0px 3px 12px #c8c8c8;
}*/

table.table-bordered {
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 100%;
  margin-bottom: 0;
  background: #fff;
}
table.table-bordered tr th,
table.table-bordered tr td {
  border-right: 1px solid#ececec4f;
  border-bottom: 1px solid #ececec4f;
  padding: 14px;
  text-align: left !important;
  vertical-align: middle;
  font-size: 14px;
}



table.table-bordered tr th:first-child,
table.table-bordered tr td:first-child {
  border-left: 1px solid #ececec4f;
}
table.table-bordered tr th{
  background: #8BC3CB;
  /* border-top: 1px solid #ECECEC; */
  text-align: left;
  color: #fff;
  font-weight: 700;
}
table.table-bordered tr td p {
    margin-bottom: 0;
}

table.table-bordered .toggle-parent {
    justify-content: center;
}
table.table-bordered tr td p p:first-child {
  border-bottom: 1px solid #E1F0F5;
}
.circle-serial {
  background: #fff;
  width: 30px;
  height: 30px;
  border: 1px solid #92C9D1;
  border-radius: 100px !important;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #A6B3BD;
  margin: 0 auto;
}
/* top-left border-radius */
table.table-bordered tr:first-child th:first-child {
  border-top-left-radius: 20px;
}

/* top-right border-radius */
table.table-bordered tr:first-child th:last-child {
  border-top-right-radius: 20px;
}

/* bottom-left border-radius */
table.table-bordered tr:last-child td:first-child {
  border-bottom-left-radius: 20px;
}

/* bottom-right border-radius */
table.table-bordered tr:last-child td:last-child {
  border-bottom-right-radius: 20px;
}
table.table-bordered tr:nth-child(odd) td {
  background: #F3E1E1;
}
.table-bordered .toggle-parent .switch {
  width: 35px;
  height: 16px;
}
.table-bordered .toggle-parent .slider:before {
  left: 1px;
  background-color: #e52113;
}
.table-bordered .toggle-parent .switch input:checked~.slider:before {
  background: #27D237;
}
.toggle-parent input:checked + .slider:before , .toggle-parent .slider.round:before{
  /* transform: translateX(18px); */
}

/* .action_group a, .action_group .btn {
  display: inline-flex;
  margin-left: 10px;
} */
td.action_group , td.ant-table-cell .action_group {
  min-width: 100px;
}
.enqire span.ant-select-selection-item {
  line-height: 1.8 !important;
}
.ant-select.enqire.ant-select-single.ant-select-show-arrow {
  width: 150px !important;
}
.action_group .circle-serial svg path {
  fill: #2d688d;
}
.enquire_parents .action_group .circle-serial svg path
{
  fill: #2d688dab;
}
.action_group .circle-serial svg {
    width: 13px;
}
.action_group .circle-serial {
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.ant-modal.transaction-modal .ant-modal-content {
  border-radius: 20px !important;
}
.ant-modal.transaction-modal .search-container .btn, .search-container .btn:hover {
  min-width: 120px;
}
.cm_popup .ant-modal-close-x{
  border: 1px solid #e52113;
  border-radius: 100px;
  line-height: 18px;
  margin: 8px;
  color: #e52113;
  width: 26px;
  height: 26px;
}
.cm_popup .ant-modal-close-x svg{
  width: 12px;
    height: 12px;
}
.ant-modal-close:focus, .ant-modal-close:hover, .ant-modal-close-x:focus{
  outline:none !important
}
.ant-modal.transaction-modal .rounded-textbox-form .form-control {
  border-color: #9aabad;
  height: calc(2em + .75rem + 2px);
}
.transaction-modal .ant-modal-body h2 {
  font-size: 29px;
  font-weight: 600;
}
.action_group p, .action_group .btn {
  display: inline-flex;
}
.action_group a:nth-child(2), .action_group .btn:nth-child(2), .action_group p:nth-child(2n + 0) , .action_group .btn:nth-child(2)  {
  margin-left: 5px;
}
.header-breadcrum {
  display: block;
  font-size: 17px;
  padding-inline-start: 0;
  font-weight: 400;
  color: #808080;
  margin-top: 4px;
  margin-bottom: 0;
}
.header-breadcrum li {
  display: inline-block;
  margin-right: 5px;
}
.header-breadcrum li a {
  font-size: 16px;
  color: #808080;
  margin-right: 5px;
}
/* ---------------------------Process------------------------- */
.order-status-process {
  display: flex;
  justify-content: center;
  padding: 60px 0;
}
.status-process-step {
  text-align: center;
  position: relative;
  width: calc(100% / 5 );
}
.process-circle {
  background: #C7C9CB;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  text-align: center;
  /* border: 4px solid #fff; */
  position: relative;
  left: 50%;
  transform: translate(-50%, 0px);
  z-index: 1;
  display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
}
.process-circle svg {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
}
.process-circle svg path {
  fill: #fff;
}
.status-process-step p{
  font-weight: 700;
  margin: 10px 0px;
  color: #C7C9CB;
}

/* .status-process-step:not(:last-child):before {
  content: "";
  display: block;
  position: absolute;
  width: 81%;
  height: 4px;
  top: 35%;
  left: 0;
  right: 0;
  background: #ebebeb;
  border: 2px #ebebeb solid;
  margin-left: calc( 100% - 48%);
} */
.status-process-step:before {
  content: "";
  display: block;
  position: absolute;
  width: 50%;
  height: 1px;
  top: 26%;
  left: 0%;
  right: 0;
  background: #92C8D2;
  /* border: 1px #92C8D2 solid; */
}
.status-process-step:after {
  content: "";
  display: block;
  position: absolute;
  width: 50%;
  height: 1px;
  top: 26%;
  right: 0;
  background: #92C8D2;
  /* border: 1px #92C8D2 solid; */
}
.status-process-step.complete .process-circle  {
  background: #2d688d;
}
.status-process-step.complete p{
  color: #2d688d;
  position: relative;
}
.status-process-step.complete p:after {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #2d688d;
  position: absolute;
  top: 30px;
  content: '';
  left: 48%;
}
.status-process-step i.fa.fa-circle.last{
  position: absolute;
  right: 0;
  top: 18%;
  z-index: 9;
  color: #92C8D2;
  font-size: 12px;
}
.status-process-step i.fa.fa-circle.first{
  position: absolute;
  left: 0;
  top: 18%;
  z-index: 9;
  color: #92C8D2;
  font-size: 12px;
}
.status-process-step.complete:not(:last-child):before {
  /* background: #000;
  border-color: #000; */
}

.order-status-detail  {
  width: calc(100% / 3 );
}
.order-status-detail p {
  font-weight: 600;
}
.order-status-detail h6 {
  font-weight: 700;
}
.order-status-detail .address{
color: #666666;
}
/* -------------------lnding page---------------- */
.landing_bannerbg{
  /* background-image: url(../src/assets/images/labding-banner.png); */
  /* background-image: url(../src/assets/images/countdown.png); */
  /* background-image: url(../src/assets/images/aesthetic/banner.png); */
  background-position: bottom center;
  background-repeat: no-repeat!important; 
  /* min-height: 100vh; */
  min-height: 45vh;
  width: 100%;
  background-size: cover;
  position: relative;
}
.Home-banner-bg .countdown-page{
  /* background-image: url(../src/assets/images/countdown1.png) !important; */
  /* background-color: pink; */
  background-position: bottom center;
  background-repeat: no-repeat!important; 
  /* min-height: 100vh; */
  width: 100%;
  max-height: 100%;
}
/* .Home-banner-bg.landing-page.cm-home.brand-home-banner-bg.aesthetic {
  background-image: url(../src/assets/images/aesthetic/bg-banner.jpeg) !important; 
} */
/* .Home-banner-bg .countdown-page{
  background-image: url(../src/assets/images/aesthetic/count-down.jpeg) !important;
} */
.countdown-page .banner-text{
  position: relative !important;
}
.thankyou-text h1{
  font-family: "Sacramento";
  color: #92c9d2;
  font-size: 98px;
  margin-bottom: 0;
}
.callu_parent a{
  color: #fff;
}
.landing-page .logo img {
  width: 270px;
  
}
.hert-parent{
  display: flex;
  justify-content: center;
}
.logo-skew{
  display: flex!important;
    align-items: center;
    justify-content: space-around;
}
.logo-skew div{
  width:50%;
  text-align: right;
}
.countdown-page .landing_bannerbg{
  /* background-image: url(../src/assets/images/countdown.png); */
  background-position: bottom center;
  background-repeat: no-repeat!important; 
  min-height: 100vh;
  width: 100%;
  background-size: cover;
  position: relative;
}
.landing_bannerbg .banner-text {
  position: absolute;
  top: 8%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
    text-align: center;
    color: #fff;
}
.landing_bannerbg .banner-text .heading-after-border .heart {
  background: #362783;
}
.landing_bannerbg .banner-text .home-heading {
  color: #fff;
}
.landing_bannerbg .banner-text h4{
  color: #fff;
  font-weight: 700;
  padding-top: 20px;
  margin-bottom: 5px;
  font-size: 22px;
}
.countdown-page .landing_bannerbg .banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.skew_text {
    width: 205px;
    transform: skew(-23deg);
    background: #FF0000;
    text-align: center;
    padding: 6px 8px;
    margin: 0 14px;
    font-weight: 600;
}
.countdown-page .skew_text{
  margin: 0 auto !important;
}
.countdown-page .thankyou-text p {
  font-size: 21px;
  color: #e2e6f6;
  margin: 24px 0;
}
.countdown-page .thankyou-text p.skyblue{
  color: #92c9d2;
  margin: 0 0;
}
.thankyou-text .callu_parent {
  display: flex;
  border: 1px solid var(--btncolor);
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.callu_parent div:first-child {
  width: 60%;
  padding-left: 50px;
    font-size: 16px;
    color: #fff;
}
.callu_parent div.traingle{
  width: 0;
    height: 100%;
    border-bottom: 40px solid red;
    border-left: 40px solid transparent;
}
.callu_parent div:nth-child(2) {
  color: #fff;
  background: #FF0000;
  margin: 0 0;
  padding: 19px 36px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;
}
.callu_parent span.traingle {
  position: absolute;
    left: -12px;
    top: 0;
    width: 50px;
    height: 100%;
    z-index: 1;
}
.hert-phone{
  position: absolute;
    width: 54px;
    left: -32px;
    top: 24px;
    z-index: 99999;

}
.callu_parent div:nth-child(2) a {
  font-size: 16px;
    font-weight: bold;
}
.callu_parent span.traingle svg {
  position: absolute;
  width: auto;
  height: 100%;
  top: 0;
  left: 1px;
  fill: var(--btncolor);
}
.callu_parent div:nth-child(2) {
  color: #fff;
  background: var(--btncolor);
  margin: 0 0;
}
.skew_text p.text {
  transform: skew(23deg);
  margin-bottom: 0;
}
.landing_bannerbg .discription{
  margin: 20px 0;
    font-weight: 700;
    font-size: 17px
}
.minusmargin-header{
  /* margin-top: -130px; */
  
    margin-bottom: 120px;
}

.tablediv .Second.sky-blue .correct svg , .tablediv .Second.sky-blue .wrong svg{
  width: 28px;
  fill: #0367A6;
}
.tablediv .Second.blue .correct svg, .tablediv .Second.blue .wrong svg {
  width: 28px;
  fill: #F2B680;
}
.tablediv .Second.red .correct svg, .tablediv .Second.red .wrong svg {
  width: 28px;
  fill: #F25922;
}
.showmobile991.plan-responsive table .correct svg, .showmobile991.plan-responsive table .wrong svg {
  width: 20px;
  fill:#0367A6;
}
.showmobile991.plan-responsive table.red .correct svg, .showmobile991.plan-responsive table.red .wrong svg {
  fill:#F25922;
}
.showmobile991.plan-responsive table.blue .correct svg, .showmobile991.plan-responsive table.blue .wrong svg {
  fill:#F2B680;
}
.wrong svg {
  fill: #b7b7b7 !important;
}
.tablediv {
  display: flex;
  width: 100%;
  align-items: flex-start;
}
/* .tablediv div {
  width: calc( 100% /4 );
} */

.tablediv .first p , .tablediv .Second p{
  padding: 11px 12px 11px 32px;
  border-bottom: 1px solid #eeeeee7d;
  margin-bottom: 0;
  max-height: 58px;
  min-height: 60px;
  font-weight: 700;
 
}
.tablediv .first p:last-child{
  border-bottom: none;
  border-bottom-left-radius: 5px;
}
.tablediv .first p:first-child {
  border-top-left-radius: 5px;
}
.tablediv .first{
width: 30%;
}
.tablediv .first span{
line-height: 11px;
display: flex;
  }
.tablediv .Second{
  width: 23%;
  color: #000;
}
.tablediv .first .tb_body{
  border: 1px solid #828282;
    background: #fff;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    margin-top: -1px;
    color: #000;
}
.tablediv .first .header, .tablediv .first .header h1, .tablediv .first .header h6, .tablediv .first .header h3, .tablediv .first .header span svg path {
  background: #fff0;
  color: #0000;
  fill: #b15c5c00;
}
.tablediv .Second p{
  border-bottom: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tablediv .Second p:nth-child(odd) {
  background: #fff;
}
.tablediv .Second{
  cursor: auto !important;
}
.tablediv .Second p:nth-child(odd), .tablediv .first p:nth-child(even) {
  background: #fff;
  border-bottom: 2px solid #828282;
}
.tablediv .Second p:nth-child(even), .tablediv .first p:nth-child(odd) {
  background: rgba(196, 196, 196, 0.2);;
  border-bottom: 1px solid #828282;
}

/* .tablediv .Second.red .header {
  min-height: 212px;
  max-height: 212px;
} */
.tablediv .first p {
  display: flex;
  align-items: center;
}
.tablediv .header {
  width: 100% !important;
  text-align: center;
  min-height: 130px;
  background: #fff;

  padding: 20px 0;
  min-height: 125px;
  max-height: 125px;

}
.tablediv .first .header {
  background: #fff0 ; 
}
.tablediv .Second .header p:nth-child(even), .tablediv .Second .header p:nth-child(odd)  {
  background: #fff !important;
} 

 .tablediv .Second .header h3{
  margin-bottom: 0 !important;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: #fff;

}
.Second .header h6 {
  color: #fff;
}
.header span svg {
  width: 42px;
  height: 42px;
}
.header span svg path{
fill:#fff
}
.tablediv .Second{
  border-collapse: collapse;
  /* border-radius: 20px; */
  /* background: #fff */
}
.tablediv .Second.sky-blue{
  /* border-radius: 20px; */
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #0367A6;
  border-bottom: 1px solid #828282;
  color:#000;
  border-right: 1px solid #0367a6;
}
.Second.sky-blue .header {
  background: #0367A6;
}
.Second.red .header{
  background: #F25922;
}
.Second.blue .header{
  background:  #F2B680;
}
.tablediv .Second.red{
  /* border-radius: 20px; */
  border: 1px solid #F2B680;
  border-top: none;
  /* margin-top: -40px; */
}
/* .tablediv .first.gray{
  border-radius: 20px;
  border: 1px solid #828282;
  /* margin-top: -40px; */

.tablediv .Second.blue{
  border-right-width: 1px;
  border: 1px solid #F2B680;
  border-top: none;
    /* border-right-style: solid;
    border-right-color: #000;
    border-bottom-color: #000; */
}
.tablediv .Second.red .btn-parent .btn, .tablediv .Second.sky-blue .btn-parent .btn-red {
  /* border: 4px solid #fff; */
  box-shadow: 0px 2px 7px #7b334996;
  margin: 0px;
}
.tablediv .Second.sky-blue .btn-parent .btn-red{
  background: #0367A6 !important;
}
.tablediv .Second.red .btn-parent {
  padding-bottom: 40px;
  text-align: center;
  /* margin-top: 20px; */
}
.tablediv .Second.sky-blue .btn-parent .btn-red, .plan-responsive .header .btn-parent .btn-red {
  background: #cbd3d4;
  box-shadow: 0px 2px 7px #b8ebf6ad;
    border: 2px solid #0367A6;
}
.tablediv .Second.blue .btn-parent .btn-red , .plan-responsive .Second.blue .header .btn-parent .btn-red{
  background: #F2B680;
  
    /* border: 4px solid #fff; */
    margin: 0
}
.plan-responsive .Second.red .header .btn-parent .btn-red ,.tablediv .Second.red .btn-parent .btn{
background: #F25922 !important;
}
.tablediv .Second.blue .btn-parent, .tablediv .Second.sky-blue .btn-parent, .tablediv .Second.red .btn-parent {
  text-align: center;
  padding: 0px 0;
}
.tablediv .Second {
  -webkit-transition: margin 1s;
}
.tablediv .Second:hover {
  /* margin-top: -40px; */
  cursor: pointer;
}
.chs-plan-wrp{
  box-shadow: initial !important;
  background:#fff !important;
}
.hj .features-card.chs-plancrd-wrp .card-body{
  padding: 1.25rem 1.25rem;
}
.feature .features-card.chs-plancrd-wrp .card-body h5{
  font-size: 16px;
}
.tablediv .Second.red:hover {
  /* margin-top: -60px; */
  border: 3px solid #7B3349;
}
.tablediv .Second.sky-blue:hover {
  border: 3px solid var(--btncolor);
}
.tablediv .Second.blue:hover {
  border: 3px solid #2c688d;
}
.hert-section .center-text{
  position: absolute;
    top: 32%;
    left: 50%;
    transform: translate(-50% ,-50%)
}
.hert-section .center-text h1 {
  font-size: 25px;
  font-weight: 600;
  color: #fff;
}

.hert-section .center-text h1, .hert-section .center-text h4 {
  margin-bottom: 0 !important;
  padding: 0 !important;
}
.hert-section .center-text h4 {
  font-weight: 400;
  color: #fff;
  font-size: 19px;
}
.payment-msz-parent {
  background: #f2fcfe;
  box-shadow: 0px 0px 19px #bdeff8;
  border-radius: 20px;
  margin: 60px 0 40px 0;
}
.payment-msz-parent p{
    padding: 12px 12px;
    margin-bottom: 0;
}
.payment-msz-parent p span:first-child {
  padding-left: 51px;
  font-weight: 700;
}
.payment-msz-parent p:last-child span {
  color: #2c688d;
  font-weight: 700;
}
.payment-msz-parent p span img {
  width: 54px;
  margin-right: 20px;
}
.payment-msz-parent p span {
  width: 50%;
  display: inline-block;
  font-size: 18px;
  font-weight: 800;
  text-align: left;
}
.payment-msz-parent p:nth-child(even){
background: #e2f9fd;
}
.paymentcard{
  background: #fff;
  border-radius: 20px;
  padding: 20px 0;
  text-align: left;
}
.paymentcard h5{
  color: #92c9d2;
}
.paymentcard p{
  margin-bottom: 0;
}
/* for one card- */
/* .paymentcard .header img{
  width: 40px;
  margin-right: 10px;
} */
.paymentcard .header img {
  width: 249px;
  margin-right: 10px; 
}
.payment-acknowledge {
  background: white;
  border-radius: 20px;
  border: 1px solid var(--btncolor);
  margin: 40px 0;
  padding: 30px;
  text-align: left;
}
.payment-acknowledge h2 {
  font-size: 30px;
  font-weight: 700;
  position: relative;
  padding-left: 14px;
  margin-bottom: 35px;
}
.payment-acknowledge h2:before {
  position: absolute;
  top: 0;
  width: 4px;
  height: 100%;
  content: '';
  background: #2c688d;
  left: 0px;
}
.payment-acknowledge ul {
  padding-inline-start: 24px;
}
.payment-acknowledge ul li span{
  color: #92c9d2;
  margin-right: 8px;
}
.payment-acknowledge .note{
  font-weight: 700;
  margin: 20px 0 40px 3px;
}
.payment-acknowledge a {
  color: #2c688d;
  font-weight: 700;
  text-decoration: underline;
}
.payment-acknowledge p, .payment-acknowledge label, .payment-acknowledge li {
  font-size: 16px;
  color: #000;
  margin-bottom: 0;
  padding: 6px 0px;
}
.payment-acknowledge h3 {
  font-size: 23px;
  font-weight: 700;
}
.payment-acknowledge .hert svg {
  width: 17px;
  margin-right: 11px;
  margin-top: -5px;
  fill: #2c688d;
}
.ant-modal.paymentpopup .ant-modal-content{
  background-image: url(../src/assets/images/second-banner-bg.jpg);
  background-position: bottom center;
  background-repeat: no-repeat!important; 
  min-height: 100px;
  width: 100%;
  background-size: cover;
  position: relative;
  border-radius: 20px;
}
.ant-modal.paymentpopup.croperpopup .ant-modal-body {
  min-height: 336px;
  padding: 52px;
}
.ant-modal.paymentpopup.croperpopup {
  width: 50% !important;
}
.ant-modal.paymentpopup .ant-modal-header {
  color: rgba(0, 0, 0, 0.65);
  background: #fff0;
}
.ant-modal.paymentpopup span.anticon.anticon-close.ant-modal-close-icon {
  background: #ffc0cb00;
  padding: 6px;
  border-radius: 100px;
  border: 1px solid red;
}
.search_wrapper.mr-2.ant-dropdown-trigger i.fa.fa-search {
  position: absolute;
  top: 57%;
}
span.anticon.anticon-caret-down.ant-select-tree-switcher-icon {
  display: none;
}
.ant-modal.paymentpopup span.anticon.anticon-close.ant-modal-close-icon svg {
  fill: red;
}
.ant-modal.paymentpopup .form-group label {
  font-size: 22px;
  color: #000;
  font-weight: 700;
}

.search-container {
  padding-top: 0 !important;
    border-radius: 100px !important;
    padding: .3rem 0.4rem !important;
    font-size: 16px !important;
    border: 1px solid var(--btncolor) !important;
    background: #fff;
}
.promocode .form-control {
  height: calc(1em + .75rem + 2px);
}
.search-container .btn , .search-container .btn:hover{
  background: var(--btncolor);
  border-radius: 100px;
  font-size: 12px;
  padding: 6px 26px !important;
  line-height: 1;
  color: #fff;
}
.paymentcard .search-container .btn, .paymentcard .search-container .btn:hover{
  min-width: 85px;
}
.search-container input {
  border: none;
  width: 100%;
}

.radio-parent {
  display: flex;
  justify-content: center;
}
.circular-btn-radio {
  padding: 9px 18px;
  background: white;
  border-radius: 100px;
  min-width: 125px;
  text-align: center;
  border: 1px solid #92c9d2;
  margin: 0 8px 45px 8px;
}
.circular-btn-radio .cm_container {
  margin-bottom: 0;
  padding: 0 0px 0 28px;
  font-weight: 600;
}
.circular-btn-radio .cm_container img {
  margin-right: 6px;
}
.paymentcard .header {
  background: #92c9d2;
  padding: 0px 10px 0 0;
  border-radius: 5px;
  display: flex !important;
    align-items: center;
}
.paymentcard h5 {
  color: #ffffff;
  margin-bottom: 0;
  font-weight: 700;
}
.main-heading{
  color: var(--btncolor) !important;
  font-weight: 700;
  margin-bottom: 20px !important;
  font-size: 27px;
}
.form-group.form-check.vat-checkbox label span {
  width: 100%;
  display: block;
  font-weight: 600;
}
.form-group.form-check.vat-checkbox label span:nth-child(3), .form-check.vat-checkbox .cm_container span:nth-child(2){
  font-weight: 400;
  font-size: 14px;
  
}
.promocode {
  background: #ecfcff;
  margin: 0 -16px 17px;
  padding: 9px 16px;
}
.payment {
  margin-right: 15px;
  margin: 0 auto;
}
.header .payment svg {
  width: 60px !important;
  padding: 0px 12px;
  background: #2c688d;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  height: 55px;
}
.payment-parent {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}
.rounded-textbox-form{
  padding: 5px 35px;
}
.signin-bg .rounded-textbox-form{
  padding: 5px 0px 35px;
}
.paymentcard .rounded-textbox-form {
  padding: 5px 35px;
}
.success-msz{
  color: #09dd58;
}
.StripeElement.StripeElement--empty , .StripeElement.StripeElement--focus, .StripeElement.StripeElement--invalid , .StripeElement.StripeElement--complete {
  padding:0.3rem .25rem !important;
}
.cm_striped_label .StripeElement--empty, .cm_striped_label .StripeElement--focus,
.cm_striped_label .StripeElement.StripeElement--complete,
.cm_striped_label .StripeElement.StripeElement--invalid,
.StripeElement .__PrivateStripeElement iframe input.__PrivateStripeElement-input  {
  margin-top: 5px !important;
    display: block !important;
    width: 100% !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #495057 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    margin-bottom: 18px !important;
    border: 1px solid #9aabad !important;
    height: calc(2.4em + .75rem + 2px) !important;
    border-radius: 100px;
    padding: 1rem 1.5rem !important
}

.diabled-div .cm_striped_label .StripeElement--empty, .diabled-div .cm_striped_label .StripeElement--focus, .diabled-div .cm_striped_label .StripeElement.StripeElement--complete, .diabled-div .cm_striped_label .StripeElement.StripeElement--invalid, .diabled-div .StripeElement .__PrivateStripeElement iframe input.__PrivateStripeElement-input  {
  background-color: #e9ecef !important;
}
.total {
  padding: 15px 16px;
  background: var(--btncolor);
  margin: 8px -16px -20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: #fff;
}
.showmobile991{
  display: none ;
}
.ElementsApp input{
  font-weight: 400 !important;
}
.signin-bg .logo img{
  cursor: pointer;
}
.cm_multiselect .ant-select-multiple .ant-select-selector , .ant-select-multiple .ant-select-selector {
 
  min-height: calc(1.4em + .75rem + 2px) !important;
    background: #ffffff !important;
    width: 100%;
    max-height: 53px;
    overflow: auto;
    border-radius: 100px !important;
    padding: 6px 20px !important;
    font-size: 16px;
    flex-wrap: nowrap !important;
    white-space: nowrap;
}
.multi-select-wrp .cm_multiselect.ant-select-multiple .ant-select-selector{
  max-height: inherit;
  border-radius: 10px !important;
}
.multi-select-wrp .cm_multiselect.ant-select-multiple .ant-select-selector .ant-select-selection-overflow{
  max-height: 80px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.multi-select-wrp .cm_multiselect.ant-select-multiple .ant-select-selector .ant-select-selection-overflow::-webkit-scrollbar{
  opacity: 0;
}
.cm_multiselect .ant-select-multiple  span.ant-select-arrow {
  background: white;
}
.anticon.anticon-search.ant-select-suffix{
  display: none;
}
.autocomplete-dropdown-container {
  position: absolute;
  width: 92%;
  z-index: 98;
  margin-left: 2%;
  background: #fff;
}
.autocomplete-dropdown-container .suggestion-item , .autocomplete-dropdown-container .suggestion-item--active{
  padding: 12px;
  border-bottom: 1px solid #eeeeeec2;
}
.ant-select-multiple .ant-select-selector::-webkit-scrollbar {
  display: none;
}
.footer-big .footer-menu ul li a:hover {
  color: var(--btncolor) !important;
  text-decoration: underline;
}
.footer-big .footer-menu ul li a:hover svg {
  fill: #e52113;
}
ul.social-networks a:hover i {
  color: red;
}
.btn.btn-red:hover {
  opacity: 1 !important;
  background: var(--btncolor) !important;
  color: #fff !important;
}
.ant-dropdown.ant-dropdown-placement-bottomLeft {
  z-index: 9999;
}
.top-lgn-btn {
  flex-direction: column;
}
.top-lgn-btn  .btn.btn-red.addbtn {
  margin: 6px 0px;
  margin-right: 0px !important;
  background: transparent !important;
  border: 2px solid var(--homeColor);
  color: #000;
  font-size: 13px;
  font-weight: 600;
}
.top-lgn-btn  .btn.btn-red.addbtn:hover{
  background: var(--homeColor) !important;
}
/* ---------------------transaction--------------- */
.filtermenu.ant-dropdown-menu {
  background: #fff;
  border-radius: 20px;
  min-width: 400px;
  padding: 20px ;
}
.form-check-label.checkbox-header{
  font-size: 18px;
  font-weight: 700;
}
.form-check-label.checkbox-header .form-check-input {
  margin-top: 8px;
}
.rounded-box{
  background: #fff;
    border-radius: 24px;
    padding: 60px;
}
.transaction-details-table td td:nth-child(even){
background: pink;
}
.transaction-details-table {
  border: 1px solid #dee2e6;
}
.transaction-details-table .table-bordered {
  border: 1px solid #dee2e600;
}
.transaction-details-table table tr td:nth-child(odd) {
  background: #92C9D2;
  color: #fff;
  font-weight: 500;
  font-size: 17px;
}
.transaction-details-table table tr td:nth-child(even) {
  background:transparent;
  color: #000;
  font-weight: 500;
  font-size: 17px;
}
.transaction-details-table table tr:nth-child(even) {
  background: #F4F9FD;
}
.transaction-details-table table tr td {
  width: 25%;
}
.tran-details .bottom-btn{
 display: flex;
 justify-content: space-between;
 align-items: center;
 margin: 35px 0px;
}
.tran-details .total-box {
  min-width: 400px;
  background: #2d688d;
  border-radius: 100px;
  justify-content: flex-start;
  align-items: center;
  padding: 14px 24px;
  font-size: 17px;
}
.tran-details .total-box p {
  width: 50%;
  margin-bottom: 0;
  color: #fff;
  font-weight: 700;
}
.tran-details .bottom-btn .btn.btn-warning {
  background: #CEE6F5;
  border-color: #CEE6F5;
  border-radius: 100px;
  min-width: 124px;
  color: #2d688d;
  font-weight: 600;
  padding: 9px;
}
.tablediv .Second.sky-blue .btn-parent {
  min-height: 52px;
}
.for-phone , .for-tab{
display: none;
}
.for-screen{
  display: block;
  }
  .banner-section .btn, .banner-section .btn:hover {
    min-width: 250px;
    font-size: 15px;
  }
  /* ---------------------page not found---------------------- */
  .notfound-404 {
    margin: 79px 0;
  }
  .notfound-404 h3 {
    color: #2c688d;
    font-size: 45px;
    font-weight: 600;
}
.notfound-404 h1 {
  font-size: 109px;
    font-weight: 700;
    color: #e52113;
    margin-bottom: 19px;
}
.notfound-404 h2 {
  font-size: 34px;
  font-weight: 700;
  color: #000;
  margin-bottom: 0;
}
.dash_body_container.without-sidenav {
  padding: 0px;
}
.blue-border.card-wrapper {
  margin: 50px 0px;
}
.small-container-bg {
  background-image: url('../src/assets/images/aesthetic/container-smallbg.jpeg');
    background-position: bottom;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    min-height: 290px;
    border-radius: 20px;
    border: 1px solid #81cbd4;
    padding: 12px;
    margin:20px 7px;
}
.small-container-bg h1 {
  font-size: 31px;
    color: #706f6fab;
    margin-top: 30px;
    min-height: 76px;
    font-weight: 700;
}
.small-hert-parent{
  /* position: relative; */ 
  display: flex;
  justify-content: center;
}
.small-hert-parent svg {
  width: 38px;
  fill: #006a91;
  position: relative;
}
.small-container-bg h5 {
  margin-top: 24px;
}
.small-hert-parent p {
  /* position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50% ,-50%); */
  color: #fff !important;
  font-weight: 700;
}
.small-hert-parent p {
  background: #8cd0d8;
  width: 45px;
  height: 45px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.heading-discript-head{
    font-size: 24px !important;
    font-weight: 700 !important;

}
.min-w100{
  min-width: 100% !important;
}
.heading-discript-text b {
  color: #000;
  font-weight: 600;
}
.heading-discript-text {
  color: #414141;
  font-size: 18px;
  margin: 36px 0;
  font-weight: 900;
}
.Home-banner-bg .home-header-text {
  font-weight: 700;
}
.Home-banner-bg .home-header-subtext {
  color: #d8d8d8;
}
.glob-connection h6 {
  color: #494949;
  font-size: 18px;
  font-weight: 900;
}
.b-class{
  font-weight: 600;
    color: #000;
    font-size: 17px;
}
.header-wrapper .chat{
  display: none !important;
}
.header-wrapper .logo.mr-auto {
  width: 40%;
}
.menu_open .sidebar .cross svg, .menu_open .sidebar .cross svg path {
  fill: white;
  stroke: #fff;
}
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  display: none !important;
}
.ant-tabs-content-holder {
  margin-top: 44px;
}
.health-parameter-choose .people_saying {
  padding: 34px;
}
.carousel-wrapper .people_saying .card {
  text-align: center;
  justify-content: inherit;
  width: 100% !important;
  padding: 0 !important;
  min-height: auto;
  max-height: 100%;
}
.people_saying .card img {
  margin: 0;
}

 .carousel-wrapper .health-parameter-choose .card img {
  padding: 0;
  border-radius: 20px;
  border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.carousel-wrapper .health-parameter-choose .card{
  padding: 0;
  border-radius: 20px !important;
}
.confrence-cover .card.video-card img{
  /* min-height: 400px;
  max-height: 400px; */
  object-fit: cover;
}
.sm-videoCard.card{
  max-height: 100px !important;
  min-height: 100px ;
  width: 100px !important;
  height: 100px !important;
  overflow: hidden;
}
.video-parent.media-viewed{
  max-width: 100px;
  min-width: 100px;
  min-height: 100px;
  max-height: 100px;
  overflow: hidden;
  position: relative;
  border-radius: 15px;
}
.video-parent.media-viewed .layerdiv {
  max-width: 100px;
  min-width: 100px;
  min-height: 100px;
  max-height: 100px;

}
.card.video-card{
 height: auto; 
}
.carousel-wrapper .fa.fa-long-arrow-left , .carousel-wrapper .fa.fa-long-arrow-right {
  display: none;
}
.prodoct-showcase-slider.viewbrand  .card.brand-card img{
  min-height: 210px;
  max-height: 210px;
}
.featured-brands.showcase .card .action_group{
  background: #fff;
    min-height: 56px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px 
}
.ant-select-dropdown.search-multidropdown.ant-select-dropdown-placement-bottomLeft.ant-select-dropdown-hidden {
  display: block;
  z-index: 9999;
}
/* .ant-modal-mask {
  z-index: 10000 !important;
} */
.ant-dropdown.edit-profile-dropdown.ant-dropdown-placement-bottomLeft{
  z-index: 999 !important;
}
.self-detail p {
  margin-bottom: 0 !important;
  text-align: center;
    font-size: 16px;
    color: #000;
}
.self-detail h4 {
  margin-bottom: 2px  0 !important;
  text-transform: capitalize;
  font-size: 21px;
  font-weight: 700;
}
.self-detail button {
  min-width: 120px;
  text-align: center;
  justify-content: center;
}
.header-text.text-left.self-deatils-table h1 {
  margin: 0;
  font-size: 24px;
  text-align: left;
  width: 100%;
  margin-bottom: 20px;
}
.header-text.text-left.self-deatils-table {
    margin: 0 0 !important;
}
.featured-brands.showcase .card .text-right .toggle-parent {
  position: absolute;
  right: 0;
  z-index: 9;
  top: 10px;
}
.featured-brands.showcase .card .action_group span.flex-grow-1 {
  justify-content: flex-start;
  display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
 .home-networking  h5, .home-networking .networking-lounges h5:hover {
  font-size: 1.25rem;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* ----------search design---------- */
.header-search .notify-row {
  align-items: center;
    transition: 0.9s;
}
.header-search .notify-row .message h5 b {
  font-weight: 600;
  font-size: 16px;
}

.search-result-parent .notify-row{
  padding: 10px 20px 16px 10px;
  cursor: pointer !important;
}

.header-search .notify-row .message span small {
  color: #888;
  font-size: 14px;
  font-weight: 700;
}
.search-result-parent .notify-row .message h5{
  color: #888;
  margin-bottom: 0px !important;
  display: flex;
    align-items: center;
}
.search-result-parent .notify-row .message h5 b {
  color: #3975bb;
    font-size: 22px;
    font-weight: 700;
}
.search-result-parent .notify-row .message h6, .search-result-parent .notify-row .message p {
  text-transform: capitalize;
}
.search-result-parent .notify-row .message h5 small{
  color: #3975bb;
}
.search-result-parent .notify-row .message p , .search-result-parent .notify-row .message h5 , .search-result-parent .notify-row .message h6 {
  margin: 1px;
}
.search-result-parent .notify-row .message h6 {
  font-size: 18px;
}
.search-result-parent .notify-row .message p {
  font-size: 16px;
    color: #707172;
}
.search-result-parent .notify-row .message h5 span {
  margin-right: 4px;
}
.search-result-parent .notify-row .message .action_group button {
  padding: 10px 16px;
  min-width: 155px;
  justify-content: center;
}

.search-result-parent .notify-row .profile-dp, .search-result-parent .notify-row .profile-dp img {
  width: 85px;
    height: 85px;
    object-fit: contain;
 
}
.search-result-parent .notify-row .profile-dp img.circle-image {
  object-fit: cover;
}
.search-result-parent {
  background: #fff;
  border-radius: 20px;
  margin: 40px auto !important;
}
.search-result-parent .notify-row .profile-dp img {
  border: 1px solid #3975bb;
  border-radius: 0;
}
.search-result-parent .notify-row .message {
  justify-content: space-between;
}
.header-search .notify-row:hover , .search-result-parent .notify-row:hover {
  cursor: pointer;
  background: #f2f2f2;
}
.businesscard-btn {
  background: transparent;
  width: 35px !important;
  height: 35px;
  border-radius: 100px;
  border: 1px solid #2d688d;
  position: relative;
  margin-left: 10px;
  cursor: pointer;
}
.businesscard-btn svg {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  fill: #2d688d;
}
.header-search .notify-row .message h5{
  margin-bottom: 0;
}
.popup_inner.user-report-popup {
  padding: 50px 30px !important;
}
/* --------add staff------- */

.staff .card.community-card .align-items-start.flex-column span.zoom {
  display: flex;
}
.staff .community-card.staff-container .textparent div span {
  display: block !important;
}
.staff .card.community-card .align-items-start.flex-column div span.action_group {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
}
.staff .card.community-card .align-items-start.flex-column div span.action_group a {
  width: auto;
  margin-right: 11px;
}
.card.community-card.staff-container .toggle-parent{
  margin-top: 5px;
}
.btn.btn-blue .spinner-border-sm {
  width: 1rem !important;
  border-color: #fff;
  margin-left: 6px;
}
.react-tel-input .country-list .flag {
  margin-right: 7px !important;
  margin-top: 2px !important;
  margin-left: 0% !important;
  margin-bottom: 0% !important;
}
.connection-detail-parent .business-bottompart, .connection-detail-parent .business-bottompart .d-block {
  width: calc( 100% ) !important;
}
button.btn.btn.btn-blue.ignore .spinner-border {
  border-color: var(--btncolor);
}
.connection-detail-parent .business-bottompart button.btn.btn.btn-blue {
  max-width: 100% !important;
}
/* .help {
  border: 2px solid;
  border-radius: 100px;
  padding: 2px 16px;
  border-color: #2b688d;
  color: #2b688d !important;
  margin-right: 10px;
}
.help i {
  font-size: 16px;
} */
.btn-blue.business-btn {
  min-width: 200px;
  justify-content: center;
}
.btns-Userprf .btn{
  max-width: inherit;
}
.btns-Userprf{
  text-align: center;
}
.btns-Userprf p {
  display: inline;
}
.hj-hr {
  border-top: 2px solid #828282;
  Stroke: Solid#000000
}
.wrap-p{
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
button.btn.btn-blue.width-auto.businesscard .zoom svg {
  fill: #fff;
}
button.btn.btn-blue.width-auto.businesscard  , button.btn.btn-blue.width-auto.businesscard:hover{
  margin-top: 0 !important;
  background: #d33b27;
}
.wrap-p2 {
  justify-content: flex-start !important;
  display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.dirymeeting-time .ant-select .ant-select-selector, .dirymeeting-time .ant-select.ant-select-focused .ant-select-selector, .notiftime .ant-select .ant-select-selector, .notiftime .ant-select.ant-select-focused .ant-select-selector{
  border: 0px none !important;
}
.dirymeeting-time .ant-select .ant-select-selector{
  padding:0px !important;
}
.notifyMeetingList-wrp {
  border: 1px solid #b6dfff;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2px;
  border-radius: 3px;
  align-items: center;
}
.notiftime .notifyMeetingList-wrp:last-child{
  margin-bottom: 1.5rem !important;
}
.diary-meeting-form .notiftime .ant-input-number, .diary-meeting-form .notiftime .ant-select{
  padding: .3rem .5rem !important;
}
.diary-meeting-form .notiftime .ant-select{
  padding: .3rem 0rem !important;
}
.notiftime .col-md-3 {
  padding: 0px 6px;
  /* flex: 0 0 20.8333335%;
  max-width: 20.8333335%; */
}
.notiftime .col-md-3:last-child{
  padding-right: 15px;
}
.icn-wr .ant-picker-range-separator{
  line-height: 0px;
}
.meetingModal .ant-modal.cm_popup.signup_modal .ant-modal-content{
  background: #F1FDFF;
  border-color:#F1FDFF;
}
.diary-meeting-form .form-control:focus{
  background: transparent;
}
.dirymeeting-time .col-md-4{
  padding: 0px 6px;
}
.pl-md-15-mtng{
  padding-left: 15px !important;
}
.ntfy-atnd > label > svg, .desc-atnd > label > svg{
  width: 16px !important;
}
.ntfy-Listmet {
  width: calc(100% / 4);
  padding: 0.4rem 1rem;
  font-weight: 600;
}
.notiftime .ant-input-number-handler-wrap .ant-input-number-handler-up:before{
  background: url(../src/assets/images/up-caret.svg) no-repeat;
  top: 10px;
}
.notiftime .ant-input-number-handler-wrap .ant-input-number-handler-down:before{
  background: url(../src/assets/images/down-caret.svg) no-repeat;
}
.notiftime .ant-input-number-handler-wrap .ant-input-number-handler:before{
  width: 8px;
  content: '';
  position: absolute;
  height: 6px;
  right: 10px;
  background-size: 100%;
}
.notiftime .ant-input-number-handler-wrap .ant-input-number-handler .anticon{
  display: none;
}
.notiftime .ant-input-number-handler-wrap:hover .ant-input-number-handler{
  height: 50% !important;
}
.wrap-p1 {
  justify-content: flex-start !important;
  display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.match-found .img-wrapper img{
  width: 120px;
    height: 120px;
    border-radius: 100px;
    margin-right: 20px;
}
.match-found .btn-group button.btn{
  min-height: 40px !important;
    max-height: 40px !important;
    line-height: 40px !important;
    padding: 0px 16px !important;
    font-size: 14px !important;
    min-width: 120px !important;
    margin-left: 0px !important;
}
.match-found h2 {
  color: #2d688d;
  font-size: 26px;
}
.match-found h4 {
  font-size: 19px;
  font-weight: 700;
}
.sperkerdetails img {
  width: 70px;
  height: 70px;
  border-radius: 100px;
}
.brandfiltersearch span.ant-select-selection-search {
  width: 100% !important;
  display: block;
  padding: 6px 0px !important;
  margin-left: -14px;
}
.brandfiltersearch .ant-select-selector input.ant-select-selection-search-input {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.cm_multiselect.cmslect_multicheck.brandfiltersearch .ant-select-selection-placeholder{
  padding-left: 0px !important;
}
.connection-detail-parent button.btn.btn-blue.width-auto.businesscard, .connection-detail-parent button.btn.btn-blue.width-auto.businesscard:hover {
  margin-top: 0 !important;
  background: #d33b27;
}
.pading-top80 {
  padding-top: 80px;
}
.connection-detail-parent button.btn.btn-blue {
  min-width: auto !important;
  max-width: 100%;
}
.exhibitor-parent {
  background: #f5fdff;
  padding: -1px 0px 10px 0px;
  border-top: 1px solid #f3f3f3;
  text-align: center;
}
.exhibitor-parent a.btn.btn-red.addbtn.mobshow {
  min-width: 200px;
  width: 200px;
  margin: 0 auto !important;
}
span.pos-relative.cursor-point.notification, a.help.ant-dropdown-trigger, .header-wrapper .chat , a.fa.fa-search{
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #2b688e;
  border-radius: 100px;
  margin: 4px;
  position: relative;
}
a.help.ant-dropdown-trigger svg {
    width: 19px;
    height: 19px;
}
.small-view-btn, .small-view-btn:hover{
  background: var(--btncolor);
    color: #fff;
    width: 50px;
    border-radius: 8px;
    text-align: center;
    padding: 3px;
}
.header-wrapper .chat.showmobile991 {
  display: none !important;
}
.header_drop .text.showmobile991{
  text-align: center;
    font-weight: 600;
    border-bottom: 1px solid #eee;
}
p.text.showmobile991 {
  text-align: center;
  border-bottom: 1px solid whitesmoke;
  font-weight: 700;
}
/* ----------------profile table--------- */
ul.detail_row {
  display: inline-flex;
  padding-inline-start: 0;
  margin-bottom: 0;
  width: 100%;
}
.user-profile ul.detail_row li{
  width: 25%;
  min-width: 25%;


}
.enquiredetail_parents .user-profile ul.detail_row li{
  font-size: 1.3rem;
}
ul.detail_row li:nth-child(odd) {
  background: #92c9d2;
  color: #fff;
}
ul.detail_row li {
  width: calc( 100% / 4 );
  background: white;
  padding: 12px;
  border-bottom: 1px solid #ececec4f;
}
.change-pass .password-show {
  top: 50%;
}
.media-img-parent .or{
  background: #5383A2;
    height: 25px;
    width: 25px;
    color: var(--white);
    text-align: center;
    border-radius: 50%;
    line-height: 26px;
    font-size: 11px;
    letter-spacing: 1px;
    padding: 6px;
}
/* .showon-desktop{
  display: block;  
} */
.showon-mobile{
display: none !important;
}
/* ----------chatbox tooltip design-- */
.ant-tooltip.chatbox-tooltip {
  min-width: 305px;
}
.chatbox-tooltip .ant-tooltip-inner{
  background-color: var(--btncolor) !important;
  border: 1px solid #eae7e7;
  color: #000 !important;
  padding: 25px 12px;
}
.card-tooltip .profile-dp {
  width: 85px;
}
.card-tooltip .profile-dp img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 100px;
  margin: 0;
  margin-right: 15px;
}
.card-tooltip {
  display: flex;
  align-items: center;
}
.card-tooltip .profile-details {
  width: calc(100% - 85px);
}
.card-tooltip .profile-details .profile{
  color: var(--headercolor);
  word-break: break-word;
}
.tooltip-wrap{
text-transform: capitalize;
}
.tooltip-wrap p {
  margin-bottom: 8px;
    color: #000000;
    font-size: 18px;
    display: flex;
    align-items: flex-start;
}
.tooltip-wrap p i{
  font-size: 24px;
    color: var(--btncolor);
    margin-right: 8px;
    padding-top: 3px;
}
.card-tooltip .profile-details h6 {
  color: #000;
  font-weight: 800;
}

.card-tooltip .profile-details h5 {
  margin-bottom: 6px;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 23px;
  word-break: break-all;
}
.stage-banner .bottom-left .red-border > span {
  width: 100%;
  display: block;
  margin: 4px 0;
}
.header-wrapper .fa.fa-search:before {
  display: none;
}
.mobile_fav svg {
  width: 18px;
  height: 18px;
}
.mobile_fav .counter {
  position: absolute;
  background: #FF0000;
  width: 20px;
  height: 20px;
  text-align: center;
  color: #fff;
  border-radius: 100px;
  top: -6px;
  left: 17px;
  font-size: 11px;
  padding-top: 0px;
}
.counter-mob{
  position: absolute;
  background: #FF0000;
  width: 11px;
  height: 11px;
  text-align: center;
  color: #fff;
  border-radius: 100px;
  top: -1px;
  left: 26px;
  font-size: 11px;
  padding-top: 0px;
}
.container.video-call-popup {
  margin: 26px 0px 64px;
}
.container.video-call-popup h4 {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 29px;
}
.container.video-call-popup p{
  font-size: 16px;
  margin-bottom: 30px;
}
.container.video-call-popup a , .container.video-call-popup span{
  float: right;
    padding: 10px 0;
    color: #6290ff;
}
.search-result-parent .notify-row .profile-dp{
  position: relative
}
.search-result-parent .notify-row .chat {
  display: block !important;
  position: absolute;
    right: 0px;
    top: 47px;
    background: #d13a27;
    border: 1px solid #d13a27;
    display: flex !important;
}
.search-result-parent .notify-row .chat svg {
  width: 27px;
  height: 27px;
}
.showchatmobile{
  display: none;
}

.more-dropdown-parent {
  box-shadow: 0px 0px 4px #2d688d78;
}
.signup-mobilepopup .ant-modal-title {
  font-weight: 700;
  font-size: 22px;
}
.transparent-btn, .transparent-btn:active, .transparent-btn:hover {
  position: absolute;
  right: 0%;
  top: 11%;
  z-index: 1;
  background: #ff000000 !important;
  color: #fff0 !important;
  min-width: 394px;
  min-height: 105px;
  cursor: auto !important;
}
.signup-mobilepopup .ant-modal-content {
  max-height: 400px;
  overflow-y: scroll;
}
.thankulogo-show{
  display: block !important;
}
header .logo.thankulogo-show img {
  width: 155px;
}
.notify-row .message div{
  width: 100%;
}
.single-muteicon {
  display: block;
  position: absolute !important;
  height: 60px;
  top: 0px;
  text-align: revert;
  z-index: 9;
}
.home-evnt-wrp-bg{
  background: url(../src/assets/images/aesthetic/bg-evnt-am-wrp.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
}
.ant-dropdown.cstm-fltr-wrp-mdl{
  top: 0px !important;
  z-index: 1050;
}
.ant-modal-confirm-confirm.del-cstm-mdl .ant-modal-confirm-body > .anticon{
  color:var(--btncolor);
  margin-top: 3px;
}
.del-cstm-mdl .ant-modal-confirm-body .ant-modal-confirm-title{
  font-size: 18px;
  font-weight: 900;
}
.ent-am-strt-wrp {
  /* max-width: 66%; */
}
.ent-am-strt-wrp > h4{
  color: #333;
  font-size: 46px;
  font-weight: bold;
  font-family: "ivypresto-display",serif;
  margin-bottom: 0;
} 
.am-evnt-hdr-right .levebtn.top-lgn-btn {
  position: relative;
  top: 0px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.am-evnt-hdr-right li:not(:last-child){
  margin-right: 1.5rem;
}
.am-evnt-hdr-right .levebtn.top-lgn-btn  .btn {
  margin: 0;
  display: block;
  width: 100%;
  padding: 15px 24px;
  height: auto;
  min-height: inherit;
  line-height: normal;
  border-radius: 15px;
  text-transform: uppercase;
}
.countDown-wrapper {
  background: #F2B680;
}
.aesthetic .am-evnt-hdr-right .levebtn.top-lgn-btn  .btn{
  min-width: 168px;
}
.evnt-dt-wrp > h2 {
  font-weight: bolder;
  font-size: 3rem;
}
.evnt-dt-wrp > h2 > span {
  font-weight: 500;
}
.am-hme-caption > h2 {
  color: var(--white);
  font-size: 52px;
  font-weight: bold;
  margin-bottom: 0;
  font-family: "ivypresto-display",serif;
  text-shadow: 1px 1px 10px rgb(0 0 0 / 80%);
}
.lookingRecruit-wrap, .job-services-wrap, .queryMsg, .selectYour-Type {
  background: rgb(242 182 128 / 20%);
}
.looking-RecruitTop {
  display: flex;
  align-items: flex-end;
}
.looking-RecruitTop > img {
  max-width: 70%;
}
.looking-Torecuit, .jobComing-soon {
  background: #F2B680;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  margin: 0px 2rem 0px -2rem;
  padding: 2rem;
}
.looking-Torecuit > h4, .whatU-get h3 > span, .jobComing-soon > h3, .am-heading-new > h2 > span, .heading-5 > span {
  border-bottom: 5px solid var(--homeColor);
  padding-bottom: 0.5rem;
  font-family: "ivypresto-display",serif;
  font-size: 40px;
  font-weight: bold;
  color: var(--homeColor);
}
.am-heading-new.julyEvnt-top > h2 > span{
  font-size: 46px;
}
.looking-Torecuit > h4{
  font-size: 40px;
}
.tabs-scroll .animate-me{
  display: none !important;
}
.tabs-scroll .active-button{
  background: var(--btncolor);
}
.tabs-scroll > .btn{
  width: 100%  !important;
  position: relative;
}
.julyEvnt-top.am-heading-new > h3 {
  font-size: 26px;
  font-weight: normal;
}
.job-common-wrp.jobComing-soon > h3{
  color: var(--homeColor);
  font-size: 40px;
}
.julyEvnt-top {
  max-width: 75%;
  margin: 0 auto;
}
.looking-Torecuit > p, .jobComing-soon > p {
  font-size: 26px;
  color: #000;
  margin: 0;
  line-height: 32px;
}
.attendDtls > h4 {
  font-size: 32px;
  font-weight: 600;
  border-bottom: 1px solid #F2B680;
  padding-bottom: 0.6rem;
  font-family: "ivypresto-display",serif;
}
.job-common-wrp.jobComing-soon {
  margin-right: 0;
  margin-top: 0px;
  margin-left: -51px;
}
.job-topwrapper > .row {
  margin: 0;
}
.job-topwrapper > .row > .col-lg-3{
  padding: 0px 5px;
}
.job-topwrapper > .row > .col-lg-3:not(:last-child){
  flex: 0 0 22%;
  max-width: 22%;
}
.job-topwrapper > .row > .col-lg-3:last-child{
  flex: 0 0 34%;
  max-width: 34%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.job-topwrapper > .row > .col-lg-3:first-child > div {
  margin-left: -5px;
}
.attendDtls > p {
  font-size: 26px;
  line-height: 32px;
  margin-bottom: 0px;
}
.looking-Torecuit {
  margin-bottom: 2rem;
}
.lookingRecruit-Dtls {
  max-width: 80%;
  margin: 0 auto;
}
.lookingRecruit-Dtls > h4 {
  font-weight: bold;
  font-size: 40px;
  font-family: "ivypresto-display",serif;
  color: #000;
  margin-bottom: 1.6rem;
}
.whatU-get .row > .col-lg-3:nth-child(1) > .attendDtls, .whatU-get .row > .col-lg-3:nth-child(2) > .attendDtls, .whatU-get .row > .col-lg-3:nth-child(3) > .attendDtls, .whatU-get .row > .col-lg-3:nth-child(4) > .attendDtls{
  margin-bottom: 3.5rem !important;
}
.lookingRecruit-Dtls > p {
  font-size: 20px;
  line-height: 22px;
}
.lookingRecruit-detais-wrp .row > .col-md-6:not(:last-child) {
  border-right: 5px solid var(--homeColor);
}
.btn-com {
  background: #F25922 !important;
  border-radius: 15px;
  font-size: 13px !important;
  text-transform: uppercase;
  color: #fff !important;
  font-weight: 600 !important;
  padding: 11px 17px !important;
  min-width: 160px;
}
.hj.jobTemp-lndHome .btn.btn-red, .thnkYou-new-wrp .btn.btn-red{
  background: var(--homeColor);
}
.btn-com:hover{
  background: var(--btncolor) !important;
}
.scrollbar-tab .btn:hover{
  background: #F25922 !important;
}
.scrollbar-tab > ul > li:not(:last-child) {
  margin-right: 2rem;
}
.scrollbar-tab .btn {
  font-size: 13px !important;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 15px !important;
}
.julyEvnt-top > h2 > span, .heading-5 > span {
  color: #333;
  display: inline-block;
  padding: 0px 2rem 0.5rem;
}
.jbEnt-desc {
  padding-top: 1rem;
}
.jbEnt-desc > h4 {
  font-size: 40px;
  font-weight: bold;
  font-family: "ivypresto-display",serif;
  color: #000;
}
.jbEnt-desc > p {
  font-size: 20px;
  line-height: 22px;
}
.jbEnt-desc > h4 > span {
  font-size: 18px;
  font-weight: 400;
}
.job-event-detls .row > .col-md-4:not(:last-child) {
  border-right: 5px solid #F2B680;
}
.am-hme-caption {
  max-width: 700px;
  padding: 20rem 0 2rem;
}
.single-muteicon .circle-icon {
  margin: 12px 0 0 0;
  background: #d33b27;
  width: 25px;
  height: 25px;
  border-radius: 100px;
  position: relative;
  cursor: pointer;
}
.sendNott-cstm .ant-select-selection-search-input{
  padding: 0px  !important;
}
.ant-select-multiple.sendNott-cstm .ant-select-selection-search:first-child{
  margin-left: 0px;
}
.serach-root-popup.ant-dropdown{
  z-index: 9999;
}
.single-muteicon .bottom-svgicon svg {
  width: 12px;
  height: 12px;
}
body.webchatopen  {
  position: fixed;
  overflow-y: scroll;
}
/* body.notificationopen {
  position: fixed;
  overflow-y: scroll;
} */
body.webchatopen.conference {
  position: inherit;
  overflow-y: auto;
}
/* ------------chatbox tooltip--------- */
.notificationopen .chat-wrapper {
  width: 60px;
}
.notificationopen .chatbody-parent {
  width: 0;
}
.ant-dropdown.tooltipdropdown.ant-dropdown-placement-bottomLeft.ant-dropdown-hidde {
  left: 514px !important;
}
.__react_component_tooltip.show {
  opacity: 1 !important;
  
}
.__react_component_tooltip.show.type-dark , .__react_component_tooltip.place-top.show.type-dark {
  background: white !important;
  padding: 25px 12px;
  box-shadow: 0 0 4px #ddd9d9;
  min-width: 300px;
  max-width: 300px;
 
}
.__react_component_tooltip{
  z-index: 9999 !important;
}

.webchatopen .__react_component_tooltip.show.type-dark, .webchatopen .__react_component_tooltip.place-top.show.type-dark {
  left: calc(100% - 740px) !important;
}

.__react_component_tooltip.show.place-left.type-dark:after , 
.__react_component_tooltip.show.place-bottom.type-dark:after,
.__react_component_tooltip.show.place-top.type-dark:after,
.__react_component_tooltip.show.place-right.type-dark:after {
  border-left-color: red !important;
  display: none;
}
.__react_component_tooltip.show.place-top.type-dark:after{
  border-top-color: #fff !important;
}
.__react_component_tooltip.show.place-right.type-dark:after {
  border-right-color: #fff !important;
}
.__react_component_tooltip.show.place-bottom.type-dark:after{
  border-bottom-color: #fff !important;
}
/* ---------------admin pannel start----------- */
/* ----------------signup modal------------------ */
.ant-modal-wrap.signup_parent_modal {
  background: #355d7696;
}
.ant-modal-wrap.signup_parent_modal.transparent {
  background: transparent !important;
}
.meetingModal.ant-modal-wrap{
  z-index: 9999 !important;
}
.ntfy-atnd .ant-input-number-handler{
  border: 0px none !important;
}
.dirymeeting-time .ant-select.ant-select-single .ant-select-selector{
  height: auto;
}
.ant-select-dropdown, .ant-picker-dropdown{
  z-index: 99999 !important;
}
.cm_popup.signup_modal .ant-modal-content, .cm_popup.signup_modal .ant-modal-header {
  border-radius: 20px;
  background: #f1fdff;
  border: none;
}
.sign-modal-content .logo {
  text-align: center;
  position: relative;
}
.ant-modal.cm_popup.signup_modal {
  width: 650px !important;
  margin: 50px 0;
}
.ant-modal.cm_popup.signup_modal .ant-modal-content {
  padding-bottom: 20px;
  background: #fff;
}
.meetng-prsn-dry {
  display: flex;
}
.mtng-prsn-lft {
  display: flex;
  align-items: center;
}
.acpt-mtngD {
  margin-left: auto;
}
.sign-modal-content .heading-after-border .heart {
  background: #f1fdff;
}
.sign-modal-content .heading-after-border-center{
  height: 1px;
}
.sign-modal-content .heading-after-border .heart svg {
  width: 12px;
}
.sign-modal-content .splash-img {
  width: 166px;
  margin: 0 auto;
  opacity: 0;
}
.sign-modal-content .logo-img{
  position: absolute;
    width: 227px;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%)
}
.sign-modal-content h3.auth-heading.pos-relative.text-center {
  font-size: 35px;
}
.sign-modal-content.diary-meeting-modal-wrap h3.auth-heading.pos-relative.text-center{
  font-size: 27px;
}
.sign-modal-content .form-group h6 ,.sign-modal-content .form-group label{
  font-size: 13px;
  font-weight: 600;
}
.jobEvnt-cmn .jbIcon {
  display: block;
  min-height: 144px;
}
.password-show .fa-eye-slash {
  color: #2c688d;
}
.meetingModal .ant-modal.cm_popup.signup_modal{
  margin-top: 10px;
}
.diary-meeting-modal-wrap > .row{
  padding-bottom: 0px !important;
  padding-top: 10px !important;
}
.forgot-pass{
  text-align: right;
}
.forgot-pass a {
  color: #2c688d;
  font-size: 14px;
}
.sign-up p {
  font-size: 16px;
  color: #353747;
  font-weight: 700;
}
.linkedin .fa {
  color: #fff;
  font-size: 19px;
}
.linkedin {
  background: #377093;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  margin: 0 12px;
  padding-bottom: 3px;
}
.or_design {
  text-align: center;
    margin: 40px auto;
    width: 380px;
    height: 1px;
    background: #92c9d2;
    bottom: 0;
    position: relative;
}
.or_design span {
  background: #dff5f8;
  width: 66px;
  height: 66px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  color: #5a5a5a;
  font-weight: 700;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  border: 14px solid #f1fdff;
}
.signup-tab {
  display: flex;
  justify-content: center;
  position: relative;
}
.signup-step {
  background: #ffffff;
  width: 25px;
  height: 25px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9E9E9E;
  margin: 0 10px;
  font-weight: 700;
  z-index: 9;
  box-shadow: 0px 3px 5px #0000001f;
  /* margin-top: 6px */
}
.signup-step.active{
  width: 35px;
  height: 35px;
  background: #183F5D;
    color: #fff;
    box-shadow: none;
    border: 2px solid #183f5d;
    margin-top: -5px;
    position: relative;
}
.signup-step.active:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 11px solid transparent;
  border-top: 11px solid #183f5d;
  position: absolute;
  bottom: -7px;
}
.signup-step.done{
  width: 35px;
  height: 35px;
  background: #92c9d2;
  color: #fff;
  margin-top: -5px
}
/* .signup-step:after, .signup-step:before { */
  .Thing-line {
    background: #91c9d3;
    height: 1px;
    width: 95%;
    position: absolute;
    top: 42%;
    transform: translate(0px, -50%);
}
.Thing-line .heart {
  position: absolute;
  right: -12px;
  color: #2c688d;
  top: -10px;
  font-size: 12px;

}
.btn.sign-backbtn , .btn.sign-backbtn:hover {
  background: #92c9d2;
  color: #fff;
  border-radius: 100px;
  min-width: 100px;
  margin-top: -12px;
}
.Thing-line .heart svg{
  width: 13px;
    fill: #92c9d2;
}
.Thing-line .circle_bullet {
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background: #92c9d2;
  position: absolute;
  top: 50%;
  left: -8px;
  transform: translate(-0%, -50%);
}
.sign-modal-content .form-group.form-control {
  min-height: calc(2.4em + .75rem + 2px);
  height: auto;
  display: flex;
    align-items: center;
}
/* --------new home page deisng------ */
.features-card {
  /* background-color: #000;  */
  /* min-height: 300px; */
  /* max-width: 320px; */
  /* margin: 0 18px 20px; */
}
.features-card .speaker-profile-img {
  width: 100%;
  border-bottom: 5px solid #c13624;
}
.hj .features-card .card-body{
 padding: 1.25rem 6.25rem;
 padding-bottom: 0px;
}
.hj .features-card .card-body h5 {
  color: #000 !important;
}
.hj .features-card.onlineEvent-Spa .card-body{
  padding: 1rem 2.25rem;
}
.hj .features-card.onlineEvent-Spa .card-body h5, .exrt-shrt-desc > h4{
  margin-bottom: 0px;
  font-size: 23px;
  font-weight: 700;
  color: #fff !important;
}
.exrt-shrt-desc > p {
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 400;
  margin-top: 1.5rem;
}
.gain-idea-wrap .am-heading-new > h5{
  font-size: 17px;
}
.gain-idea-wrap .am-heading-new{
  margin-bottom: 1rem;
}
.am-heading-new > h2 {
  font-weight: bold;
  color: var(--btncolor);
  margin-bottom: 0.4rem;
}
.am-heading-new > h3 {
  font-size: 33px;
  font-weight: 600;
  color: #2C2A25;
  margin-bottom: 0.4rem;
}
.am-heading-new.julyEvnt-top > p {
  font-weight: bold;
  font-size: 26px;
}
.hj .features-card .speaker-profile-img {
  border-bottom: 0px;
}
.features-card .card-body h5 {
  font-size: 18px;
  font-weight: 300;
  text-transform: uppercase;
  min-height: 55px;
}
.light_blue{
  background-color: var(--home-bg);
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.hj .light_blue{
  background: #FBFAFA;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
}

.time-parent{
  background-color: var(--home-bg);
  padding-top: 40px !important;
  padding-bottom: 40px !important;
  
}
.netwrking-lng-wrp{
  padding:4rem 0px;
}
.time-parent h5{
  font-size: 18px;
    font-weight: 400;
    color:#fff;
}
.time-parent h3{
   font-size: 21px;
    font-weight: 600;
    margin: 12px 0;
    color:#fff;
}
.homebner-wrpper{
  background: url(../src/assets/images/aesthetic/count-down.jpeg );
  background-size: cover;
  height: 523px;
}
.homebner-wrpper > .container-fluid, .homebner-wrpper > .container-fluid > .row{
  height: 100%;
}
.sldr-descrp > h3 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 0;
}
.landpg-hdr-top ul {
  display: inline-block;
}
.landpg-hdr-top ul .btn.btn-red.addbtn{
  min-width: 142px !important;
}
.time-parent table tr td p {
  font-size: 16px;
  font-weight: 400;
  margin: 2px 20px;
}
.time-parent table tr td:first-child p {
  color: black;
  font-weight: 600;
}
.aesthetic .heading {
  font-size: 33px;
    font-weight: 600;
    text-align: center;
    margin: 10px 0 20px;
}
.leading-crd-cnt > h5 {
  font-size: 18px;
  font-weight: 300;
}
.aesthetic .sect-5 .heading{
  margin: 10px 0 10px;
  font-size: 36px;
}
.patient-care-erp {
  background: #d8eff2;
  margin-bottom: 30px;
  border-top: 8px solid #131337;
  height: calc( 100% - 30px);
}
.patnt-img {
  width: 86%;
  margin: -40px auto 0px auto;
}
.pant-dr-desc > h4 {
  font-size: 21px;
  font-weight: 600;
}
.pant-dr-desc > h5 {
  font-size: 18px;
  font-weight: 300;
}
.Home-banner-bg h3 {
  color: #1d1d1b;
}
.heading.red{
  color: #c13624;
}
.aesthetic .sub-heading {
  font-size: 21px;
  font-weight: 300;
  text-align: center;
  margin: 0;
}
.technique-parent {
  justify-content: center;
  margin: 80px 0 0px;
}
.hj .technique-parent {
  margin-top: 2rem;
}
.bx-shdw{
   box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
}
.expert-led-wrap{
  background:#FBFAFA;
}
.hj .sect-4, .leading-brands-wrp, .netwrking-lng-wrp {
  background: #d8eff2;
}
.technique {
  /* width: 320px; */
  background: #000;
  margin-bottom: 39px;
  border-top: 8px solid #c13624;
  text-align: center;
}
.onlineEvent-Spa, .exrt-shrt-desc > h4{
  background: var(--btncolor);
  height: 100%;
  border-radius: 0px 0px 20px 20px;
}

.hj .technique {
  /* width: 320px; */
  background: #d8eff2;
  border-radius: 0px 0px 20px 20px;
  border-top: 0px;
  margin-bottom: 10px;
}
.hj .technique-parent h6{
  font-size: 18px;
}
.technique div img, .technique img {
  width: 86%;
  margin: -60px 2% 0px 2%;
}

.hj .technique div img, .technique img {
  width: 100%;
  margin: 0px;

}
.technique h5 , .light_blue h3{
  color: #000;
}
.technique h4 {
  font-size: 23px;
  font-weight: 600;
  margin: 14px 0;
  color:#000;
}
.technique h5 {
  font-size: 18px !important;
    font-weight: 300 !important;
    padding: 18px 12px 20px 12px;
    min-height: 85px;
}
.hj .technique h5 {
  font-size: 19px !important;
    font-weight: bold !important;
    padding: 18px 12px 3px 12px;
    min-height: inherit;
    margin-bottom: 9px;
}
.fill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden
}
.fill img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%
}
.sect-4 ,.sect-6{
  margin: 40px 0;
}
.hj .sect-4 , .hj .sect-6{
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.sect-6 p {
  font-size: 18px;
  margin: 0px 0;
  font-weight: 400;
  color: #000;
}
.min-w100{
  min-width: 100%;
}
.front-footer-wrp {
  background: #d8eff2;
}
.frnt-top-footr h5 {
  font-size: 18px;
  font-weight: bolder;
}
.aesthetic .banner_container .btn {
  box-shadow: 0px 2px 9px #00000075;
}
.timer_parent{
  display: flex;
  justify-content: center;
}
.timer {
  width: 180px; 
  height: 180px;
  background: var(--btncolor);
  margin: 20px 2%;
  border-radius: 100px;
  display: flex;
  justify-content: space-around;
  /* box-shadow: 0px 0px 6px #000; */
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  border: 3px solid var(--btncolor);
  box-sizing: border-box;
}
.timer h1 {
  margin-bottom: 0;
  font-weight: bold;
  color: var(--white);
  font-size: 78px;
  font-family: "ivypresto-display",serif;
  line-height: 40px;
}
.timer p{
  margin-bottom: 0;
  font-weight: normal;
  color: #fff;
  line-height: 40px;
  font-size: 23px;
  padding-top: 1rem;
}
.thnkYou-new-wrp > .heading-5 > span{
  font-size: 46px;
}
.thanku {
  margin: 20px 0;
  max-width: 753px;
}
/* .thanku h1,.thanku h3,.thanku h6{
  font-
} */

.thanku h1 {
  font-weight: normal;
  /* font-size: 54px; */
  color: var(--btncolor);
  margin-bottom: 6px;
}
.thanku h6 {
  font-weight: 400;
  font-size: 26px;
}
.thanku h3 {
  font-weight: bold;
  font-size: 26px;
  margin-bottom: 1.5rem;
  color:#000;
}
.plan-feture .features-card{
  min-height: 338px;
  background: #fff;
}
.signup_modal .ant-modal-body {
  padding: 0;
}
.ant-modal.cm_popup.signup_modal .ant-modal-body .row.align-items-center {
  padding: 24px;
}
.popup_mid_test{
  position: absolute;
    width: 100%;
    top: 53%;
    color: #fff;
    padding: 0 12%;
}
.sign-backbtn.btn-on-img {
  position: absolute;
  top: 26px;
  left: 10px;
  box-shadow: 0px 2px 6px #00000029;
  background: #ABCADE;
}
.job-landing-header {
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  background: rgb(255 255 255 / 80%);
  padding: 0.8rem 0px;
  transition: all 0.4s ease;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
}
.job-landing-header.fixed-header:not(.top-RFheader){
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 999;
  background: #fff;
}
.job-landing-header.fixed-header:not(.top-RFheader) .am-logo-wrp img{
  /* max-width: 210px; */
}
.am-logo-wrp img{
  transition: all 0.4s ease;
}
.sign-modal-content .popup_mid_test .heading-after-border-center {
  height: 1px;
  background: #f1fdff;
}
.sign-modal-content .popup_mid_test .heading-after-border .heart {
  background: #8daab4;
}
.sign-modal-content .popup_mid_test .heading-after-border svg path {
  fill: #f1fdff;
}
.sign-modal-content {
  min-height: 500px;
}
.sign-modal-content.timetable-modal {
  min-height: 300px;
}
.cstmMdl-notification .sign-modal-content{
  min-height: inherit;
}
.levebtn.top-lgn-btn{
  top: 20px;
}
.notifcn-csmt-wrp > h3, .notifcn-csmt-wrp > hr, .notifcn-csmt-wrp > div, .serach-root-popup > div{
  display: none !important;
}
/* .btn.btn-red.addbtn.levebtn-regis {
  position: absolute;
  right: 84px;
  top: 62px;
}
.mac .btn.btn-red.addbtn.levebtn-regis{
  top: 80px;
} */
/* -----------edit profile page---------- */

.drysidebar-mtng-card.call_notification.menoti, .drysidebar-mtng-card {
  background: #42BAC9;
  color: #fff;
  border: 1px solid #42BAC9;
  border-radius: 15px;
  text-align: left;
  margin-bottom: 1.5rem;
  padding: 0px;
  overflow: hidden;
}
.meetingCard-whatKnow{
  background: #fff;
  color: #342E2E;
}
.mtng-dry-cnt > h4{
  color: #fff;
  font-size: 1.3rem;
}
.meetingCard-whatKnow .mtng-dry-cnt > h4{
  color: #342E2E;
}
.mtng-dry-cnt li.tmdry svg{
  fill: #fff;
}
.meetingCard-whatKnow .mtng-dry-cnt li.tmdry svg{
  fill: #42bac9;
}
.meetingCard-whatKnow .know-Metngbtn{
  margin-top: 5px;
}
.meetingCard-whatKnow .know-Metngbtn .btn{
  color: #fff;
  border-radius: 27px;
  font-weight: 700;
  font-size: 13px;
  padding: .4rem 2rem;
  border: 0px none;
}
.meetingCard-whatKnow .dryUsers{
  border-color: #fff;
}
.ant-picker-calendar-date-content .events{
  padding:0px;
}
.evnt-csmt-clndar .ant-picker-calendar-date-content{
  /* background: #eee;
  border-radius: 4px;
  padding: 0.2rem; */
}
.mtng-dry-cnt li{
  margin-bottom: 0.5rem;
}
.mtng-dry-cnt {
  width: calc(100% - 75px);
  padding-left: 0.35rem;
}
.dry-clnd-rgt-body.scrll-evnt-wrp .mtng-dry-cnt{
  width: calc(100% - 165px);
}
.dry-clnd-rgt-body.scrll-evnt-wrp .mtng-dry-icn svg{
  width: 50px;
}
.dry-clnd-rgt-body.scrll-evnt-wrp .mtng-dry-cnt li svg {
  max-width: 15px;
  margin-right: 0.2rem;
}
.dry-clnd-rgt-body.scrll-evnt-wrp .meeting-dry-crd-top{
  padding: 0.5rem 0.5rem;
}
.svg-20 svg{
  width: 20px !important;
}
.timezone-wrapper .css-yk16xz-control, .timezone-wrapper .css-1pahdxg-control{
  height: auto;
  padding: .15rem 1.04rem .15rem .5rem;
  font-size: 14px;
  background-color: #DDF7FA;
  border-color: #DDF7FA !important;
  box-shadow: initial;
}
.btn.disabled, .no-cursor{
  cursor: initial !important;
}

.css-tlfecz-indicatorContainer{
  padding: 7px 3px;
}
.dryUsers img {
  max-width: 100%;
  width: 100%;
  padding: 0px;
  height: 100%;
  object-fit: cover;
}
.dry-mtng-invt-users {
  display: flex;
  align-items: center;
}
.dryUsers:last-child {
  margin-left: -9px;
}
.dry-usLeft {
  display: flex;
  align-items: center;
}
.meeting-dry-crd-btm {
  background: #339BA8;
}
.meeting-dry-crd-btm li{
  width: calc(100% / 2);
  text-align: center;
  padding: 0.5rem 0.5rem;
}
.meeting-dry-crd-btm li .btn{
  border: 0px none;
  color: #fff;
  font-size: 11px;
  border-radius: 27px;
  padding: .4rem 2rem;
}
.meeting-dry-crd-btm li .btn-success{
  background: #42C966;
  border: 1px solid #5BE880;
}
.meeting-dry-crd-btm li .btn-danger{
  background: #FC0000;
  border: 1px solid #fff;
}
.know-Metngbtn .btn-danger{
  background: #FC0000;
}
.diary-meeting-form .form-control::-webkit-resizer, .datepicker-diary .ant-picker-suffix, .atdTimePicker .ant-picker-suffix{
  display: none;
}
.icn-wr {
  position: relative;
}
.icn-wr > svg {
  width: 20px;
  fill: #42bac9;
  position: absolute;
  left: 13px;
  top: 11px;
  z-index: 99;
}
.scrll-evnt-wrp {
  max-height: 553px;
  overflow: hidden;
  overflow-y: scroll;
}
.scrll-evnt-wrp:hover{
  /* padding-right: 8px; */
}
.scrll-evnt-wrp:hover::-webkit-scrollbar-track, .scrll-evnt-wrp:hover::-webkit-scrollbar{
  visibility: visible;
}
.scrll-evnt-wrp::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  visibility: hidden;
	background-color: #F5F5F5;
}
.meetName .form-control::placeholder{
  color: #dbdbdb;
}
.scrll-evnt-wrp::-webkit-scrollbar{
	width: 6px;
	background-color: #F5F5F5;
  display: none;
}

.scrll-evnt-wrp::-webkit-scrollbar-thumb{
	background-color: var(--btncolor);
}
.notiftime .ant-input-number-handler-wrap{
  opacity: 1;
  background: transparent;
  border: 0px none;
  top: 58%;
  transform: translateY(-50%);
  height: 40px;
}
.notiftime .ant-input-number-handler-wrap .ant-input-number-handler-down{
  border: 0px none;
}
.notiftime .ant-input-number-handler-wrap .ant-input-number-handler-down .anticon{
  /* width: 16px; */
}
.notiftime .ant-input-number-handler-wrap .ant-input-number-handler-down svg{
  fill: var(--btncolor);
}
.icn-wr .ant-picker{
  padding-left: 38px !important;
}
.drysidebar-mtng-card.chatMeetingCard .meeting-dry-crd-btm li .btn{
  font-size: 12px;
  padding: .25rem 0.75rem;
}
.drysidebar-mtng-card.chatMeetingCard .mtng-dry-icn svg{ 
  width: 45px;
}
.metToogle .toggle-parent{
  justify-content: center;
}
.metToogle .toggle-parent span {
  font-size: 1.2rem;
  font-weight: 600;
}
.drysidebar-mtng-card.chatMeetingCard .meeting-dry-crd-top{
  padding: 0.25rem;
}
.drysidebar-mtng-card.chatMeetingCard .meeting-dry-crd-top {
  display: block;
  flex-wrap: wrap;
  text-align: center;
}
.drysidebar-mtng-card.chatMeetingCard .mtng-dry-cnt > h4{
  font-size: 1rem;
}
.drysidebar-mtng-card.chatMeetingCard .dry-mtng-invt-users{
  justify-content: center;
}
.drysidebar-mtng-card.chatMeetingCard .mtng-dry-cnt {
  width: 100%;
  padding-left: 0;
}
.wtic {
  color: #fff !important;
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  border-radius: 50%;
  font-size: 9px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.2rem;
  vertical-align: middle;
}
.dry-top-sidebar {
  margin-bottom: 1rem;
}
.dry-top-sidebar > h4{
  margin: 0;
  font-size: 28px;
  font-weight: 600;
  color: var(--btncolor);
}
.dry-top-sidebar > a{
  color: #42BAC9;
  text-decoration: underline;
  font-size: 17px;
}
.invt-acceptd {
  margin-left: auto;
}
.invt-acceptd > span{
  font-size: 13px;
  font-weight: 600;
}
.meeting-dry-crd-btm li:not(:last-child) {
  border-right: 1px solid rgb(255 255 255 / 51%);
}
.cstm-tbl-calendar{
  background: #fff;
  box-shadow: 0px 13px 36px #42BAC91A;
  border: 1px solid #D0F1F5;
  border-top-color: #42BAC9;
}
.cstm-tbl-calendar table.table-bordered tr th{
  background: #F2FDFF;
  border-color: #42BAC9;
  text-align: center !important;
}
.cstm-tbl-calendar table.table-bordered tr:nth-child(odd) td{
  background: #fff;
}
.cstm-tbl-calendar .table-bordered td{
  border-color: rgb(66 186 201 / 45%);
  border-top: 0px none;
  border-bottom: 0px none;
  position: relative;
}
.cstm-tbl-calendar .table-bordered td:not(:first-child)::before{
  content: '';
  width: 100%;
  height: 1px;
  background: rgb(66 186 201 / 45%);
  position: absolute;
  left: 0;
  top: 10px;
}
.cstm-tbl-calendar .table-bordered td:first-child{
  vertical-align: top;
  padding-top: 0;
}
.cstm-tbl-calendar .table-bordered tr td:not(:first-child){
  padding: 0px;
}
.frstTimeD {
  font-size: 16px !important;
  color: #626262;
}
.evntDiary-box {
  padding: 0.9rem 0.75rem;
  margin-top: 17px;
  text-align: left;
  border-radius: 9px;
  color: #fff;
}
.evntDiary-box .dryUsers{
  border: 2px solid rgb(255 255 255 / 39%);
  width: 25px;
  height: 25px;
}
.evntDiary-box .dry-usLeft{
  margin-top: 4px;
}
.evntDiary-box .dryUsers img{
  width: 28px;
  height: 28px;
  margin-top: -3px;
}
.evntDiary-box > h5 {
  margin: 0;
  color: #fff;
  font-size: 17px;
}
.sesson-mtngBG{
  background: #E0FAFF;
  color: #42BAC9;
}
.sesson-mtngBG > h5{
  color: #4B4B4B;
}
.evntDiary-box.sesson-mtngBG .dryUsers{
  border-color: #E0FAFF;
}
.drTblH > strong{
  text-transform: uppercase;
}
.coming-evnt-dry > *{
  opacity: 0.36;
}

.drTblH > span, .drTblH > strong{
  display: block;
  text-align: center;
  color: #626262;
}
.crnt-evnt-dry > span{
  background: #42BAC9;
  color: #fff;
  display: inline-block;
  border-radius: 14px;
  padding: 0.2rem 0.5rem;
  font-weight: 400;
  line-height: normal;
}
.crnt-evnt-dry > strong{
  color: #626262;
  
}
.dryUsers {
  width: 36px;
  height: 36px;
  overflow: hidden;
  border: 2px solid #42BAC9;
  border-radius: 50%;
  text-align: center;
}
.mtng-dry-cnt li svg{
  max-width: 20px;
  margin-right: 0.5rem;
}
.mtng-dry-cnt li svg path{
  stroke: #fff
}
.meeting-dry-crd-top {
  padding: 1rem;
}
.meeting-dry-crd-top {
  display: flex;
  flex-wrap: wrap;
}
.heading-4 {
  position: relative;
  font-size: 34px;
  font-weight: 500;
  color: #000;
  padding-bottom: 0.3rem;
}
.heading-4:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  width: 15%;
  height: 1px;
  background: #969FAA;
  bottom: 0pc;
  margin: 0 auto;
}
.job-common-list {
  background: #fff;
  max-width: 70%;
  margin: 0 auto 1.5rem;
  border-radius: 5px;
  box-shadow: 0 0 3px rgb(0 0 0 / 5%);
  padding: 0.8rem;
}
.jb-cmn-topbar  h3 {
  font-size: 24px;
  font-weight: 500;
}
.jobprofile-right-wrp {
  display: flex;
}
.jobprofile-right-wrp .btn-jbcn:not(:last-child) {
  margin-right: 0.5rem;
}
.text-carr {
  font-size: 17px;
  padding-top: 0.4rem;
}
.jb-cmn-topbar {
  border-bottom: 1px solid rgba(150, 159, 170, 0.57);
  padding: 0px 1rem 0.5rem;
}
.upld-cv-btn img {
  width: 110px;
}
.upld-cv-btn > label{
  cursor: pointer;
}
.job-finder-page-temp .jb-btn, .job-finder-page-temp .jb-btn:focus, .btn.btn-primary.jb-btn, .btn.btn-primary.jb-btn:focus, .btn-cmn {
  border-radius: 5px !important;
  margin: 0px !important;
  border: 0px none !important;
}
.userCV > img {
  max-width: 69px;
}
.btn-cmn{
  background: var(--btncolor) !important;
  color: #fff !important;
  font-size: 19px !important;
  font-weight: 600 !important;
  padding: 12px 24px !important;
}
.btn-cmn:hover{
  opacity: 0.8;
}
.job-finder-page-temp .jb-btn:not(:last-child), .btn.btn-primary.jb-btn:not(:last-child) {
  margin-right: 1rem !important;
}
.job-frm-mdl .cm-form-wrapper{
  max-width: 100%;
}
.job-frm-mdl .cm-form-wrapper textarea.form-control{
  height: auto;
  border-radius: 10px;
}
.jobprofile-left-wrp {
  width: calc(100% - 50px);
}
.btn-jbcn {
  background: #000;
  border: 0px none;
  border-radius: 50%;
  color: #fff;
  width: 30px;
  height: 30px;
  font-size: 14px;
}
.jobprofile-list-wrp {
  position: relative;
  z-index: 9;
  padding-left: 2rem;
}
.job-dtl-crd > h4 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 2px;
}
.job-dtl-crd > ul > li {
  font-size: 15px;
  color: #47525E;
}
.jobprofile-list-wrp:after, .jobprofile-list-wrp:before{
  content: '';
  position: absolute;
  top: 24px;
}
.jobprofile-list-wrp:not(:last-child):after {
  left: 10px;
  width: 1px;
  height: 100%;
  z-index: -1;
  background: #969FAA;
}
.jobprofile-list-wrp:before{
  width: 20px;
  height: 20px;
  background: var(--btncolor);
  border-radius: 50%;
  left: 0;
  border: 4px double #fff;
}
.userprofile-tbs-wrp .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .subscrptn-body .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap{
  top: 0px;
  color: #969FAA !important;
  position: relative  !important;
  justify-content: center;
}
.subscrptn-body .ant-tabs-tab{
  margin: 0 !important;
  padding: 0;
}
.subscrptn-body .ant-tabs-tab .ant-tabs-tab-btn{
  background: #2D688D;
  color: #fff;
  padding: 0.8rem 2rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
  min-width: 224px;
  font-size: 0.9rem;
}
.subscrptn-body .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  background:#0D3D58;
}
.subscrptn-body .ant-tabs-content-holder{
  margin-top: 5px;
}
.pln-sub-tbl a{
  vertical-align: middle;
}
.adn-head{
  font-weight: 600;
  font-size: 0.9rem;
}
.pln-sub-tbl .toggle-parent{
  top: 3px;
  position: relative;
}
.add-sbs-Plan{
  display: none !important;
}
.add-sbs-Plan.payable{
  display: flex !important;
}
.pnls-bodywrp {
  background: #F5FDFF;
}
.manageSubsplans-wrp table.table-bordered tr:first-child th:first-child, .manageSubsplans-wrp, .manageSubsplans-wrp table.table-bordered tr:first-child th:last-child, .manageSubsplans-wrp .table.table-bordered tr:last-child td:last-child, .manageSubsplans-wrp table.table-bordered tr:last-child td:first-child{
  border-radius: initial !important;
}
.manageSubsplans-wrp table.table-bordered tr:first-child th:first-child{
  background: transparent;
  border: 0px none;
}
.table-responsive.manageSubsplans-wrp, .table-responsive.manageSubsplans-wrp>.table-bordered{
  box-shadow: initial;
  background: transparent;
}
.manageSubsplans-wrp thead > tr > th:not(:first-child), .manageSubsplans-wrp tbody > tr > td:not(:first-child){
  text-align: center !important;
}
.manageSubsplans-wrp thead > tr > th.mngPl-user{
  background:#828282;
}
.manageSubsplans-wrp thead > tr > th.mngPl-brnd{
  background: #E41B12;
}
.manageSubsplans-wrp thead > tr > th.mngPl-premi{
  background: #000;
}
.manageSubsplans-wrp thead > tr > th.mngPl-actn{
  background: #0D3D58;
}
.manageSubsplans-wrp tbody > tr > td > button{
  background: transparent;
  border: 0px none;
}
.manageSubsplans-wrp tbody > tr > td > button > svg{
  width: 20px;
}
.manageSubsplans-wrp tbody > tr > td:nth-child(2) > button > svg{
  fill: #828282;
}
.manageSubsplans-wrp tbody > tr > td:nth-child(3) > button > svg{
  fill: #E41B12;
}
.manageSubsplans-wrp tbody > tr > td:nth-child(4) > button > svg{
  fill:#000;
}
.manageSubsplans-wrp tbody > tr > td:nth-child(1){
  border-right-color:#828282 ;
}
.manageSubsplans-wrp tbody > tr > td:nth-child(2){
  border-right-color:#E41B12 ;
}
.manageSubsplans-wrp tbody > tr > td:nth-child(3){
  border-right-color:#000 ;
}
.manageSubsplans-wrp tbody > tr > td:nth-child(4){
  border-right-color:#0D3D58 ;
}
.manageSubsplans-wrp table.table-bordered tr td:first-child{
  border-left: 1px solid #828282;
}
.manageSubsplans-wrp table.table-bordered tr:nth-child(1) td:first-child{
  border-top: 1px solid #828282;
}
.manageSubsplans-wrp table.table-bordered tr td:last-child{
  border-right:1px solid #828282 ;
}
.manageSubsplans-wrp table.table-bordered tr td{
  border-bottom-color: #828282;
}
.mngEdit > svg {
  width: 15px !important;
  fill: var(--blue_bg) !important;
}
.mngEdit > svg {
  width: 15px !important;
}
.mngEdit {
  margin-left: auto;
}
.planTitle .form-control {
  width: calc(100% - 29px);
  display: inline-block;
  background: transparent;
  height: auto;
  vertical-align: middle;
  border: 0px none !important;
}
.planTitle .form-control:disabled{
  background: transparent;
}
.planTitle .form-control:focus{
  outline: none;
  border: 0px none;
}
.planTitle {
  /* display: flex; */
  align-items: center;
  flex-wrap: wrap;
}
.manageSubsplans-wrp thead > tr > th > h5{
  margin: 0px;
  color: #fff;
  font-weight: 600;
  font-size: 1.5rem;
  padding: 0.5rem;
}
table.table-bordered .pln-sub-tbl, table.table-bordered .tc{
  text-align: center !important;
}
.pln-sub-tbl .circle-serial > i{
  color: #8BC3CB;
  font-size: 13px;
}
.subscrptn-body {
  background: #ECFAFD;
  padding: 0 1rem 1rem;
}
.addNewPlans-tab {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.userprofile-tbs-wrp .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: var(--btncolor) !important;
}
.userprofile-tbs-wrp .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-tab-btn{
  font-size: 20px;
  font-weight: 600;
  padding: 0.5rem 1rem;
  position: relative;
}
.userprofile-tbs-wrp .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-tab-btn:after{
  content: '';
  position: absolute;
  bottom: 0px;
  width: 0;
  transition: all 0.3s;
  left: 0px;
  height: 3px;
  background:var(--btncolor);
}
.userprofile-tbs-wrp .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-tab-active .ant-tabs-tab-btn:after{
  width: 100%;
}
.without-sidenav .body_container .userprofile-tbs-wrp .header-text{
  margin-top: 0px !important;
}
.userprofile-tbs-wrp .ant-tabs-content-holder{
  margin-top: 0px;
}
.about-textarea .form-control {
  border: 0px none;
  appearance: none;
  color: #333;
  font-size: 18px;
}
.about-textarea .form-control:focus{
  border: 1px dotted #eee !important;
}
.file-name-cv, .userCV > span{
  color: var(--btncolor);
  font-size: 1.2rem;
}
.edit-tabular {
  border: 1px solid #ececec;
  border-radius: 10px;
  padding: 20px;
  margin: 0px 33px 33px;
  position: relative;
}
.edit-tabular .table{
  margin-bottom: 0;
}
.edit-tabular table.table.table-striped tr:nth-child(odd) td {
  border: none !important;
  border-radius: 7px;
  /* background: #e6fbff; */
  background: #f0f0f0;
}
.job-profile-temp {
  padding: 1.4rem;
}
.job-dtls-topbar > h4 {
  margin: 0;
  background: #e6fbff;
  padding: .75rem;
  line-height: normal;
  font-size: 16px;
  font-weight: 600;
  border-radius: 7px;
}
.job-dtls-bodyWrp {
  font-size: 16px;
  color: #47525E;
  padding: 1rem;
  font-weight: 500;
}
.edit-tabular .table td, .edit-tabular .table th {
  border-top: none;
}
.edit-tabular table.table.table-striped tr td h5 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
}
.edit-tabular table.table.table-striped tr:nth-child(even) td p {
  margin: 10px 0;
}
.edit-tabular .btn.btn-red , .edit-tabular .btn.btn-red:hover{
  position: absolute;
  right: 22px;
  bottom: -32px;
  min-width: 110px;
  background: var(--btncolor);
}
.profile-dtl ul.detail_row li {
  width: 50%;
  padding-left: 41px;
}
.profile-dtl ul.detail_row:nth-child(odd) li {
  background: #f0f0f0;
  color: #000;
}
.profile-dtl ul.detail_row:nth-child(even) li {
  background: #fff;
  color: #000;
}
/* .profile-dtl ul.detail_row:first-child {
  font-weight: 700;
} */
.profile-dtl ul.detail_row li:first-child {
  font-weight: 600;
}
.timer_parent {

  margin-top: 0px !important;

}
/* ----------------------new select------------------ */
span.css-1okebmr-indicatorSeparator {
  display: none;
}
.css-yk16xz-control, .css-1pahdxg-control {
  border-color: #9aabad !important;
  height: calc(2.4em + .75rem + 2px);
  border-radius: 5px !important;
  padding: .3rem 1.04rem;
  width: 100%;
  font-size: 16px;
}
/* ---------------tab---------- */
.home-tab .ant-tabs-nav-list {
  width: 100%;
  margin-bottom: 15px;
}
.home-tab .ant-tabs-nav-wrap {
  width: 100%;
  top: 0px !important
}
.home-tab .ant-tabs-tab{
  white-space: pre-wrap !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color:#fff;
 
}
.home-tab .ant-tabs-tab {
  width: calc( 100% / 3 );
  margin: 0 !important;
  padding: 21px !important;
  color: #fff;
  background: #0367A6;
  text-align: center;
}
.home-tab .ant-tabs-tab:nth-child(2) {
  background: #F25922;
  color: #fff !important;
}
.home-tab .ant-tabs-tab:nth-child(3) {
  background: #F2B680;
}
.home-tab .ant-tabs-tab .ant-tabs-tab-btn {
  color: #ffffff;
  font-weight: 600;
  margin: 0 auto;
  width: 100%;
}
.footer-widget.footAbot p, .footer-widget p {
  font-size: 12px;
  font-weight: 600;
  color: #000;
}
.socialMedia-wrp > ul > li > a {
  margin: 0;
  width: 40px;
  height: 40px;
  background: var(--btncolor);
  color: #fff;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  font-size: 1.2rem;
}
.socialMedia-wrp > ul > li > a:hover {
  background: #F25922;
}
.socialMedia-wrp > ul > li.tikTok > a > img{
   width: 18px !important;
}
.home-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 44%;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 11px solid transparent;
  border-top: 11px solid #183f5d;
  z-index: 9;
}

.home-tab .ant-tabs-tab:nth-child(1).ant-tabs-tab-active,.home-tab .ant-tabs-tab:nth-child(2).ant-tabs-tab-active {
  color: #fff !important;
}
.home-tab .ant-tabs-tab:nth-child(1).ant-tabs-tab-active .ant-tabs-tab-btn:after {
  border-top: 11px solid #0367A6;

}
.bg-red{
  background: #FF0000 !important;
  color: #fff !important;
}
.bg-black {
  background: #000000 !important;
  color: #fff !important;
}
.bg-blue{
  background: var(--btncolor) !important;
  color: #fff !important;
}

.home-tab .ant-tabs-tab:nth-child(2).ant-tabs-tab-active .ant-tabs-tab-btn:after {
  border-top: 11px solid #F25922;
}
.home-tab .ant-tabs-tab:nth-child(3).ant-tabs-tab-active .ant-tabs-tab-btn:after {
  border-top: 11px solid #F2B680 !important;
}
.home-tab .tablediv .first {
  width: 45%;
}
.home-tab .tablediv .Second {
  width: 55%;
}
.home-tab .ant-tabs-content-holder {
  margin-top: 0px !important;
}
.home-tab .ant-tabs-nav {
  margin-bottom: 0 !important;
}

.home-tab .plan-responsive .table-responsivee , .home-tab .table-responsivee{
  margin: 0px 0 !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
  font-weight: 600 !important;
}
.home-tab .header {
  border: none !important;
  margin: 0 !important;;
  padding: 2px 0 0 0 !important;;
}
.home-tab .table td, .home-tab .table th {
  /* border-radius: 0px; */
}
.ant-tabs.ant-tabs-top.home-tab .ant-tabs-nav, .home-tab .ant-tabs-content-holder {
  background: #fff;
}
.home-tab .table-bordered thead th , .home-tab .table-bordered thead td ,.home-tab .table-bordered {
  border: 0px solid #dee2e6;
}
.home-tab table.table-bordered tr td:first-child p {
  text-align: left;
  font-weight: 600;
}
.home-tab table.table-bordered tr td:nth-child(2) p {
  text-align: center;
}
.question-skip {
  position: absolute;
  color: #fff !important;
  border-radius: 100px !important;
  min-width: 100px;
  right: 33px;
  top: 14px;
  font-weight: 600 !important;
  text-decoration: underline;
  font-size: 18px !important;
}
.btn.btn-warning.btn-sm {
  background: var(--btncolor);
  border-color:var(--btncolor);
  color: #fff;
  border-radius: 100px;
  padding: 9px 20px;
  min-width: 100px;
}
.btn.btn-warning.btn-sm.changepass , .btn.btn-warning.btn-sm.changepass:active {
  background: #fff;
  border-color: var(--btncolor);
  color: var(--btncolor);

}
a.help.ant-dropdown-trigger.d-none.d-sm-block{
  position: relative;
}
a.help.ant-dropdown-trigger svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% ,-50%);
}
.action_group i svg path {
  fill: #2b688e;
}

.new-product .action_group i svg {
  width: 20px;
  transform: rotate(45deg);
  margin-right: 7px;
}
.new-product .wrap-p2{
  -webkit-line-clamp: 1 
}
.card.community-card.wrapper.cursor-point.new-product {
  /* min-height: 314px;
  max-height: 314px; */
}
.new-product .action_group h6:last-child , .new-product .action_group a:last-child {
  color: #2b688e;
  font-weight: 600;
}
.chat-on-table{
    background: #d33b27;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.chat-on-table svg{
  width: 20px;
    height: 20px;
}
.jobPlnSml {
  color: #fff;
  font-weight: 500;
  font-size: 15px;
}
.showmobile991.plan-responsive .jobPlnSml{
  color: #000;
}
.enquire_parents table.table.table-bordered.rounded-table.event-table tr th {
  text-align: left !important;
}
.enquire_parents table.table.table-bordered.rounded-table.event-table tr th, .enquire_parents table.table.table-bordered.rounded-table.event-table tr td {
  min-width: 100%;
}
.enquiredetail_parents ul.detail_row li{
width: 50%;
text-align: center;
}
.footer-area.footer--light{
  margin-top: 60px !important;
  border-top: 1px solid var(--btncolor);
  padding: 2.5rem 0px 3.3rem;
}
.landing-temp-wrp .footer-area.footer--light{
  
}
.jobTemp-lndHome .footer-area.footer--light, .thankyou-wrp-temp .footer-area.footer--light{
  border-top: 0px none;
  margin-top: 2rem !important;
}
/* --------febevent home-------- */
.eventifo-card .speaker-profile-img img {
  width: 144px;
}
.eventifo-card {
  border-right: 3px solid white;
}
.eventifo:last-child .eventifo-card {
  border-right: none;
}
.eventifo-card .card-body {
  padding: 0 20px;
}
.feature{
  margin: 8px 0;
}
.discover h3 , .discover h5{
  color:#fff;
}
.feature .features-card .card-body h5 {
  color: #fff;
}

.thankubanner{
  background-image: url(./assets/images/aesthetic/evnt-am-hd-bg.jpg);
  /* min-height: 68vh; */
  /* background-position: center center; */
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
}
.thankubanner .thankubanner-inner {
  display: inline-block;
}
.thnkutext{
  background: #fff;
    padding: 30px 0 !important;

}
.plan-feture .heading {
  color: #fff;
  margin-bottom: 20px;
}
.plan-feture .features-card .speaker-profile-img {
  border-bottom: none;
}
.plan-feture .features-card .card-body h5{
  color: #000;
}
.thankubanner h4 {
  color: var(--white) !important;
}
/* .aesthetic .container-fluid .btn, .aesthetic .container-fluid a.btn.btn-red,.aesthetic .container-fluid .btn:hover, .aesthetic .container-fluid a.btn.btn-red:hover {
  min-width: 228px;
} */
.checkbox_tab-body .form-group.form-control .check_wrapper.circle-checkbox {
  display: none !important;
}
.tablediv .Second .btn-parent button.btn.btn-red.btn-block {
  min-width: 100%;
}
.d-flex.edit-sectorcategory {
  flex-wrap: wrap;
}
.edit-sectorcategory .un_select_field {
  background: #d9eaed;
  border: 1px solid #c1d0d3;
  padding: 6px 22px;
  border-radius: 100px;
    margin: 0 10px 10px 0;
    cursor: pointer;
}
.edit-sectorcategory .un_select_field.select_field {
  background:#2c688d;
  color: #fff;
}
.asidebar{
  display: none;
  width: 100%;
    max-width: 100%;
    position: fixed;
    top: 0;
    min-height: 100vh;
    max-height: 100%;
    left: 0;
    z-index: 9999;
    background: #fff;
    padding-top: 0px;
    overflow-y: scroll;
   
}
aside.asidebar svg path {
  fill:#fff;
  stroke: white;
}
body.aside_open {
  overflow: hidden;
}
body.aside_open .asidebar{
  display: block;
}
.spredlove{
  margin-left: 40px;
}
.calenar-btn-view  .dry-evnt-btns > .btn{
  border: 1px solid var(--btncolor);
  font-size: 20px;
  padding: .5rem 2rem;
  font-weight: 600;
  color: #353747;
  position: relative;
}
.calenar-btn-view .btn:after{
  content: '';
  position: absolute;
  bottom: -15px;
  border: 7px solid transparent;
  left: calc(50% - 7px);
}
.calenar-btn-view .btn.active:after{
  border-top-color: var(--btncolor);
}
.calenar-btn-view .btn i{
  color: var(--btncolor);
  margin-right: 5px;
}
.meeting-accept-mdl li svg{
  width: 20px;
  margin-right: 14px;
}
.acpt-mtngD .btn {
  border-radius: 27px;
  font-size: 13px;
  padding: 0.25rem 1rem;
}
.drysidebar-mtng-card.chatMeetingCard {
  /* position: relative; */
  bottom: 91px;
  max-width: 300px;
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-bottom: 10px;
}
.meeting-accept-mdl li span{
  font-size: 16px;
}
.mtng-desc > span > strong{
  color: var(--btncolor);
}
.meeting-accept-mdl li{
  padding: 10px 0px;
}
.meeting-accept-mdl li:not(:last-child){
  border-bottom: 1px solid #eee;
}
.calenar-btn-view .btn.active{
  background: var(--btncolor);
  color: #fff;
}
.calenar-btn-view .btn.active > i{
  color: #fff;
}
.diary-events-wrap .event-tab{
  background: #fff;
  border: 1px solid var(--btncolor);
}
.diary-events-wrap .event-tab h3, .diary-events-wrap .event-tab h6{
  color: #333;
}
.diary-events-wrap .event-tab.active{
  background: var(--btncolor);
  border-color: transparent;
  color: #fff;
}
.diary-events-wrap .event-tab.active h3, .diary-events-wrap .event-tab.active h6{
  color: #fff;
}
.diary-events-wrap .event-tab.active:after{
  margin-top: -1px;
}
.evnt-diary-wrp .event-tab.active{
  background: var(--mainColor);
}
.evnt-diary-wrp .event-tab.active:after{
  border-top-color: var(--mainColor);
}
.diaryevnt-time h6{
  margin-bottom: 0.3rem;
}
.btm-time-btns .btn-comm{
  border: 0px none;
  background: var(--btncolor);
}
.btm-time-btns{
  padding-right: 14px;
  padding-top: 12px;
}
.btm-time-btns .btn{
  min-width: inherit;
  border-radius: 28px;
  margin-bottom: 5px;
  color: #fff;
  padding: .35rem 0.78rem !important;
  text-transform: inherit;
}
.btm-time-btns .btn-com{
  border: 0px none;
}
.btm-time-btns .btn > i{
  color: #fff;
}
.commuty-dropdown-lst > p{
  display: none !important;
}
.rbc-events-container .rbc-event{
  display: flex;
  flex-wrap: wrap;
}
.rbc-events-container .rbc-event .rbc-event-label{
  font-size: 10px;
  white-space:  normal;
}
.rbc-events-container .rbc-event .rbc-event-content{
  font-size: 16px;
}
.calnder-view-wrps .rbc-day-slot .rbc-event{
  border: 0px none;
  padding: 5px 6px;
}
.calnder-view-wrps .rbc-day-slot .rbc-event:nth-child(n){
  background:#938AFE;
}
.calnder-view-wrps .rbc-day-slot .rbc-event:nth-child(n+1){
  background: #938AFE;
}
.calnder-view-wrps .rbc-day-slot .rbc-event:nth-child(2n+1){
  background: #FD6E6E;
}
.calnder-view-wrps .rbc-day-slot .rbc-event:nth-child(3n){
  background: var(--btncolor)
}
.calnder-view-wrps .rbc-day-slot .rbc-event:nth-child(3n){
  background: #42BAC9
}
.calnder-view-wrps .rbc-day-slot .rbc-event:nth-child(4n){
  background:#df3737;
}


/* Menu sidebar */
.menu-wrapper-admin-sidebar.ant-menu{
  background: transparent;
}
.menu-wrapper-admin-sidebar.ant-menu .ant-menu-submenu .ant-menu-submenu-title{
  height: auto;
  border-top: 1px solid #245F83;
  padding: 16px 15px !important;
  margin: 0px;
}
.menu-wrapper-admin-sidebar.ant-menu .ant-menu-submenu .ant-menu-submenu-title svg, .menu-wrapper-admin-sidebar.ant-menu .ant-menu-submenu .ant-menu-item svg{
  width: 26px;
  height: 26px;
  fill: #fff;
  margin-right: 15px;
}
.menu-wrapper-admin-sidebar .ant-menu-submenu-expand-icon, .menu-wrapper-admin-sidebar .ant-menu-submenu-arrow, .menu-wrapper-admin-sidebar .ant-menu-submenu:hover .ant-menu-submenu-arrow{
  color: #fff !important;
}
.menu-wrapper-admin-sidebar.ant-menu .ant-menu-submenu .ant-menu-item svg{
  width: 20px;
  height: 20px;
  margin-right: 7px;
}
.menu-wrapper-admin-sidebar.ant-menu .ant-menu-sub.ant-menu-inline{
  background: transparent;
}
.menu-wrapper-admin-sidebar.ant-menu .ant-menu-item{
  padding: 0px !important;
  height: auto;
  margin: 0px !important;
}
.menu-wrapper-admin-sidebar.ant-menu .ant-menu-item.ant-menu-item-only-child{
  display: block;
  height: auto;
}
.menu-wrapper-admin-sidebar.ant-menu .ant-menu-submenu .ant-menu-item a{
  display: block;
  padding: 6px 15px 6px 25px;
  color: #fff;
  font-size: 16px;
  font-weight: 900;
}
.menu-wrapper-admin-sidebar.ant-menu .ant-menu-submenu .ant-menu-item:not(:last-child) a{
  border-bottom: 1px dashed rgb(255 255 255 / 10%);
}
.menu-wrapper-admin-sidebar.ant-menu .ant-menu-submenu .ant-menu-submenu-title .ant-menu-title-content{
  color: #fff;
  font-weight: 900;
  margin: 0px;
  font-size: 18px;
}
.menu-wrapper-admin-sidebar .ant-menu-item:active, .menu-wrapper-admin-sidebar .ant-menu-submenu-title:active{
  background: transparent;
}
.menu-wrapper-admin-sidebar.ant-menu{
  box-shadow: inherit;
}
.menu-wrapper-admin-sidebar.ant-menu .ant-menu-submenu{
  outline: none !important;
}
/* .prfsnl-UserDtls  {
  color: rgb(0 0 0 / 45%);
} */
.text-mtd {
  color: #bcbcbc;
}
/* Menu sidebar End */

.event-tabbody .diary-evnt-dtls h2{
  font-size: 22px;
  color: #101010;
}
.event-tabbody .diary-evnt-dtls h2 small{
  margin-left: 0px;
}
.diary-evnt-dtls .speaker-details{
  padding: 3.5px 0px;
}
.diry-evts-title {
  display: flex;
  padding-right: 0.5rem;
}
.diary-evnt-spkr{padding-right: 0.5rem;}
.sign-modal-content .diary-meeting-form label{
  font-size: 1rem;
  font-weight: 500;
}
.diary-meeting-form .form-group{
  margin-bottom: 1.35rem;
}
.notiftime .ant-input-number, .notiftime .ant-select, .dirymeeting-time .ant-picker{
  background: #DDF7FA;
  border-color: #DDF7FA !important;
}
.dirymeeting-time .datepicker-diary .ant-picker{
  padding-right: 5px !important;
}
.time-pcker-wrap {
  display: flex;
  align-items: center;
  width: 100%;
}
.time-pcker-wrap .ant-picker{
  padding-right: 8px !important;
}
.time-pcker-wrap > .icn-wr{
  padding: 0px 2px;
}
.dirymeeting-time .ant-picker-clear, .time-pcker-wrap .ant-picker-suffix{
  display: none !important;
}
.diary-meeting-form .ant-picker-input > input{
  color: #353747;
  font-weight: 600;
}
.meetName .form-control{
  background: transparent;
  font-size: 1.3rem;
  font-weight: 600;
  color: #353747;
  border: 0px none;
  border-bottom: 1px solid #9de8ec;
  border-radius: inherit;
  padding-top: 0px;
  overflow: hidden;
  line-height: 20px;
  resize: both;
}
.desc-atnd .form-control{
  border: 1px solid #DDF7FA;
  /* border-bottom: 1px solid #9de8ec; */
  border-radius: initial;
  min-height: 40px;
  background: #fff;
  height: auto;
  line-height: normal;
}
.sign-modal-content.diary-meeting-modal-wrap {
  min-height: inherit;
}
.desc-atnd .form-control:focus{
  border: 1px solid #DDF7FA !important;
  background: #fff;
}
.sign-modal-content .diary-meeting-form .meetName label, .sign-modal-content .desc-atnd label{
  margin-bottom: 4px;
}
.meeting-type .btn {
  background: #D1EBFF;
  font-size: 0.9rem;
  font-weight: bold;
  border-radius: 5px;
  padding: 0.5rem 1.5rem;
  display: inline-flex;
    align-items: center;
    justify-content: center;
}
.label-icn label{
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.label-icn label svg{
  margin-right: 5px;
}
.diarypeople-invited {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.dry-pl-img img {
  width: 46px;
  height: 46px;
  overflow: hidden;
  border-radius: 50%;
  object-fit: cover;
}
.dry-pl-desc > h4 {
  font-size: 15px;
  margin-bottom: 0;
  font-weight: 600;
}
.dry-pl-desc > span {
  color: var(--btncolor);
}
.dry-pl-desc {
  padding-left: 0.5rem;
}
.diarypeoplelist {
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #DDF7FA;
  border-radius: 43px;
  padding: 0.25rem 0.78rem;
  min-width: 257px;
}
.diarypeople-invited .diarypeoplelist:not(:last-child){
  margin-right: 1rem;
}
.dry-pl-desc > h4 {
  font-size: 15px;
  margin-bottom: 0;
  font-weight: 600;
}
.dry-pl-desc > span {
  color: var(--btncolor);
}
.label-icn label svg {
  width: 25px;
}
.mtng-atnd label svg path{
  fill: #42bac9;
}
.dirymeeting-time .row, .notiftime > .row{
  align-items: center;
}
.clse-ntfy-icn .btn {
  padding-left: 0;
}
.add-notity-btn .btn{
  font-size: 13px;
  font-weight: 600;
  padding: .375rem .25rem .375rem .05rem !important;
}
.add-notity-btn .btn:hover{
  background: #DDF7FA !important;
}
.dirymeeting-time > .row{
  align-items: initial;
}
.btn-gray{
    background: #e8e8e8 !important;
    border-radius: 5px !important;
    color: #353747 !important;
    padding: .5rem .75rem !important;
}
.meeting-type .btn.active{
  color: #fff;
  background: var(--btncolor);
}
.meeting-type .btn.active svg path{
  fill: #fff;
}
.meeting-type .btn svg{
  width: 25px;
  margin-right: 7px;
}
.meetName .form-control:focus{
  border: 0px none !important;
  border-bottom: 1px solid #9de8ec !important;
}

.topbar-diary-icn{
  /* position: absolute;
  top: 8px;
  right: 8px; */
  margin-left: auto;
}
.topbar-diary-icn .btn, .mtng-dry-cnt-right-button .btn{
  padding: 0px !important;
  width: 35px !important;
  height: 35px !important;
  background: var(--btncolor);
  border-radius: 50%;
}
.topbar-diary-icn .btn svg, .mtng-dry-cnt-right-button .btn svg{
  width: 15px;
}
.btm-diaryevnt-btns .btn{
  border-radius: 15px;
  color: #fff;
  font-size: 12px;
  padding: .25rem 1.35rem;
}
.btm-diaryevnt-btns .btn.btn-outline-warning{
  color: #ffc107 !important;
}
.btm-diaryevnt-btns .btn.btn-outline-warning:hover{
  color: #fff !important;
}
.invt-acceptd.text-success{
  color: #5bff81 !important;
}
.invt-acceptd.text-success .wtic{
  color: #5bff81 !important;
  border-color: #5bff81;
}
.invt-acceptd.text-warning{
  color: #ffd149 !important;
}
.invt-acceptd.text-warning .wtic{
  color: #ffd149!important;
  border-color: #ffd149;
}
.invt-acceptd.text-primary{
  color: #0d3560 !important;
}
.invt-acceptd.text-primary .wtic{
  color: #0d3560 !important;
  border-color: #0d3560 ;
}
.invt-acceptd.text-danger .wtic{
  color: #dc3545!important;
  border-color: #dc3545;
}
.btm-diaryevnt-btns .dbtn{
  border-color: transparent;
}
.dry-clnd-rgt-sidebar .invt-acceptd{
  /* background: #fff;
  line-height: normal;
  padding: 0.10rem 0.25rem;
  border-radius: 11px;
  min-width: 82px;
  text-align: center; */
}
.dry-clnd-rgt-sidebar .invt-acceptd > span{
  font-size: 12px;
}
.btm-diaryevnt-btns .btn-outline-success{
  border-color: #28a745;
  color: #28a745;
}
.btm-diaryevnt-btns .btn-outline-secondary{
  background: #D1D1D1;
  border-color: transparent;
}
.btm-diaryevnt-btns .btn-outline-info{
  background: #17a2b8;
  border-color: transparent;
} 
.diary-evnt-spkr .speaker-details .profile{
  cursor: inherit;
}
.diary-evnt-spkr .speaker-details .profile .text p{
  color: #000 !important;
}
.btm-diaryevnt-btns .btn-outline-success:hover {
  color: #fff;
}
.urs{
  fill: #264461;
}
.meeting-accept-mdl .urs{
  fill: var(--btncolor);
}
.meeting-accept-mdl .place-mtng svg, .meeting-accept-mdl .smList svg path{
  fill: var(--btncolor);
}
.btm-diaryevnt-btns .chat{
  background: var(--btncolor);
  width: 34px;
  height: 34px;
  display: block;
  text-align: center;
  line-height: 34px;
  border-radius: 50%;
}
.btm-diaryevnt-btns .chat svg{
  width: 18px;
}
.meeting-accept-mdal .btn.btn-blue{
  max-width: 100%;
  padding: 15px 20px;
}
.meeting-diary-mld-popup.ant-modal.cm_popup.signup_modal{
  width: 500px !important;
}
.meeting-diary-mld-popup .sign-modal-content{
  min-height: inherit;
}
.meeting-accept-mdal .auth-heading{
  font-size: 25px;
}
.meeting-accept-mdal .meetingName.auth-heading{
  font-size: 18px;
    background: #eee;
    padding: 0.5rem 1rem 0.5rem 0.75rem;
    border-left: 3px solid var(--mainColor);
}
.meeting-diary-mld-popup .ant-modal-close{
  display: none !important;
}
.diary-evnt-spkr .row{
  align-items: flex-end;
}
.topbar-diary-icn .btn svg, .topbar-diary-icn .btn svg path, .mtng-dry-cnt-right-button .btn svg path{
  fill: #fff;
}
.event-tabbody .event-detail.diary-evnt-dtls{
  width: calc(100% - 16%);
}
.event-tabbody .diaryevnt-time button svg path{
  fill: transparent;
}
.ant-select.cm_multiselect.cmslect_multicheck.cm_simpleselect.ant-select-multiple.ant-select-show-search .ant-select-selector {
  min-height: 37px !important;
  border-radius: 100px !important;
  border-color: #fff !important;
  background: transparent !important;
  min-width: 153px !important;
  margin-right: 20px !important;
  max-height: 37px !important;
}
.Home-banner-bg.home-coming-soon {
  min-height: 85vh;
  background: #1c2a3b;
}
section.Home-banner-bg.home-coming-soon .thankyou-text {
  padding-top: 50px;
}
.downloadbtn span {
  background:var(--btncolor);
  padding: 12px 23px;
  border-radius: 100px;
  color: #fff;
}
.offer-view table th span svg {
  width: 20px;
  height: 20px;
  fill: #fff;
  margin-right: 10px;
}
.offer-view .community-card img{
  border-radius: 4%; 
  width: 100%;
}
/* ----------speaker details-------- */

.spaker-detail-popup .profile img {
  width: 180px;
  height: 200px;
  margin: 0 auto;
  border: 3px solid var(--blue_bg);
  box-shadow: 0px 0px 4px #003872;
  border-radius: 24px;
}
.spaker-detail-popup .details h3 {
  font-size: 33px;
  margin: 12px;
  text-transform: capitalize;
  font-family: 'Henriette Italic';
}
.spaker-detail-popup .details h6 {
  font-size: 20px;
  margin: 12px;
  text-transform: capitalize;
  color: #92c9d2;
}
.spaker-detail-popup .details p {
  margin: 12px;
    color: #000;
}

body.conference.webchatopen {
  width: calc( 100% - 426px);
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  }
  .search_wrapper.search-type {
    min-height: 50px;
    /* line-height: 50px; */
    min-width: 90%;
    max-width: 90%;
    margin-left: 1.5%;
    margin: 0 auto;
    display: block;
}
.search_wrapper.search-type:focus {
  outline: none;
}
.whiteoption span.ant-select-selection-item {
  color: #fff !important;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px !important;
}
.downloadbtn span{
  background: var(--btncolor);
  padding: 12px 23px;
  border-radius: 100px;
  color: #fff;
}
.brandhome .ant-tabs-tab:hover{
  color: var(--btncolor);
}
.nav-item.homeicon {
  font-size: 22px;
}
.nav-wrapper li.nav-item.homeicon {
  padding: 7px 0px;
}
.card.brand-card .brand-img-wrapper img {
  object-fit: initial !important;
}
.srch-chat-icn .chat, .srch-list-rslt .notify-cht .chat{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  border: 0px none;
  line-height: 28px;
  display: inline-block;
}
.srch-chat-icn .chat svg, .srch-list-rslt .notify-cht .chat svg{
  width: 17px;
}
.srch-chat-icn .chat.yellow, .srch-list-rslt .notify-cht .chat.yellow{
  background: #F4E60B;
}
.srch-chat-icn .chat.green, .srch-list-rslt .notify-cht .chat.green{
  background:#4caf50;
}
.enqirechat-p{
  display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.enqirechat svg{
  width: 30px;
  height: 30px;
}
.enqirechat svg path {
  fill: #92c9d2;
}
.btn.btn-lg.btn-primary.btn-blue.btn-block.confebtn{
  min-width: 260px !important;
  max-width: 260px !important;
}
.passowrdtext{
  font-size: 12px;
    word-break: break-all;
}
.four-div.conference-lounges .offer-badge {
  left: -10px;
}

/* ------------------radio checkbox file upload end-------------------- */
@media (min-width:320px) and (max-width:480px){
  .technique-parent .technique {
    margin-top: 60px;
}
  .home-tab .plan-responsive .table-responsive{
    margin: 0px 0 !important;
  }
  .home-tab .ant-tabs-tab {
    padding: 20px 0px !important;
    /* font-size: 12px; */
    text-align: center;
  }

.timer h1{
font-size: 20px;
  font-weight: 600;

}
.timer p{
font-size: 11px;
}
  .time-parent table tr td p {
    font-size: 14px;
    margin: 3px 0px;
  }
  .profile-body .profile-icon-wrapper {
    margin-top: 100px !important;
    padding: 6px 10px !important;
}
.profile-body.profile-dtl .profile-icon-wrapper {
  margin-top: 15px !important;
  padding: 6px 10px !important;
}

 .btn.btn-red.addbtn.levebtn{
  padding: 6px 10px !important;
    min-height: 32px !important;
    line-height: 20px !important;
}
.small-container-bg h1 {
    min-height: auto;
}
  .or_design {
    width: 196px;
  }
  .popup_mid_test {
    font-size: 15px;
    padding: 0 2%;
}
  .sign-modal-content .form-group.sign-up.d-flex.justify-content-center.align-items-center.mb-4 {
    flex-wrap: wrap;
}
.Thing-line {
  width: 100%;
}
.signup-step {
  width: 21px;
  height: 21px;
  margin: 0 4px;
  font-size: 10px;
}
.signup-step.done{
  width: 21px;
    height: 21px;
    margin-top: 0px;
}
.signup-step.active {
width: 21px;
height: 21px;
margin-top: 0px;
}
.signup-step.active:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #183f5d;
  bottom: -4px;
}
.popup_mid_test {
  top: 45%;
}
.viewbrandshowcse{
  display: flex;
  flex-wrap: wrap;
}
.viewbrandshowcse .btn btn-blue{
  margin-top: 10px;
}
span.pos-relative.cursor-point.notification, a.help.ant-dropdown-trigger, .header-wrapper .chat, a.fa.fa-search {
  width: 38px;
  height: 38px;
}
.profile img {
  width: 40px !important;
  height: 40px !important;
}

}
@media (min-width:481px) and (max-width:580px){

  .timer {
    width: 90px;
    height: 90px;
    margin: 4px 1%;
  }
  .timer h1{
    font-size: 26px;
        font-weight: 600;
    }
    .timer p{
    font-size: 15px;
    }
  .signup-step.done , .signup-step.active{
    margin: 0 3px;
}
.time-parent table tr td p {
  font-size: 14px;
  margin: 3px 0px;
}
}

@media (min-width:300px) and (max-width:767px){
  .thankubanner .hj-logo {
    width: 50%;
  }
  .patient-care-erp{
    margin-bottom: 68px;
    height: auto;
  }
  .logo-wrper {
    text-align: center;
}
  .leading-crd {
    margin-bottom: 2rem;
}
.frnt-btm-ftr .col-md-3 {
  margin: 1rem 0px;
}
   .thankubanner {
    background: #f5f5f5;
    padding-bottom: 1rem;
  }
  .thankubanner .thankubanner-inner {
    width: 100%;
    padding-top: 1vh !important;
  }
  .timer_parent {
  justify-content: center;
  padding-left: 15px !important;
  }
  .hj .banner_container .btn.btn-red {
    min-width: 70px !important;
    min-height: 25px !important;
    max-height: 25px !important;
    line-height: 22px !important;
    padding: 0px 15px !important;
    font-size: 10px !important;
    display: block;
  }
  .btn.btn-red.addbtn.levebtn, .hj .levebtn{

    /* line-height: 25px !important; */
    margin-top: 0px;
    position: absolute;
    right: 0;
    top: 0;
    display: inline-block !important
  }

  .spaker-detail-popup .profile img {
    width: 180px !important;
    height: 200px !important;
  }
  .speaker-details .profile {
    width: 207px;
    justify-content: left;
    margin: 10px auto;
    text-align: left;
    align-items: flex-start;
}
.event-detail .speaker-details img {
  min-width: 45px;
  max-width: 45px;
  height: 45px !important;
}
  section.Home-banner-bg.home-coming-soon {
    min-height: 100vh;
}
  .profile-dtl .self-detail .btn {
    min-width: 200px;
}
  .new-confrence-height .card-img-wrapper .card img {
    min-height: 420px;
    object-fit: cover;
}
  .timer_parent {
    margin: 0 0 !important;
    justify-content: center;
}
  .payment-sucess-screen .update-password {
    width: 100%;
}
  .user-profile ul.detail_row li {
    width: 50%;
    min-width: 50%;
   }
  .thanku {
    width: 100%;
}
  .chat_open_mob {
    overflow: hidden !important;
}
.ant-modal.cm_popup.signup_modal {
  width: 92% !important;
}
  .search-result-parent .notify-row .chat svg {
    width: 12px;
    height: 12px;
    top: 50%;
    left: 0%;
    transform: translate(-110%, -50%);
}
.search-result-parent .notify-row .chat svg path{
  fill: #fff;
}
.ant-dropdown.serach-root-popup, .ant-dropdown.serach-root-popup.ant-dropdown-placement-bottomRight {
    width: 100% !important;
    top: 139px !important;
}
.chat-header .counter.chat-counter.white-counter {
  color: #d13a27 !important;
  border: transparent;
}
  .circle-icon.sharescreen {
    display: none;
}
.profile-body .self-detail p a {
  margin: 14px 0;
}
.profile-body .self-detail p button{
  width: 100%;
}
  .confreance-detail-parent .image-banner , .confreance-detail-parent .image-banner img {
    min-height: auto;
    max-height: auto;
}
.header-notification{
  box-shadow: 0px 8px 9px #2b688ee0 !important;
  }

  .profile-body .pading-top80 {
    padding-top: 0px;
}
.body_container.profile-body .pading-top80 .card-wrapper {
    padding-top: 0 !important;
}
.body_container.profile-body .pading-top80 .header-text {
  margin: 25px 0 25px!important;
} 
.body_container.profile-body .pading-top80 .table-wrapper {
  padding: 0px 20px;
}
.body_container.profile-body .pading-top80 .header-text .d-flex{
  margin-bottom: 0px;
}
.profile img {
  width: 45px;
  height: 45px;
}
  .header-search {
    min-width: 100%;
  }
  ul.detail_row {
    flex-wrap: wrap;
}
  ul.detail_row li {
    width: calc( 100% / 2 );
  }
.search-filter-responsive{
  justify-content: center;
}
.ml-3.header_drop{
  margin-left: 0rem!important;
}
/* .search-filter-responsive .search_wrapper {
  min-width: 100% !important;
  max-width: 100% !important;
  width: 100% !important;
} */
  .ant-modal.cm_popup.term-services .ant-modal-content {
    width: 100%;
}
/* .chat_open_mob .showchat .chatbody-parent , .chat_open_mob .chatbody-parent{
  display: block;
  width: calc(100% - 67px ) !important;
}
.wrap-chat-header {
  width: calc(100% - 67px );
}
.chat_open_mob .bottom {
  width: calc(100% - 67px ) !important;
}
.showchat .chatbody-parent{
width: calc(100% - 75px ) !important;
}

  .header-wrapper .chat svg {
    width: 24px;
    height: 24px;
    margin-left: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.header-wrapper .chat {
  display: block !important;
  cursor: pointer;
}
.header-wrapper .notify-row .chat{
  display: none !important;
}
  .header-wrapper .chat svg path {
    fill: #2c688c;
}
  .chat-wrapper{
    display: none;
    width: 100% !important;
  }
  .chat_open_mob
{} */

  .brandhome .home-heading {
    font-size: 27px;
  }
  .profile-edit .upload-wrapper {
    top: 25px;
  }
  .brandhome .company-profile .form-group, .brandhome .company-profile pre{
    margin-top: 80px;
  }
  .images-video .col-md-4 {
    padding-left: 0;
    padding-right: 0;
}
/* .brochure .upload-photo-wrapper {
  left: 219px;
} */
.brochure-parent .d-flex.align-items-start.flex-column {
  align-items: center !important;
}
.brochure-parent .community-card.brochure {
  margin: 20px auto;
}
  .rounded-body.pb-5.pl-4.pr-4 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.blue-border.card-wrapper {
  margin: 50px 0px;
  padding: 50px 0;
}
.rounded-body-form {
  padding: 20px 20px;
}
.blue-border.card-wrapper .nav-tabs .nav-link {
  min-width: 117px;
}
.profile-body .profile-icon-wrapper {
  margin-top: 30px;
}
  .mac .profile-body .profile-icon-wrapper {
    margin-top: 104px;
}
.popup_inner{
  min-width: 94% !important;
}
.popup_inner.user-report-popup {
  padding: 60px 0;
}
.tran-details .bottom-btn {
  flex-wrap: wrap;
  justify-content: center;
}
.profile-body a.btn.btn-red.float-right {
    display: block;
}
.tran-details .total-box {
  min-width: 100%;
  margin-top: 20px;
}
  .header-text, .header-container ,.header-text .d-flex{
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
    
}
.header-text{
  justify-content: space-between;
    align-items: center
}
.header-text h1.title {
  margin: 20px 0;
  font-size: 22px;
}
.header-container .search_wrapper.mr-3{
  margin-right:0rem !important;

}
.header-text div , .header-container div{
  width: 100%;
}
.header-text .d-flex.flex-grow-1.align-items-center , .header-container .d-flex.flex-grow-1.align-items-center{
  justify-content: center;
  margin-bottom: 20px;
}
.header-text, .header-container, .header-text .d-flex {
  flex-wrap: wrap;
  justify-content: space-between;
}
  .video-parent {
    margin: 0 auto;
    /* width: 89%; */
  }
  .video-parent .video-player {
    width: 100%;
    height: 100%;
  }
  .payment-msz-parent p span {
    font-size: 13px;
  }
  .payment-msz-parent p span:first-child {
    padding-left: 10px;
}
  .update-password {
    position: inherit !important;
    top: 0;
    left: 0;
    transform: translate(0% , 0%);
    width: auto;
  }
  .Join-us-section .d-flex.justify-content-center.align-items-center {
    flex-wrap: wrap;
    text-align: center;
}
.banner-section .event-date {
  margin-top: 20px;
}

  .Join-us-section h3 {
    font-size: 32px;
  }
  .header_wrapper {
    flex-wrap: wrap;
}
.header-btn-group.d-flex {
  justify-content: center;
  width: 100%;
}
.banner-section .row.align-items-center .col-md-6:first-child {
  order: 2;
  margin: 30px 0;
  text-align: center;
}
.glob-connection {
  padding-bottom: 60px;
}
.row.glob-connection:nth-child(2) .col-md-6:first-child {
  order: 2;
}
.second-banner-bg{
  min-height: auto !important;
}
.Join-us-section .col-md-7 {
  order: 1;
}
  .header_wrapper .logo {
    margin: 0 auto;
    margin-bottom: 45px;
}
.global-content-image , .upload-container-image{
  margin: 0px;
}
.banner-section {
  text-align: center;
}

.btn.btn-light {
  background: #ECFEFD;
  min-height: 25px !important;
  padding: 0px 16px !important;
  min-width: 78px;
  line-height: 35px;
  font-size: 15px;
}

.btn.right {
  margin-right: 10px;
}
.self-detail .btn, .self-detail a {
  margin: 4px 0 !important;
}
}
@media (min-width:320px) and (max-width:580px){
  .timer-wrapper {
    margin-top: 25px;
  }

  .timer-wrapper {
    margin-top: 2px !important;
  }
  .eventifo-card{
    border-right: none;
  }
  /* .aesthetic .container-fluid .btn, .aesthetic .container-fluid a.btn.btn-red,.aesthetic .container-fluid .btn:hover, .aesthetic .container-fluid a.btn.btn-red:hover {
    min-width: 200px;
  } */
  .plan-feture .features-card , .plan-feture .features-card .card-body h5{
    min-height: auto;
  }
  .thankubanner {
    min-height: 132px;
  }
  .btn.btn-red.addbtn.levebtn, .btn.btn-red.addbtn.levebtn:hover {
    min-width: 85px;
    padding: 6px 10px !important;
}
  .footer-widget img {
    width: 85% !important;
}
  /* .btn.btn-red.addbtn.levebtn-regis{
    top: 60px;
    }
    .mac .btn.btn-red.addbtn.levebtn-regis{
      top: 60px !important;
    } */
   .rounded-textbox-form.mt-5.edituser, .edituser .rounded-textbox-form {
    padding: 5px 8px;
}
  .sign-modal-content .rounded-textbox-form {
    padding: 5px 0px;
}
.timer_parent {
  flex-wrap: wrap;
}
.Thing-line {
  width: 100%
}
  .Home-banner-bg .countdown-page h4 {
    /* font-size: 15px !important */
  }
  .res-search-wrapper .header_drop {
    width: 100%;
    padding: 0 3.2%;
}
.res-search-wrapper .search_wrapper{
  min-width: 100%;
    max-width: 100%;
    width: 100%
}
.res-search-wrapper .search_wrapper {
  margin-bottom: 0px;
}

.breakonphone{
  display: flex;
  flex-wrap: wrap;
}

  .search-result-parent .notify-row .message h5 {
    margin-top: 6px !important;
}
.notify-row .message div {
    width: 100%;
}
.search-result-parent .notify-row .message .action_group button {
    padding: 5px 16px;
    margin-top: 10px;
}
  .industries-serve .five .card{
    width: 100% !important;
  }
  .hert-tab-parent {
    justify-content: flex-start;
  }
  .ant-tabs-content-holder {
    margin-top: 20px !important;
  }
  .edit-tabular .btn.btn-red, .edit-tabular .btn.btn-red:hover{
    bottom: -23px;
  }
  .userprofile-tbs-wrp .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-tab-btn{
    font-size:15px ;
    padding: 0.5rem 0.3rem;
  }
  .rounded-body .edit-tabular{
    margin-bottom: 2.2rem !important;
  }
  .professional-beauty-banner-wrapper .upload-wrapper , .brand-logo-parent .upload-wrapper , .profile-edit .upload-wrapper {
    width: 40px;
    height: 40px;
  }
  .upload_croperimg svg , .upload-photo-wrapper i svg{
    width: 16px;
    height: 16px;
}
  .upload_croperimg {
    top: -6px;
    left: -4px;
}
.brand-logo-parent .upload-wrapper {
  bottom: -28px;
  right: -12px;
}
  section.Home-banner-bg.cm-home.brand-home-banner-bg .col-md-6.d-flex.justify-content-end, section.Home-banner-bg.cm-home.brand-home-banner-bg .col-md-6.d-flex.justify-content-start {
    justify-content: center !important;
}

.brandhome .graph-wrapper .search_wrapper{
  max-width: 300px;
    min-width: 300px;
}
.speaker-card {
  min-height: 868px !important;
  max-height: auto !important;
}
.networking-card {
 margin-right: 0px; 
}

  .ant-modal.media-popup .ant-modal-body{
    padding: 59px 42px;
  }
  header .header-btn-group .btn, header .header-btn-group .btn:hover {
    min-width: 134px;
  }
  .logo-skew div {
    width: 100%;
    text-align: center;
}
.mac .profile-body .btn.btn-red, .mac .profile-body  .btn.btn-red:hover{
  padding:0px 24px 3px!important;
  
  }
  .mac .profile-body .profile-icon-wrapper {
  margin-top: 120px;
  
  }
.thankyou-text h1 {
  font-size: 60px;
}
.countdown-page .container.hert-parent.mt-5 div.hert-section {
  width: 100% !important;
}


.landing_bannerbg.countdown-page .banner-text {
  padding-top: 30px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
  text-align: center;
  color: #fff;
}
.hert-section.pos-relative img{
  max-width: 134px;
}
.landing_bannerbg .banner-text {
  position: absolute;
  top: 56% !important;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  color: #fff;
}
.mac .hj .banner_container .btn.btn-red ,.hj .banner_container .btn.btn-red, .mac .hj .banner_container .btn.btn-red:hover ,.hj .banner_container .btn.btn-red:hover {
  min-width: 55px !important;
  /* min-height: 23px !important; */
  max-height: 23px !important;
  /* line-height: 23px !important; */
  padding: 0px 11px !important;
  font-size: 8px !important;
  margin-right: 11px !important;
  display: block;

}

.mac .Home-banner-bg.cm-home.brand-home-banner-bg.aesthetic .btn.btn-red , .mac .Home-banner-bg.cm-home.brand-home-banner-bg.aesthetic .btn.btn-red:hover{
  /* max-height: 23px !important;
  line-height: 20px !important; */
}
.logo-skew{
  flex-wrap: wrap;
}
.skew_text {
  margin: 0 auto;
}
.container.hert-parent {
  flex-wrap: wrap;
  margin-top: 36px !important;
}
  .banner-section p {
    font-size: 12px;
  }
  .join-us h1 {
    font-size: 34px;
  }
  .join-us .btn, .Join-us-section .btn:hover, .Join-us-section button.btn.btn-red{
    min-width: 118px !important;
    font-size: 12px;
}
.banner-section .btn, .banner-section .btn:hover {
  min-width: 250px;
  font-size: 15px;
}
  .join-us p {
    font-size: 16px;
}
  .for-phone {
    display: block;
  }
  .for-tab , .for-screen{
    display: none;
    }
  .container.hert-parent{
    flex-wrap: wrap;
  }
  .landing_bannerbg {
    min-height: 80vh !important;
  }
  .mac .countdown-page .landing_bannerbg , .countdown-page .landing_bannerbg{
    height: 100vh;
  }
  .hert-section .center-text h1  {
    font-size: 16px;
    font-weight: 100;
  }
  .hert-section .center-text h4 {
    font-size: 14px;
    font-weight: 600;
}
  .container.hert-parent.mt-5 div.hert-section {
    width: 80%;
}
.container.hert-parent.mt-5{
  margin-top: 1rem!important;
}
.minusmargin-header {
  margin-top:  40px;
  margin-bottom: 80px;
}
.container.hert-parent.mt-5 div {
    width: 50%;
    text-align: center;
}
.hert-parent {
  display: flex;
  justify-content: center;
}
/* .landing_bannerbg .banner-text {
  top: 5%;
} */
.banner-section h1, .home-heading {
  font-size: 30px;
}
.landing_bannerbg .banner-text h4 {
  font-size: 30px;

}
.countdown-page .thankyou-text p , .callu_parent div:first-child{
  font-size: 16px;
}
.landing_bannerbg .discription {
  font-size: 14px;
}
.hert-section .center-text h1 {
  font-size: 23px;
}
.landing_bannerbg .banner-text h4 {
  /* font-size: 22px; */
  font-size: 19px;
    font-weight: 700;
}
}

@media (min-width:300px) and (max-width:991px){
  /* ----------for chat on mobile end---- */

  .header-wrapper .chat {
    display: block !important;
    cursor: pointer;
}
.chat_open_mob .showchat .chatbody-parent , .chat_open_mob .chatbody-parent{
  display: block;
  width: calc(100% - 67px ) !important;
}
.wrap-chat-header {
  width: calc(100% - 67px );
}

.chat_open_mob .bottom {
  width: calc(100% - 67px ) !important;
}
.showchat .chatbody-parent{
width: calc(100% - 75px ) !important;
}

  .header-wrapper .chat svg {
    width: 24px;
    height: 24px;
    margin-left: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.header-wrapper .chat {
  display: block !important;
  cursor: pointer;
}
.header-wrapper .notify-row .chat{
  display: none !important;
}
  .header-wrapper .chat svg path {
    fill: #2c688c;
}
  .chat-wrapper{
    display: none;
    width: 100% !important;
  }
  
  /* ----------for chat on mobile end---- */
}

@media (min-width:581px) and (max-width:767px){
  .timer {
    width: 111px;
    height: 111px;
}
  .technique h5 {
    min-height: 100px;
  }
  .footer-widget img {
    width: 50% !important;
  }

  .signup-step {
    margin: 0 14px;
  }
  .container.hert-parent {
    max-width: 776px;
}
  .hert-section .center-text {
    top: 30%;
}
.hert-section .center-text h1 {
    font-size: 30px;
}
.hert-section .center-text h4 {
    font-weight: 600;
    font-size: 15px;
}
}
@media screen and (max-width: 1199px){
  .ent-am-strt-wrp {
    max-width: 100%;
}
}
@media screen and (max-width: 991px){
  .btn.btn-red, .btn.btn-red:hover{
    line-height: normal !important;
    height: auto;
    min-height: initial !important;
  }
  .job-topwrapper > .row > .col-lg-3:last-child{
    flex: 0 0 100%;
    max-width: 100%;
    display: block;
  }
  .job-common-wrp.jobComing-soon {
    margin-right: 0;
    margin-top: 15px;
    margin-left: 0;
}
.job-topwrapper > .row > .col-lg-3:not(:last-child){
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.looking-RecruitTop{
  display: block;
}
.looking-RecruitTop > img {
  max-width: 100%;
}
.looking-Torecuit {
  margin: 0;
}
.job-common-list{
  max-width: 100%;
}
.mobileWrp-jobfinder.sidebar .cm-logo{
  background: #fff;
  padding: 25px 0px 20px;
}
.sidebar.mobileWrp-jobfinder{
  background: #fff;
}
.sidebar.mobileWrp-jobfinder > ul > li > a{
  color: var(--btncolor);
  padding: 15px 15px 15px 15px;
  box-shadow: initial !important;
}
.menu_open .sidebar.mobileWrp-jobfinder .cross svg, .menu_open .sidebar.mobileWrp-jobfinder .cross svg path{
  fill: var(--btncolor);
  stroke: var(--btncolor)
}
.sidebar > ul > li > a.active, .sidebar > ul > li > a:hover{
  background: transparent;
  color: var(--mainColor);
}
.fltrsBtns {
  display: flex;
}
.fltrsBtns .btn {
  margin: 0 4px !important;
}
}
@media screen and (max-width: 767px){
  .mbv-clnd-topbar {
    background: #fff;
    box-shadow: 0 0 3px rgb(0 0 0 / 18%);
  }
  .mbv-clnd-topbar > ul > li {
      width: 100%;
      text-align: center;
      padding: 0.4rem 0.2rem;
  }
  .mbv-clnd-topbar > ul > li.active{
    color: var(--btncolor);
  }
  .mbv-clnd-list{
    padding: 0.5rem 0.6rem;
  }
  .mobileView-calendar-wrap{
    background: #fff;
  }
  .mbv-clnd-topbar > ul > li > span {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
  }
  .event-tabbody .event-detail.diary-evnt-dtls{
    width: 100% !important;
  }
  .topbar-diary-icn {
    width: 90px;
    text-align: right;
}
.cmn-diary-meeting .time-details{
  border-bottom: 1px solid #eee;
}
  .mbv-clnd-topbar > ul > li > strong{
    color: var(--btncolor);
  }
  .meeting-type .btn{
    font-size: 12px;
    padding: 0.5rem 0.5rem;
  }
  .know-Metngbtn .btn{
    width: 100%;
    margin-bottom: 0.5rem;
    margin-right: 0;
    display: block;
    max-width: inherit;
  }
  .diary-evnt-spkr .speaker-details .profile{
    width: auto;
  }
  .speaker-details .profile
  .event-tabbody .event-detail.diary-evnt-dtls {
    width: 100%;
    padding-left: 0;
    border-left: 0;
}
  .meeting-type .btn svg{
    width: 17px;
    margin-right: 3px;
  }
  .sign-modal-content.diary-meeting-modal-wrap h3.auth-heading.pos-relative.text-center{
    font-size: 21px;
  }
  .sign-modal-content.diary-meeting-modal-wrap .diary-meeting-form label{
    font-size: 0.9rem;
  }
  .diary-meeting-modal-wrap > .row{
    padding: 0px 8px !important;
  }
  .meeting-type{
    display: flex;
  }
  .meeting-type > button {
    width: 50%;
  }
  .icn-wr > svg{
    width: 15px;
    top: 13px;
  }
  .meetingModal .ant-modal.cm_popup.signup_modal{
    width: 100% !important;
  }
  .self-detail.btns-Userprf .btn {
    width: 100%;
}
  .icn-wr .ant-picker{
    padding-left: 32px !important;
    padding-right: 12px !important;
  }
  .pr-m-2{
    padding-right: 7px !important;
  }
  .pl-m-2{
    padding-left: 7px !important;
  }
  .diarypeople-invited .diarypeoplelist:not(:last-child){
    margin-right: 0px;
    margin-bottom: 1rem;
  }
  .notiftime .col-md-3 {
    padding: 0px 15px;
  }
  .diarypeoplelist{
    width: 100%;
  }
  .ent-am-strt-wrp, .ent-am-strt-wrp > h4, .julyEvnt-top, .lookingRecruit-Dtls{
    max-width: 100%;
  }
  .looking-Torecuit > h4, .whatU-get h3 > span, .jobComing-soon > h3, .am-heading-new > h2 > span, .heading-5 > span{
    font-size: 24px;
  }
  .julyEvnt-top.am-heading-new > h3, .am-heading-new.julyEvnt-top > p, .lookingRecruit-Dtls > h4{
    font-size: 18px;
  }
  .looking-Torecuit > p, .jobComing-soon > p, .jbEnt-desc > p{
    font-size: 17px;
    line-height: normal;
  }
  .attendDtls > p{
    font-size: 18px;
  }
  .whatU-get .row > .col-lg-3:nth-child(1) > .attendDtls, .whatU-get .row > .col-lg-3:nth-child(2) > .attendDtls, .whatU-get .row > .col-lg-3:nth-child(3) > .attendDtls, .whatU-get .row > .col-lg-3:nth-child(4) > .attendDtls{
    margin-bottom: 1rem !important;
  }
  .job-common-wrp {
    text-align: center;
  }
  .job-topwrapper > .row > .col-md-3:not(:last-child), .job-topwrapper > .row > .col-md-3:last-child{
    flex: 0 0 100%;
    max-width: 100%;
  }
  .sectn-12-wrapp .scrollbar-tab > ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
  .job-topwrapper > .row > .col-md-3:first-child > div{
    margin-left: 0px;
  }
  .jobEvnt-cmn .jbIcon{
    min-height: initial;
  }
  .jbIcon > img {
    width: 80px;
}
.jbEnt-desc > h4{
  font-size: 23px;
}
.footer-area.footer--light{
  margin-top: 25px !important;
  padding: 1.5rem 0px;
}
.socialMedia-wrp {
  border-top: 1px solid rgb(51 51 51 / 34%);
  padding-top: 0.8rem;
}
.looking-Torecuit {
  text-align: center;
  margin-bottom: 1rem;
}
  .attendDtls > h4{
    font-size: 1.3rem;
    padding-bottom: 0.3rem;
  }
  .jobEvnt-cmn {
    border: 1px solid #fff;
    margin-bottom: 1rem;
    padding: 0.5rem;
  }
  .sectn-12-wrapp .scrollbar-tab > ul > li > a{
    min-width: inherit;
    display: block;
  }
  .sectn-12-wrapp .scrollbar-tab > ul > li {
    margin: 0 !important;
    width: 50%;
    padding: 0px 0.2rem;
}
  .looking-Torecuit, .jobComing-soon{
    margin: 0px;
  }
  .job-common-wrp {
    margin-bottom: 1rem;
}
  .ent-am-strt-wrp > h4{
    font-size: 2rem;
  }
  .scrollbar-tab > ul > li:not(:last-child){
    margin-right: 0.5rem;
  }
  .thanku h6{
    font-size: 16px;
  }
  .am-evnt-hdr-right li:not(:last-child){
    margin-right: 0px;
    margin-bottom: 0.5rem;
  }
  .srch-btn-lst .action_group .btn{
    width: 100%;
    max-width: 100%;
    margin: 0 0 5px;
  }
  .am-evnt-hdr-right li.list-inline-item{
    display: block;
  }
  .timer{
    width: 120px;
    height: 120px;
  }
  .sect-4, .sect-6{
    margin:20px 0px;
  }
  .levebtn.top-lgn-btn {
    display: block !important;
    text-align: right !important;
}
  .evnt-dt-wrp > h2{
    font-size: 2rem;
  }
  .exprt-ld-clmn {
    margin-bottom: 1.5rem;
  }
  .exrt-shrt-desc > p{
    margin-top: 8px;
  }
  .am-evnt-hdr-right {
    text-align: right;
  }
  .am-evnt-hdr-right .levebtn.top-lgn-btn > .btn{
    display: inline-block;
  }
  .am-hme-caption > h2{
    font-size: 1.3rem;
    line-height: normal;
    text-align: center;
  }
  .am-hme-caption{
    padding: 10rem 0 2.5rem;
  }
  .am-heading-new > h2{
    font-size: 25px;
  }
  .job-common-wrp.jobComing-soon > h3{
    font-size: 28px;
  }
  .thnkYou-new-wrp > .heading-5 > span, .am-heading-new.julyEvnt-top > h2 > span{
    font-size: 31px
  }
  .thanku h3{
    font-size: 20px;
  }
  .am-evnt-hdr-right .levebtn.top-lgn-btn  .btn{
    min-width: initial !important;
  }
  .hj .features-card.onlineEvent-Spa .card-body h5, .exrt-shrt-desc > h4{
    font-size: 18px;
  }
  .am-heading-new > h3{
    font-size: 20px;
  }
  .thankubanner .btn{
    margin-top: 0px;
  }
  .thankubanner h4{
    color: var(--btncolor) !important;
  }
  .timer p{
    font-size: 16px;
  }
  .am-evnt-hdr-right ul {
    display: inline-block;
  }
  .timer h1{
    font-size: 1.5rem !important;
    line-height: normal;
  }
  .am-evnt-hdr-right .levebtn.top-lgn-btn .btn{
    padding: 6px 24px;
  }
  .job-landing-header.fixed-header:not(.top-RFheader){
    /* position: relative;
    top: 0px;
    width: 100%;
    z-index: 999;
    background: rgb(255 255 255 / 80%); */
  }
  .job-landing-header.fixed-header:not(.top-RFheader) .am-logo-wrp img{
    max-width: 100%;
  }
  .lookingRecruit-detais-wrp .row > .col-md-6:not(:last-child){
    border-bottom: 5px solid var(--btncolor);
    border-right: 0px none;
    margin-bottom: 1rem;
  }
  .lookingRecruit-Dtls > p{
    font-size: 18px;
  }
  .plan-responsive .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap{
    position: relative;
  }
  .assign-checkboc-parent{
    display: block;

  }
  .csmt-rad-btn .assign-checkboc-parent > div:not(:last-child){
    margin-right: 0rem;
    margin-bottom: 0.5rem;
  }
  .job-finder-page-temp .jb-btn:not(:last-child), .btn.btn-primary.jb-btn:not(:last-child){
    margin-right: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    vertical-align: top;
  }
  .jobprofile-right-wrp .btn-jbcn:not(:last-child) {
    margin-right: 0;
    margin-bottom: 0.5rem;
}
}
@media screen and (max-width: 576px){
  .popCar-btn > .btn {
    width: 100%;
}
}
@media screen and (max-width: 370px){
  .jobprofile-right-wrp{
    display: block;
    text-align: right;
  }
}
@media (min-width:581px) and (max-width:991px){

  .for-tab {
    display: block;
    }
    .join-us div {
      width: 55%
    }
    .join-us p {
      font-size: 24px;
    }
    span.join-us svg {
      width: 500px;
      display: flex;
      margin: 0 auto;
  }
    .join-us div {
      top: 54%;
    }
    .for-phone, .for-screen{
      display: none;
      }
      .global-content-image , .upload-container-image{
        margin: 0 auto;
    }
    
}
@media (min-width:300px) and (max-width:991px){
  .exhibitor-parent {
    padding: 5px 13px;
}
  .home-tab .ant-tabs-content {
    /* margin-top: 90px !important; */
}
  .ant-modal.cm_popup.term-services {
    width: 90% !important;
}
  body.webchatopen, body.notificationopen {
    overflow: inherit;
}
  .showchatmobile{
    display: block;
  }
  .sidebar .cm-logo {
    display: flex;
    font-size: 17px;
    color: #fff;
    align-items: center;
}
.sidebar .cm-logo span.logo {
  display: block;
  margin-left: 20px;
}
  .connection-parent i.fa.fa-ellipsis-v.ant-dropdown-trigger {
    width: 27px;
      height: 27px;
      background: transparent;
      background: #ffffff;
      padding-top: 6px;
      border-radius: 100px;
      box-shadow: 0px 1px 5px #91cbd3;
  }
  .video-call-popup button#joinButton {
    background: var(--btncolor);
}
  .search-result-parent .notify-row .chat {
    top: 22px;
    display: flex !important;
    width: 25px;
    height: 25px;
}
.search-result-parent .notify-row .chat svg {
  width: 12px;
  height: 12px;
}

  body.menu_open {
    overflow: hidden !important;
}
  .search_wrapper.chatbox-textbox {
    max-height: 75px;
}
.chatbox-tooltip {
  display: none !important;
}
  .showon-desktop{
    display: none !important;
  }
  .showon-mobile{
display: block !important;
  }
  a.help.ant-dropdown-trigger svg, .header-wrapper .fa.fa-search svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
}
.header-wrapper .fa.fa-search svg {
  fill: #2b688e;
  width: 19px;
  height: 19px;
}
  .header-wrapper .chat.showmobile991{
    display: block !important;
  }
  .notification svg {
    width: 22px;
    height: 22px;
  }

  .pos-relative.cursor-point.notification .counter {
    top: -8px;
    left: 27px;
}
  .search-result-parent .notify-row .profile-dp, .search-result-parent .notify-row .profile-dp img {
    width: 45px;
    height: 45px;
}
.search-result-parent .notify-row .message h6 {
  font-size: 13px;
}
.search-result-parent .notify-row .message h5 b {
  font-size: 17px;
  font-weight: 600;
}

/* .mac .header-search {
    margin-top: 50px;
} */
  .profile .caret-icon{
    display: none;
  }
  .ant-modal.paymentpopup.croperpopup {
    width: 100% !important;
}
  .profile.ant-dropdown-trigger .fa-caret-down:before{
    content: "";
  }
  .stack-wrapper {
    justify-content: space-around;
    flex-wrap: wrap;
}
  .header-wrapper .cm-section.container-fluid {
    /* padding: 0 100px 0 20px !important; */
    padding: 0 14px 0 13px !important;
}
  .staff .card.community-card {
    min-height: 320px;
    max-height: 320px;
    min-width: 100%;
}
.speaker-card {
  max-width: 335px;
  min-height: 834px;
  max-height: auto;
}
.without-sidenav .body_container{
  margin: 0px 6%;
}
.container-fluid.brandhome .btn.btn-red, .container-fluid.brandhome .btn.btn-red:hover, .container-fluid.brandhome .btn.btn-primary, .container-fluid.brandhome .btn.btn-secondary {
  min-height: 39px !important;
  padding: 0px 14px !important;
  min-width: 145px !important;
  line-height: 39px;
  font-weight: 700;
  font-size: 12px !important;
}
.popup_inner.form.add-media-popup{
  min-width: 94% !important;
}
.carousel-wrapper i.fa.fa-long-arrow-left, .carousel-wrapper i.fa.fa-long-arrow-right{
  display: none;
}

.staff .card.community-card .img-wrapper {
  text-align: center;
}
.staff .community-card h4{
  margin: 15px;
}
.staff  .community-card .action_group{
  margin: 15px;
}
.brand.dash_body_container.withsidenavbar .container-fluid {
  padding: 40px 0!important;
}
.brand-logo-parent {
  left: 23px;
}

.staff .card.community-card .d-flex.align-items-start.flex-column {
  align-items: center !important;
}
  .header-text.d-flex {
    flex-wrap: wrap;
}
  .mobile_trigger{
    display:block;
    padding: 12px 0px;
  }
  span.menuicon svg {
    margin: 0px 14px 0 12px;

    /* position: absolute;
    top: 40px;
    left: 16px;
    z-index: 1; */
}
  .thankyou-text .callu_parent {
    border: 0px solid #92c9d2;
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .callu_parent div:first-child {
    width: 100%;
      padding-left: 0px;
      font-size: 18px;
      margin-bottom: 20px;
  }
  
  .cm-form-wrapper {
    max-width: calc(100% - 12%);
  }
  .showmobile991{
    display: block;
  }
  .webhome .header-text a.btn.btn-primary.cursor-point {
    display: none;
}

  .col-lg-7 .paymentcard {
    margin-top: 25px;
}
  .hidemobile991{
    display: none ;
  }
  /* ------------plan page--------- */
  .plan-responsive .rounded-table td {
    width: 50%;
    text-align: left !important;
}
.plan-responsive .table-responsive {
  margin: 20px 0;
}
.plan-responsive table.table-bordered tr th {
  font-weight: 700;
  font-size: 16px;
}
.plan-responsive table svg{
  margin-right: 12px;
}
.plan-responsive .header h1 svg {
  width: 70px;
  height: 70px;
}

.plan-responsive .header h1 svg path {
  fill: #92c9d2;
}
.plan-responsive .header h1, .plan-responsive .header h6, .plan-responsive .header h3 {
  color: #352783;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 0px;
}
.text-blue {
  color: #2c688d !important;
}
.text-black {
  color: #000000 !important;
}
.text-red{
  color:#e41b12 !important;
}
.ant-tabs-tabpane:nth-child(1) .text-light-black{
  color: var(--btncolor) !important;
}
.ant-tabs-tabpane:nth-child(2) .text-red{
  color: #F25922 !important;
}
.ant-tabs-tabpane:nth-child(3) .text-black{
  color: #F2B680 !important;
}
.text-light-black {
  color:#828282 !important;
}
.Second:first-child .header {
  padding: 44px 0;
}
.plan-responsive .header {
  background:#fff;
  border: 2px solid #92c9d2;
  border-radius: 20px;
  text-align: center;
  margin: 20px 0;
    padding: 20px;
}
.plan-responsive .Second.red .header {
  border-color:  #FF0000 !important;
  background:#fff;
}
.plan-responsive .Second.red .header h1 svg path {
  fill: #FF0000;
}
.plan-responsive .Second.red .header h1, .plan-responsive .Second.red .header h6, .plan-responsive .Second.red .header h3 {
  color: #FF0000 !important;
  font-weight: 700;
}
.plan-responsive .Second.blue .header {
  border-color:  #2c688d  !important;
  background:#fff;
}
.plan-responsive .Second.blue .header h1 svg path {
  fill: #2c688d;
}
.plan-responsive .Second.blue .header h1, .plan-responsive .Second.blue .header h6, .plan-responsive .Second.blue .header h3 {
  color: #2c688d  !important;
  font-weight: 700;
}
/* ------------plan page end--------- */
  .container-fluid {
    padding-left:15px !important;
    padding-right: 15px !important;
  }
  /* .mobile_trigger{
    display:block;
    padding: 12px 0px;
  }
  span.menuicon svg {
    margin: 0 14px 0 0;
  } */
  .dash_body_container .dasboard-parent{
    flex-wrap: wrap-reverse;
  }
  .dash_body_container .graphparent-card, .dash_body_container  .piegraph-parent-card{
    width: 100%;
    margin-left: 0%;
  }
  header.filter-header-wrapper.container-fluid {
    padding: 0 24px 0 20px !important;
}
}

@media (max-width:340px){
  .search_wrapper {
    min-width: 260px;
}

@media (max-width:1700px){

    .modal-wrapper {
        padding: 0px;
    }
  
}


  
  

}
@media (min-width:320px) and (max-width:1199px){
  .without-sidenav .body_container.profile-body{
    margin: 0px 0%;
  }
  .footer-widget img {
    width: 25%;
}
.footer-menu {
  padding-left: 0px;
    padding: 8px 0;
}
.timer p{
  font-size: 14px;
  font-weight: 600;
}
.timer_parent {
  margin: 0 !important;
}

}
@media (min-width:992px) and (max-width:1300px){


  .new-confrence-height .card-img-wrapper .card img {
    min-height: 258px;
    object-fit: cover;
}
  .mobile_trigger{
    display:block;
    padding: 12px 12px;
  }
  .features-card {
    /* max-width: 250px; */
    margin: 0 12px;
}
.technique {
  width: 256px;
  margin: 20px 8px;
}
  span.menuicon svg {
    margin: 0px 14px 0 0;
    /* position: absolute;
    top: 40px;
    left: 16px;
    z-index: 1; */
}
  .without-sidenav .body_container.profile-body{
    margin: 0px 2%;
  }
  .tablediv .header {
    min-height: 130px;
  }
  .join-us h1 {
    font-size: 32px;
    margin-bottom: 0;
  }
  .join-us p {
    font-size: 15px;
  }
  .join-us button.btn.btn-red {
    min-width: 100px !important;
    font-size: 12px;
}
  .tablediv .Second .header h3 {
    font-size: 22px;
}

.payment-parent .container {
  max-width: 1200px !important;
  width: 100%;
}


/* .minusmargin-header {
  margin-top: -135px;
} */
}
@media (min-width:1300px) and (max-width:1400px){
  .without-sidenav .body_container.profile-body {
    margin: 0px 8%;
}
  .join-us h1 {
    font-size: 42px;
  }
  .join-us p {
    font-size: 19px;
  }
  .new-confrence-height .card-img-wrapper .card img {
    min-height: 258px;
    object-fit: cover;
}
}
@media (min-width:1000px){
  .hert-section.pos-relative img {
    max-width: 200px;
  }
  .mac  .landing_bannerbg{
    min-height: 50vh;
    
    }
    .mac .countdown-page .landing_bannerbg , .countdown-page .landing_bannerbg{
      height: 100vh;
    }
  
}
@media (max-width: 1300px){

    .dash_body_container .mobile_trigger , .dash_body .mobile_trigger, .header-wrapper .mobile_trigger{
      display: block;
      padding: 12px 0;
      top: 20px;
      position: fixed;
      z-index: 999;
  }
  .dash_body_container.without-sidenav {
    padding: 0px !important;
  }
  .cross svg {
    display: block;
  }
 .dash_body .mobile_trigger {
    padding: 12px 15px !important;
}
.menu_open .dash_body_container .mobile_trigger, .menu_open .dash_body .mobile_trigger {
  display: none;
}
  /* .dash_body_container {
    padding: 66px 2rem !important;
  } */
  .sidebar{
    display: none;
  }
  .menu_open .sidebar {
    display: block;
  }
  .dash_body_container .sidebar .cross , .dash_body .sidebar .cross {
    position: absolute;
    top: 8px;
    right: 2px;
}
.inner_header{
  width:calc(100% - 0px);
  border-bottom: 1px solid #d4e0ef73;
}
.brand.dash_body_container.withsidenavbar{
  padding: 110px 0rem;
}
  .dash_body_container .menuicon svg , .dash_body .menuicon svg{
    margin: 0;
  }
  .dash_body_container .menuicon svg path, .dash_body .menuicon svg path  {
    fill: #000;
  }

}
@media (min-width:767px) and (max-width:1400px){

  .staff .community-card img {
    width: 90px;
    height: 90px;
    }
    
    .home-red-circle img {
        height: 100px;
        width: 100px;
    }

}
@media (min-width: 1450px){
  .coverImage_placholder {
    top: 84%;
  }
.speaker-card {
  background-color: #D4F1F7;
  min-height: 974px;
  max-height: 100%;
  max-width: 100%;
  margin: 0 auto;
}
  .landing_bannerbg {
    min-height: 50vh;
  }
  .mac .countdown-page .landing_bannerbg , .countdown-page .landing_bannerbg{
    height: 100vh;
  }
}
.welcome{
  text-align: center;
  color: black;
  padding-top: 303px;
}

@media (min-width:320px) and (max-width:1199px){
  .footer-widget img {
    width: 25%;
}
.footer-menu {
  padding-left: 0px;
}
.speaker-card img.img-fluid {
  min-width: 0;
}
/* .speaker-card {
  max-width: 155px;
} */

}

@media (min-width:320px) and (max-width:726px){
  .timer p{
    font-size: 17px;
    line-height: normal;
    padding: 0;
  }
  .btn.btn-red, .btn.btn-red:hover, .btn.btn-primary, .btn.btn-secondary {
    min-width: 127px;
  }

  /* .thankubanner {
    min-height: 36vh;
  } */

  .rounded-body-form {
  padding: 40px 10px;
  }

  .card-wrapper {
    padding: 50px 10px;
  }

  .community-card  {
    text-align: center;
    margin: 10px auto;
  }

  .header-container h4 {
    font-size: 5vw;
    
  }

   .rounded-body-form .status-process-step p {
    font-size: 2vw;
  }

  .rounded-body-form .process-circle, .rounded-body-form .success-circle {
    height: 35px;
    width: 35px;
  }

    .carousel-wrapper .fa.fa-long-arrow-left , .carousel-wrapper .fa.fa-long-arrow-right {
        display: none;
    }

    .home-header-text {
        font-size: 40px;

    }
    .home-header-subtext {

        font-size: 30px;

    }

}
@media (min-width:726px) and (max-width:992px){
 .timer-wrapper{
   margin-top: 32px !important;
 }
}

@media (min-width:992px) and (max-width:1300px){
  .technique h5 {
    min-height: 100px;
  }
  .card-wrapper {
    padding: 50px 15px;
  }
  .rounded-textbox-form {
    padding: 5px 20px;
}
 
  .tablediv .Second .header h3 {
    font-size: 22px;
}
.payment-parent .container {
  max-width: 1200px !important;
  width: 100%;
}
}


@media (max-width: 1300px){
  .dash_body_container .mobile_trigger ,  .dash_body .mobile_trigger{
    display: block;
    padding: 12px 0;
  }
  .dash_body_container{
    padding:110px 1rem 0 !important;
  }
  .sidebar{
    display: none;
  }
  .menu_open .sidebar{
    display: block;
  }
  .dash_body_container .sidebar .cross {
    position: absolute;
    top: 8px;
    right: 2px;
}

.cross svg {
    display: block;
}

.inner_header{
  width:calc(100% - 0px);
  border-bottom: 1px solid #d4e0ef73;
}

  .dash_body_container .menuicon svg {
    margin: 0;
  }
  .dash_body_container .menuicon svg path {
    fill: #000;
  }
}
@media(min-width:1301px) and (max-width:1434px){
  .tablediv .Second .header h3 {
    font-size: 25px;
}
  .timer-wrapper {
  
  
}
}
@media (min-width:992px) and (max-width:1199px){
  .speaker-card {
    min-height: 965px;
    max-height: 965px;
}
}
@media (min-width:1466px) and (max-width:1596px){
  .staff .card.community-card .align-items-start.flex-column div span.action_group {
    flex-wrap: wrap;
}
}
@media (min-width:714px) and (max-width:991px){
  .features-card {
    /* max-width: 218px; */
    margin: 0 9px;
}
.technique h5 {
  min-height: 122px;
}

/* .thankubanner {
min-height: 36vh;
} */
 }
 @media (min-width:320px) and (max-width:400px){
 .btn.btn-red.addbtn.levebtn, .btn.btn-red.addbtn.levebtn:hover {
    min-width: 85px;
    padding: 6px 10px !important;
  }
 }
.staff .community-card h4, .staff .card.community-card .align-items-start.flex-column span:first-child{
  font-size: 20px;
  display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis
}

.staff .community-card h4::first-letter , .staff .card.community-card .align-items-start.flex-column span::first-letter{
  text-transform: capitalize !important;
}
.action_button_text {
  display:inline-flex;
  width: 100%; 
}
.action_button_rightmargin { 
  margin-right: 1em;
}
.badge-common {
  background: var(--white);
  border: 1px solid #92C9D1;
  font-weight: 700 !important;
  font-size: 12px !important;
  border-radius: 30px !important;
  padding: 7px 12px !important;
}
.radio-button-Style  {
  height: 30px;
  line-height: 30px;
}
.btn-center{
  text-align:-webkit-center !important;
}
.profile {
  display: flex;
  align-items: center;
}
td.wrap-text-msg{
  max-width: 30em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.virual-viewport {
  width: 150px;
  overflow-y: auto;
}
.virual-item {
  height: 20px;
}
.mac .sidebar {
  max-width: 300px;
}
.mac  .sidebar > ul > li > a {
  font-size: 16px;
}
.mac   .sidebar > ul > li > a > span svg {
  width: 23px;
  height: 23px;
  margin-right: 11px;
}
.mac  .cm-logo a, .cm-logo a:hover {
font-size: 13px;
}
.mac  .header-container h4 {
font-size: 26px;
}
/* .mac .dash_body_container{
padding: 110px 2rem 29px calc(300px + 2rem) !important;
} */
.profile-edit pre{
overflow: hidden;
}


.red-circle { 
  background: #e52118 !important;
  
}
.cursor-point{
  cursor: pointer !important;
}
.cursor-point.active::after{
  content: " ";
  background: var(--headercolor);
  height: 7px;
  width: 100%;
  display: block;
  margin-top: 5px;
  margin-bottom: -38px;
}
.loader-connection{
  height: 7em !important;
width: 7em !important;
z-index: 999;
color:red !important; ;
justify-content: center;
align-self: center;
position: absolute;}








.btn.btn-redd, .btn.btn-redd:hover {
    background: #8cd0d8;
    color: #fff;
    border-radius: 100px;
    min-height: 48px;
    /* padding: 9px 24px; */
    /* min-width: 142px; */
    font-weight: 700;
    /* margin: 12px 0px; */
    /* font-size: 19px; */
}
@media (min-width:320px) and (max-width:383px){
    .mac .profile-icon-wrapper img {
    margin-top: 0px;
  }
  .profile-icon-wrapper img {
    margin-top: -0px;
  }

}
@media (min-width:1200px) and (max-width:1300px){
  .callu_parent div:first-child {
    font-size: 17px;
}
}

@media (min-width:300px) and (max-width:1500px){
  .hj .features-card .card-body{
    padding: 1.25rem ;
}

}

@media (min-width:1600px) and (max-width:1800px){


}
@media (min-width:1300px) and (max-width:1601px){

  }
/* @media (max-width:500px){
  .ul.ant-dropdown-menu.ant-dropdown-menu-light.header-search.radha.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
    margin-left:-82 em !important;
  }
} */