

table.table.table-bordered.rounded-table a{
    color:#2D688D;
    font-weight:700;
}
.tab-add-btn-group{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.btn-primary , .btn.btn-primary:focused{
    border-color: var(--btncolor) !Important;
    min-width: 150px;
    color: #fff !Important;
    text-decoration: none !Important;
    background: var(--btncolor) !Important; 
    border: none !important;
}
.tab-add-btn-group .btn.btn-primary {
    border-color:var(--btncolor) !Important;
    min-width: 150px;
    color: #fff !Important;
    text-decoration: none !Important;
    background: var(--btncolor) !Important;
    border: none !important;
}
.btn.btn-primary:hover {
    opacity: .8!important; 
    color: #fff !Important;
    border-color: var(--btncolor) !Important;
    border: none !important;
 }
 .dash_body_container.brand.hidesidebar {
    padding: 90px 3.5rem;
    
}
/* ------------for checkbox------------- */
.check_wrapper {
    display: inline-flex;
    margin-right: 10px;
    vertical-align: middle;
    position: relative;
}

.check_wrapper input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 99;
}
.term-services .check_wrapper input{
    height: auto ;
}
.check_wrapper > span {
    display: inline-block;
    height: 18px;
    width: 18px !important;
    background-color: #eee0 !important;
    border-radius: 3px;
    border: 1px solid #92c9d2;
}

.check_wrapper > span:after {
    content: '';
    position: absolute;
    width: 6px;
    height: 10px;
    border-right: 2px solid #2D688D;
    border-bottom: 2px solid #2D688D;
    transform: rotate(35deg);
    left: 6px;
    top: 3px;
    transition: all 0.25s;
    opacity: 0;
}

.check_wrapper input:checked + span {
    background: #92c9d2;
    border-color: #92c9d2;
}

.check_wrapper input:checked + span:after {
    opacity: 1;
}
/* ------------for checkbox circle------------- */
.check_wrapper.circle-checkbox input:checked + span {
    background: #92c9d2;
    border-color: #92c9d2;
}

.check_wrapper.circle-checkbox > span {
    display: inline-block;
    height: 20px;
    width: 20px !important;
    background-color: #eee0 !important;
    border-radius: 3px;
    border: 1px solid #656565;
    border-radius: 100px;
}
.check_wrapper.circle-checkbox > span:after {
    content: '';
    position: absolute;
    width: 13px;
    height: 13px;
    left: 50%;
    top: 50%;
    transition: all 0.25s;
    opacity: 0;
    border-radius: 100px;
    /* background: #183f5d; */
    background: #92c9d2;
    transform: translate(-50% ,-50%);
    border: none;
}
.form-group.form-control.select_field {
    background: #183f5d;
    color: #fff;
    border-color: #183f5d;
  }
check_wrapper.circle-checkbox input:checked + span {
    background: #ffffff;
    border-color: #ffffff;
}


.hidesidebar{
    /* margin-left: 340px; */
    padding: 90px 0rem 29px calc(0px + 0rem);
}
.hidesidebar .container-fluid.brandhome {
    padding: 0px 41px !important;
}
.brand.dash_body_container.withsidenavbar {
    padding: 110px 2rem 29px calc(340px + 0rem);
}
.brand.dash_body_container.withsidenavbar .container-fluid {
    padding: 30px 4px !important;
}
.without-sidenav {
    padding: 0px 0 !important;
}
.popup_inner.form.add-media-popup {
    min-width: 780px !important;
}
.without-sidenav .body_container .header-text {
    justify-content: center;
    margin: 40px !important;
}
.without-sidenav .body_container {
    margin: 0px 26%;
}
 .brand-deatils .community-card {
    padding: 5px 0px;
 }
 .image-chat.chat-profile .chat {
    top: 105px;
    left: 5px;
}
.profile-dtl .image-chat .chat {
    top: 110px;
    left: 106px;
}
 .chat-icon-wrapper .chat svg{
    width: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    position: absolute;
    cursor: pointer;
 }
 .chat.pos-relative.cros svg {
    width: 17px;
}
 .chat svg path{
     fill:#fff
 }
 .chat-wrapper {
    width: 60px;
    border: 0px solid black;
    border-radius: 25px;
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: 0 0px 10px  rgb(0 0 0 / 15%);
    background-color: inherit;
    display: flex;
    width: 67px;
    height:100vh;
    z-index: 9999;
    position: fixed;
}
.chat-wrapper.showchat {
    width: 426px;
}
header.header-wrapper .chat svg {
    margin-left: 0;
    margin-right: 12px;
    -webkit-text-stroke: 2px;
}
.search_wrapper.chatbox-textbox textarea {
    padding: 8px 22px 12px 12px;
}
.search_wrapper textarea {
    border: 0;
    width: 100%;
    height: 100%;
    outline: none;
    background: #de232300;
    padding: 12px;
    padding-left: 0;
}
.other .dp {
    margin-right: 20px;
    width: 60px;
}
.msz-box-parent {
    max-width: calc( 100% - 60px );
    font-weight: 100;
    min-width: auto;
}
.search_wrapper.chatbox-textbox input {
    border: 0;
    width: 100%;
    height: 100%;
    outline: none;
    background: #de232300;
    padding: 12px;
    padding-left: 0;
    padding-right: 27px;
    padding: 8px 20px 8px 0px;
}
article.chattextbox {
    border: none;
    outline: none;
  }
.msz-box-parent h6 {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 700;
    color: #807e7e;
    margin-bottom: 2px;
}
.chat-current-online-body .center-chat-text {
    text-align: center;
    margin-right: 75px;
    text-align: center;
    width: 83%;
    margin-right: 75px;
}
.dp .dot.yellow {
    position: absolute;
    top: 40px;
    right: 7px;
    background: #ffffff;
    width: 14px;
    height: 14px;
    border-radius: 100px;
    border: 3px solid #4CAF50;
}
.chat-icon-wrapper {
    background-color: var(--btncolor);
    color: #fff;
    padding: 5px;
    margin-bottom: 5px;
    width: 100%;
    height: 46px;
    margin: 0 auto;
    position: relative;
    border-top-left-radius: 23px;
    display: flex;
    justify-content: space-around;
}
.chatbody-parent .chat-icon-wrapper div {
    width: calc(100% /2 );
    padding: 12px 0;
}
.chat-counter {
    top: 7px !important;
    left: 8px !important;
}
.counter.chat-counter.white-counter {
    background: #d33b2700;
    color: #fff;
    left: 120px !important;
    border: 2px solid #fff;
    font-size: 11px;
    padding-top: 2px;
    font-weight: 600;
    width: 26px;
    height: 26px;
    top: 11px !important;
}
.chat.pos-relative .counter.chat-counter.white-counter {
    left: 6px !important;
    font-size: 9px;
    background: #fffffff2;
    color: #000;
    width: 23px;
    height: 23px;
    top: 15px !important;
}
.showCase-chat-counter {
    background: #d33b2700;
    color: #fff;
    left: 120px !important;
    border: 2px solid #fff;
    font-size: 11px;
    padding-top: 2px;
    font-weight: 600;
    width: 26px;
    height: 26px;
    top: 11px !important;
}
.chatbody , .chatbody1{
    /* padding: 20px;
    padding-bottom: 213px;
    height: 100vh;
    overflow: scroll; */
    padding: 20px;
    padding-bottom: 16px;
    height: calc(100vh - 215px);
    overflow-y: auto;
    margin-top: 119px;
    overflow-x: hidden;
}

.chatbody.support .chatbody1.support{
    margin-top: 56px;
}
.no-chat-parent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    width: 75%;
    text-align: center;
    color: #c6c6c6;
    font-size: 18px;
}
.chat-current-online-body i {
    font-size: 18px;
}
.no-chat-parent span svg {
    width: 66px;
    margin: 0px auto 19px;
    fill: #c8c9c9;
}
.chat-icon-wrapper .active {
    /* background: #2d688d; */
    background: var(--btncolor)
}
.chat-header {
    width: 67px;
    background: #fff;
    box-shadow: 0 0px 10px  rgb(0 0 0 / 15%);
    height: 100vh;
    border-top-left-radius: 22px;
    margin-left: 0px;
}
.chat-scroll {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 59px);
}
.chatbody-parent {
    background: #F4FAFD;
    border-top-right-radius: 23px;
    border-bottom-right-radius: 23px;
    height: 100vh;
    overflow-y: auto; 
    position: relative;
    /* width: 407px; */
    width: 0px;
    display: none;
    overflow: hidden;
}

.showchat .chat-header {
    margin-left: 0;
}
.showchat .chatbody-parent {
    display: block;
    width: 360px;
}
.chat-wrapper img{
    padding: 12px;
  }
.chatbody-parent .chat-icon-wrapper {
    background: #80CAD4;
    width: 100%;
    border-radius: 0;
    text-align: center;
    height: auto;
    padding: 0;
    border-top-right-radius: 23px;
}
.bottom.fullwidth form {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}
.bottom.fullwidth .search_wrapper.chatbox-textbox {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}

.bottom {
    /* border-top: 1px solid #92c9d275;
    margin-left: -20px;
    margin-right: -20px;
    padding: 22px;
    position: fixed;
    width: 401px;
    bottom: 10px;
    background: #f4fafd; */
    border-top: 1px solid #92c9d275;
    padding: 10px;
    position: fixed;
    width: 360px;
    bottom: 0px;
    /* bottom: 10px; */
    background: #f4fafd;
    
    display: flex;
    align-items: center;
}
.bottom form {
    width: 90%;
    min-width: 90%;
    max-width: 90%;
}

.bottom span svg {
    width: 30px;
    fill: #e52113;
}
.chat-current-online-body span svg {
    width: 20px;
    margin-left: 10px;
}
.chat-current-online-body span svg path{
    fill:#0d3d58;
}

.search_wrapper.chatbox-textbox {
    width: 98%;
    min-width: 98%;
    max-width: 98%;
    border: none;
    box-shadow: 0 2px 5px #92c9d2b5;
    padding: 0.5rem 1.5rem;
    min-height: 54px;
    overflow-y: auto;
    
    max-height: 86px;
}
 /* {
    position: absolute;
    top: 24px;
    bottom: 6%;
    right: 20px;
    transform: translate(0);
} */
.other {
    display: flex;
    width: 90%;
    margin-right: 10% !important;
    justify-content: flex-start;
}
/* .other div{
    display: flex;
    width: 80%;
    margin-left: 20% !important;
    justify-content: flex-end;
} */
.me{
    display: flex;
    width: 80%;
    margin-left: 20% !important;
    justify-content: flex-end;
    
}
.me .msz-box {
    background: #2d688d;
    color: #fff;
    box-shadow: 0px 1px 1px #000000;
}
.me .msz-box a {
    color: #fff;
}
.wrap-chat-header {
    position: fixed;
    z-index: 9;
    width: 360px;
}
.chat-current-profile img , .chat-wrapper .profile-dp img{
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 10px;
    border-radius: 100px;
    border: 1px solid #b3b3b3;
}
.other .dp img  {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    margin: 0 !important;
}

.chat-wrapper .other img  {
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 11px;
        border-radius: 100px;
        border: 1px solid #b3b3b3;
}
.chat-current-online-body, .chat-current-profile {
    /* background: #0d3d58; */
    background: var(--headercolor);
    color: #fff;
    min-height: 60px;
    display: flex;
    align-items: center;
}
.chat-current-online-body h4 {
    margin-bottom: 0;
    color: #fff;
    /* padding-top: 10px; */
    font-size: 16px;
    font-weight: 700;
}
.chat-current-online-body p {
    margin-bottom: 0;
    padding-bottom: 10px;
    color: #92c9d2;
}
.chat-current-profile , .chat-wrapper .profile-dp{
    position: relative;
    cursor: pointer;
    
}
.chat-wrapper .profile-dp:hover {
    background: #f4fafd;
}

.chat-current-profile .dot , .chat-wrapper .profile-dp .dot{
    background: #ffffff;
    width: 14px;
    height: 14px;
    border-radius: 100px;
    position: absolute;
    bottom: 10px;
    right: 16px;
    border: 3px solid #4CAF50;
}
.chat-scroll .profile-dp .dot {
    bottom: 10px;
    right: 14px;
}
.profile-dp.selected-user {
    background: #f4fafd;
    box-shadow: 0px 0px 4px #d0d3d5e0;
    border-left: 3px solid #2d688d;
}
.date-time.date-show span {
    background: #eeeeee;
    display: inline-block;
    padding: 6px 12px;
    border-radius: 6px;
    margin: 0 auto !important;
    align-items: center;
}
.date-time.date-show {
    display: block;
    text-align: center;
}
p.date-time {
    margin-bottom: 0 !important;
}
.dot.green{
    background: #4CAF50 !important;
}
.dot.yellow{
    background: #F4E60B ;
    border-color: #F4E60B !important;
}
.dot.red{
    background: #D33B27 !important;
    border-color:#dfd1cf !important;
}
.chat-current-online-body span.redcamera svg path {
    fill: #d33b27;
}
.msz-box {
    background: #fff;
    padding: 9px;
    border-radius: 7px;
    color: #000;
    width: 100%;
    box-shadow: 0px 1px 1px #585858;
    word-break: break-word;
    font-weight: 100;
}
.other, .me {
   
    margin: 0px 0 2px 0;
}
p.date-time.mt-2.date-show {
    margin: 4px 0 10px !important;
}
.other.less, .me.less{
    margin: 5px 0;   
}
.chat-icon-wrapper svg {
    width: 13px;
    position: absolute;
    right: 26px;
    top: 15px;
   
}
.chat-icon-wrapper svg path{
    fill: #fff;
    stroke: #fff;
}
.call_notification {
    background: #fff;
    padding: 6px 11px;
    border-radius: 10px;
    margin:24px 0;
}
.call_notification h5 {
    display: flex;
    margin-bottom: 0;
    font-size: 13px;
    flex-wrap: wrap;
}
.call_notification hr {
    margin: 10px 0;
}
.call_notification h6 a, .call_notification h6 {
    color: #2d688d;
    font-weight: 700;
    margin-bottom: 0;
    font-size: 13px;
}
.call_notification.menoti {
    /* background: #e0e0e0; */
    background: #2d688d;
    width: 100%;
    box-shadow: 0px 1px 1px #8e8e8e;
    color: #fff;
}
.call_notification.menoti h5 , .call_notification.menoti  h5 span svg path{
    color: #fff;
    fill:#fff;
}
.call_notification.menoti h5 span svg {
    fill: white;
}
.call_notification p {
    padding-left: 24px;
    margin-bottom: 0;
    font-size: 11px;
}
.call_notification h5 span svg {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    fill: #505050;
}
.ant-tooltip-inner , .ant-tooltip-arrow-content{
    background-color: var(--btncolor) !important;
}
.ant-tooltip {
    z-index: 9999 !important;
}
.call_notification h5 span svg path{
    fill: #505050;
}
/* -------tooltip------- */
.tooltip {
    /* position: relative;
    display: inline-block;
    border-bottom: 1px dotted #2d688d; */
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #2d688d;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
/* * --------------------image upload for registerimeageupload---------------- */ */
 .register-upload-img {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  margin: 0 auto;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  border: 3px solid #000;
  margin-left: 0;
}
 .register-upload-img:after {
  content: '';
    position: absolute;
    width: 48px;
    height: 48px;
    background: #3c3c3c;
    border-radius: 100%;
    right: 44px;
    bottom: 24px;
}
.profile_img_wrapper {
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    margin: 0 auto;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
  }
  
  .profile_img_wrapper:after {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    background: #393996;
    border-radius: 100%;
    right: -10px;
    bottom: -6px;
  }
  
  span.upload_icon {
    position: absolute;
    width: 19px;
    right: 0;
    bottom: 7px;
    z-index: 99;
  }
  /* .upload-wrapper img {
    height: 20px;
    height: 100px;
    width: 100px;
    background: pink;
    border-radius: 100px;
    object-fit: cover;
} */
  input.profile_input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 999;
    cursor: pointer;
  }
 label.upload-photo-wrapper.upload-container-pic.rectangle img {
    min-width: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 0;
    min-height: 150px;
    border-radius: 12px;
    position: relative;
    /* box-shadow: 0px 0px 4px #8BC3CB; */
    top: 0;
    left: 0;
}
  button.cm_img_upload_btn {
    position: absolute;
    left: -60px;
    font-size: 12px;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    background: #393996;
    color: #fff;
    border: 0;
    padding: 6px;
    line-height: 1;
    border-radius: 4px;
  }
  
  
  .file_uopload_btn_border {
    border: 2px solid #393996 !important;
    color: #393996 !important;
    font-weight: 600;
    position: absolute;
    top: 21px;
    right: -15px;
    background: transparent !important;
    border-radius: 26px;
    margin: 0px 14px !important;
    font-family: 'SF Compact Display Medium';
    padding: 7px 25px;
  
  }
.brochure .upload-wrapper{
    cursor: auto;
    background: #fff;
    border: 1px solid #92c9d2;
    height: 65px;
    width: 75px;
    
}
.brochure .upload-photo-wrapper {
    margin: 0;
    position: absolute;
    top: 90%;
    left: 75%;
    transform: translate(-50%, -50%);
    background: #5383a2;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    border-radius: 100px;
}
.brochure .upload-photo-wrapper .fa{
    color:#fff;
    font-size: 12px;
}
.brochure .upload-wrapper span {
    background: #0d3d58;
    width: 34px;
    height: 34px;
    z-index: 9;
    position: absolute;
    border-radius: 100px;
    text-align: center;
    top: 13px;
    left: 13px;

}
.brochure .upload-wrapper span i {
    position: absolute;
    top: 10px;
    right: 9px;
    color: white;
    font-size: 15px;
}
.community-card.brochure, #get_file, #get_file:hover, #get_file:focus {
    min-height: 150px !important;
}
.community-card.brochure p {
    font-size: 20px;
    text-transform: capitalize;
    color: #0d3d58;
    font-weight: 700;
    text-align: left;
    margin-bottom: 0;
}
.community-card.brochure .row {
    align-items: center;
}
.brochure .upload-wrapper img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
}

span.register_upload_icon {
  position: absolute;
  width: 20px;
  right: 56px;
  bottom: 39px;
  z-index: 99;
}
.brochure-parent{
    /* display: flex;
    justify-content: space-between */
}
.brochure{
    border: 1px solid #92c9d2 !important;
    border-radius: 12px !important;
    align-items: center;
    /* position: relative; */
    /* display: flex;
    justify-content: space-evenly; */
    padding: 0px 0px;
    background-color: #fff;
    
}
.brochure .img-wrapper{
    position: relative;
    margin-right: 20px;
}
.brochure .img-wrapper img {
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    box-shadow: 0px 0px 3px #92c9d2;
    object-fit: cover;
}
.brochure p {
    font-size: 15px;
}
.card.community-card .toggle-parent {
    display: revert;
}
.card.community-card hr {
    border-color: #e8e8e8a3 !important;
}
.community-card .action_group p{
    margin-bottom: 0;
}
.community-card .action_group span.blue {
    color: #5383a2;
}

.professional-beauty-banner-wrapper {
    position: relative;
    border-radius: 20px;
}

.professional-beauty-banner-wrapper img {
    border-radius: 20px;
    width: 100%;
    /* min-height: 400px;*/
    /* max-height: 550px;  */
    object-fit: cover;
}
.professional-beauty-banner-wrapper img.placeholder {
      max-height: 550px !important; 
}
.professional-beauty-banner-wrapper img.placeholderimg{
  min-height: 400px;
    max-height: 400px; 
}
.professional-beauty-banner-wrapper .upload-wrapper {
    position: absolute;
    bottom: 0;
    width: 50px;
    height: 50px;
    right: 25px;
}
.profile-edit{
    position: relative;
}

.profile-edit .upload-wrapper {
    position: absolute;
    top: -20px;
    width: 50px;
    height: 50px;
    right: 25px;
    cursor: pointer;
}
.profile-edit p {
    word-break:break-word;
}
.upload-photo-wrapper i svg {
    width: 20px;
    fill: #fff;
}
.brand-logo-parent .upload-wrapper {
    position: absolute;
    bottom: -40px;
    right: -18px;
    width: 50px;
    height: 50px;
}
.brand-logo-parent {
    position: absolute;
    bottom: -33px;
    left: 54px;
    /* background: #000; */
    border-radius: 12px;
    width: 265px;
    min-height: 115px;
    max-height: 115px;
}
.brand-logo-parent img {
    width: 265px;
    /* min-height: 115px; */
    max-height: 115px;
    border-radius: 10px;   
    border: 1px solid #868282;
}
.card.community-card.staff-container button {
    display: flex;
}
/* --------------assign brand ----------- */
.assign-checkboc-parent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.assign-checkboc-parent .cm_container {
    font-size: 17px;
    margin-bottom: 0;
}
.assign-checkboc-parent .cm_container .radio-checkmark {
    top: 3px;
}
.ant-modal.media-popup {
    width: 800px !important;
  
}
.ant-modal.media-popup .ant-modal-body {
    padding: 59px 20px;
}
.ant-modal.media-popup .card.video-card img, .ant-modal.media-popup .card.video-card, .ant-modal.media-popup .video-react.video-react-fluid , .card.video-card{
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 20px !important;
}
.ant-modal.media-popup .card.video-card div:first-child {
    width: 100% !important;
    height: 100% !important;
}
.media-popup .next{
    position: absolute;
    right: -54px;
    top: 50%;
    font-size: 36px;
    transform: translate(-50% , -50%);
    cursor: pointer;
}
.media-popup  .ant-modal-close-x {
    width: 35px !important;
    height: 35px !important;
    border: 2px solid #e52113 !important;
    border-radius: 100px !important;
    display: flex !important;
    justify-content: space-around !important;
    align-items: center !important;
    color: #e52113 !important;
    margin: 11px;
}
.media-popup .prev{
    position: absolute;
    left: -30px;
    top: 50%;
    font-size: 36px;
    transform: translate(-50% , -50%);
    cursor: pointer;
}
.video-player-parent {
    width: 100%;
}
.card.video-card .video-parent {
    margin: 0;
    width: 100%;
}
.card.video-card .video-player-parent {
    margin: 0px 0;
}
.images-video .card.video-card {
    background: transparent;
    box-shadow: none;
    /* margin-bottom: 30px; */
    border: none !important;
    position: relative;
}
.card.video-card .video-parent iframe {
    min-height: 300px;
    max-height: 300px;
    width: 100%;
    border: none !important;
    border-radius: 20px !important;
    box-shadow: none !important;
    border: none !important;
}
.media-delete-icon svg {
    width: 20px;
    height: 20px;
    fill: white;
}
.media-delete-icon {
    position: absolute;
    top: 4px;
    right: 4px;
    background: #5383a2;
    width: 40px;
    height: 40px;
    z-index: 99;
    border-radius: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0px 1px 4px #497fa2;
    cursor: pointer;
}
.nodata{
    /* border: 1px solid #92c9d2;
    background: #fff; */
    border-radius: 20px;
    min-height: 290px !important;
    position: relative;
}
.nodata svg {
    width: 149px;
    /* position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50% , -50%); */
}
.ops_text{
    font-family: "Sacramento";
    color: #92c9d2;
    font-size: 42px;
    margin-top:10px;
    margin-bottom: 10px;
}
.nodata h2 {
    font-size: 20px;
    font-weight: 700;
}
.crosschat{
    display: none;
}
.cummunity .check_wrapper > span:after {
    left: 18px;
    top: 8px;
}
.width-auto{
    max-width: 100% !important;
}
/* -------------------add staff design----------- */
.staff-container img {
    margin-right: 15px;
}
.staff-container .image-chat .chat {
    top: 74px !important;
    left: 57% !important;
}

.staff .community-card.staff-container img {
    width: 110px !important;
    height: 110px !important;
}
.staff .card.community-card .descript, .community-card.staff-container h4 {
    min-height: 26px;
}
.staff .card.community-card .descript, .staff .card.community-card .descript small {
    margin-bottom: 14px;
    font-weight: 700;
}
.community-card .image-chat .chat {
    left: 47%;
    top: 63px;
}

.community-card.staff-container {
    /* min-height: auto !important;
    max-height: 100% !important; */
    min-height: 216px;
    max-height: 100% !important;
}
.staff .community-card.staff-container .textparent, .cummunity .community-card.staff-container .textparent, .community-card.brochure .textparent{
    width: calc(100% - 125px );
}
.staff .community-card.staff-container .textparent a, .staff .community-card.staff-container .textparent span, .staff .community-card.staff-container .textparent .staff-bottom {
    width: 100%;
}
.brandhome.cummunity .card.text-center.community-card img {
    min-height: 100px;
    max-height: 100px;
    object-fit: cover;
    width: 100px;
}
.staff .community-card.staff-container .textparent span button.btn.btn-blue span.zoom {
    width: auto;
}
.view-answer {
    background: var(--btncolor) !important;
    font-size: 12px !important;
    border-radius: 100px !important;
    color: #fff !important;
}
.quadrado {
    /* width: 100px;
  height: 100px;
  background: #000; */
    animation: crescendo 1.5s alternate infinite ease-in;
  }

.wrap-chat-header nav {
    padding: 5px 7px;
    background: #BFE0E6;
    border-radius: 7px;
    margin: 6px 10px;
}
.wrap-chat-header .nav-link {
    padding: 0.3rem 1rem;
    border-radius: 7px;
    position: relative;
}

.wrap-chat-header a {
    color : #ffffff !important;
}
.wrap-chat-header .nav-link.active {
    background : var(--headercolor) !important;
    background-color : var(--headercolor) !important;
    /* position: absolute; */
    /* -webkit-animation: slide 2s forwards;
    -webkit-animation-delay: 2s;
    animation: slide 2s forwards;
    animation-delay: 2s; */
    /* -webkit-transition:1s;
    -o-transition:1s;
    transition:1s; */
}
@-webkit-keyframes slide {
    100% { left: 0; }
}
@keyframes slide {
    100% { left: 0; }
}

.chatbody .ant-modal-wrap, .chatbody1 .ant-modal-wrap{
    z-index: 999999;
    float: right;
}

.cm-modal {
    z-index: 1; /* Overlay effect: positioned over other containers */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    position: fixed; /* Fix position on the top-left corner*/
    top: 0;
    left: 0;
    overflow: auto; /* Enable scroll if needed */
    z-index: 0999999999;
    overflow-y: hidden;
    overflow-x: hidden;
}
/* Modal content */
.modal-content {
    /* background: rgb(225,244,244) !important; */
    background: linear-gradient(0deg, rgba(225,244,244,1) 85%, rgba(42,103,149,1) 100%, rgba(43,102,146,1) 100%) !important;
    max-width: 425px;
    /* min-width: 425px; */
    height: 100%;
    border-radius: 18px 0px 0px 0px !important;
    margin: auto;
    float: right;
    padding: 10px;
    border-radius: 20px;
    backdrop-filter: blur(22px);
    -webkit-backdrop-filter: blur(22px);
    margin: 0;
    position: absolute !important;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-left: 425px;
    
    justify-content: center;


}

#slide {

    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 0s;
    animation: slide 0.5s forwards;
    animation-delay: 0s;
    right: -425px;
}
@-webkit-keyframes slide {
    100% { right: 0; }
    }
    
    @keyframes slide {
    100% { right: 0; }
    }
#slide-close {

    -webkit-animation: slide 0.5s forwards;
-webkit-animation-delay: 0s;
animation: slide-close 0.5s forwards;
animation-delay: 0s;
right: 0;
}

@-webkit-keyframes slide-close {
    100% { right: -425px; }
    }
    
    @keyframes slide-close {
    100% { right: -425px; }
    }

#chatbody-slide1, #chatbody-slide2, #chatbody-slide3 {
    position: absolute !important;
    -webkit-animation: slide 0.100s forwards;
    -webkit-animation-delay: 2s;
    animation: chatbody-slide 0.100s forwards;
    animation-delay: 0s;
    right: -300px;
    width: 100%;
}

@-webkit-keyframes chatbody-slide {
    100% { right: 0; }
    }
    
    @keyframes chatbody-slide {
    100% { right: 0; }
    }

    #chatbody-slide1 {
        position: absolute !important;
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 2s;
    animation: chatbody-slide1 0.5s forwards;
    animation-delay: 0s;
    right: -300px;
    width: 100%;
    

}
@-webkit-keyframes chatbody-slide1 {
    100% { right: 0; }
    }
    
    @keyframes chatbody-slide1 {
    100% { right: 0; }
    }

.cm-modal button {
    width: 206px;
    max-width: 206px !important;
    height: 48px;
    background: #2C688D 0% 0% no-repeat padding-box;
    border-radius: 34px;
    font-size: 14px !important;
}

.cm-modal button:hover {
    background-color: #90B3C8 !important;
}

.cross-icon-wrapper {
    height: 30px;
    width: 30px;
    position: absolute;
    top: 15px;
    cursor: pointer;
}

.cross-icon-wrapper svg path {
    fill: #fff;
    stroke: #fff;
}

.bg-red{
    background: #dfd1cf;
}

.btn.right {
    margin: 0 auto;
    display: block;
    margin-right: 121px;
}







  @keyframes crescendo {
    0%   {transform: scale(1);}
    100% {transform: scale(1.4);}
  }

@media (min-width:300px) and (max-width:991px){
    .chat_open_mob .chat-wrapper {
        border: 0px solid black;
    border-radius: 25px;
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: 0 25px 50px -12px rgb(233 247 253 / 2%);
    background-color: inherit;
    display: flex;
    width: 73px;
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
    z-index: 9999;
    position: fixed;
    }
     .chat-wrapper{
        display: block;
        width: 100%;
    }
    .chat.pos-relative .counter.chat-counter.white-counter {
        left: 22px !important;
        background: #d13a27;
        color: #fff;
        top: 24px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .chat_open_mob .crosschat{
     display:block;
     cursor: pointer;
    }
    .chat_open_mob .bottom {
        width: 88%;
    }
    .search_wrapper.chatbox-textbox i {
        position: fixed;
        bottom: 1.4%;
    }
    .chatbody , .chatbody1{
        height: calc(100vh - 257px);

    }
}
@media (max-width:1300px) {
    .brand.dash_body_container.withsidenavbar .container-fluid{
        padding: 40px !important;
    }
}
@media (max-width:499px) {
    .brand-logo-parent img, .brand-logo-parent {
        min-height: 83px;
        max-height: 83px;
        max-width: 160px;
    }
}
@media (min-width:300px) and (max-width:1179px){
    .staff .card.community-card .align-items-start.flex-column div span.action_group {
        justify-content: center !important;
        margin: 6px 0 !important;
    }
    .card.community-card.staff-container button{
        margin: 0 auto;
      }
    .staff .community-card.staff-container .textparent{
        width: 100%;
    }
    staff-container .imgparent img {
        margin-right: 0;
    }
    .staff-container .imgparent {
        width: 100%;
        text-align: center;
    }  
    .staff-container .image-chat .chat {
        top: 74px;
        left: 57%;
    }
   
    .staff-container .textparent, .staff-container .textparent h4, .staff-container .textparent span {
        width: 100%;
        text-align: center;
        margin: 0 !important;
    }
    .card.community-card.staff-container .textparent {
        margin-top: 12px !important;
    }
    .staff .community-card.staff-container .textparent , .staff .community-card.staff-container .textparent a {
        width: 100%;
        text-align: center;
    }
    /* .staff .card.community-card .align-items-start.flex-column span div{

    } */
    /* .staff .card.community-card.staff-container {
        min-height: 261px;
        max-height: 261px;
    } */
}
@media (min-width:300px) and (max-width:575px){
    .staff-container .image-chat .chat {
        top: 74px;
        left: 52%;
    }
}
@media (min-width:300px) and (max-width:1200px){
    /* .card.community-card.staff-container {
        flex-wrap: wrap;
    }
    .community-card.staff-container .textparent {
        width: calc(100% - 0px );
    } */
}



   /* chat tab css */

   .chatbody-parent .tabs {
    background: #BFE0E6;
    width: calc(100% - 20px);
    position: relative;
    color: #ffffff;
    overflow: hidden;
    margin: 13px auto;
    text-align: center;
    border-radius: 10px;
    height: 42px;
  }
  .tabs__tracker {
    position: absolute;
    top: 5px;
    bottom: 5px;
    border-radius: 5px;
    line-height: 30px;
    margin-left: 5px;
    width: 100px;
    background: #2D698D;
    width: 100;
  }
  .tabs__titles__title {
    color: #ffffff;
    display: inline-block;
    cursor: pointer;
    padding: .8em 20px;
    width: 100%;
    text-align: center;
    margin: 0;
    transition: all 300ms ease-in-out;
    position: relative;
  }

  .tabs__contents {
    position: relative;
    height: 260px;
  }
  .tabs__contents__content {
    padding: 20px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .scale-enter {
    transform: translateX(-100%);
    opacity: 0.01;
  }
  
  .scale-enter.scale-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: all 500ms ease-in-out;
  }
  
  .scale-leave {
    transform: translateX(0);
    opacity: 1;
  }
  
  .scale-leave.scale-leave-active {
    transform: translateX(100%);
    opacity: 0.01;
    transition: all 500ms ease-in-out;
  }
  
  .chatbody-parent .tag, .chatbody-parent .tag--not {
    display: inline-block;
    border-radius: 12px;
    color: white;
    padding: 4px 8px;
    text-transform: uppercase;
    font-weight: 700;
  }
  .tag + .tag, .tag--not + .tag, .tag + .tag--not, .tag--not + .tag--not {
    margin-left: 10px;
  }
  .tag--not {
    background: #ae2f3c;
  }
.w-33{
    width: calc(100% / 3) !important;
}

.tabswitchanipation-page{
    background: #BFE0E6;
    margin: 10px 5px;
    padding: 4px;
    border-radius: 10px;

}

.tabswitchanipation-page .tabs-scroll {
    position: relative;
    display: flex;
}

.tabs-scroll .animate-me, .tabs-scroll > .btn:after {
    position: absolute;
    content: '';
    height: 100%;
    top: 0px;
    z-index: 0;
    background-color: #2D698D;
    transition: all ease-in-out 200ms;
    z-index: 0;
    border-radius: 10px;
}
.tabs-scroll .btn{
    position: relative;
    z-index: 1;
    color: #FFF;
    
}

.tabs-scroll .btn .active-button{
    color: #ffffff;
}
.tabs-scroll .btn:hover{
    color: #fff !important;
}
  /* chat tab css end */